var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('a-tabs',{ref:"sekkeiref",attrs:{"activeKey":_vm.activeKey,"defaultActiveKey":_vm.tabNumber},on:{"change":_vm.callback}},[(_vm.isAdmin() || _vm.isIchijoUser())?_c('a-tab-pane',{key:"0",attrs:{"tab":"全検索"}},[_c('AllDocuments')],1):_vm._e(),(!_vm.isSalesUser())?_c('a-tab-pane',{key:"1",attrs:{"tab":"ルールブック"}},[_c('Rulebook')],1):_vm._e(),(!_vm.isSalesUser())?_c('a-tab-pane',{key:"11",attrs:{"tab":"ルールブック(全体)"}},[_c('RulebookNew',{attrs:{"rulebookTab":'all'}})],1):_vm._e(),(!_vm.isSalesUser())?_c('a-tab-pane',{key:"12",attrs:{"tab":"ルールブック(軸組)"}},[_c('RulebookNew',{attrs:{"rulebookTab":'jiku'}})],1):_vm._e(),(!_vm.isSalesUser())?_c('a-tab-pane',{key:"13",attrs:{"tab":"ルールブック(枠組)"}},[_c('RulebookNew',{attrs:{"rulebookTab":'waku'}})],1):_vm._e(),(_vm.isAdmin() || _vm.isIchijoUser() || _vm.isSalesUser())?_c('a-tab-pane',{key:"2",attrs:{"tab":"連絡票・通達"}},[_c('JapanMemo')],1):_vm._e(),(
        (_vm.isAdmin() || _vm.isIchijoUser() || _vm.isGCUser() || _vm.isGCPortalUser()) &&
          !_vm.isSalesUser()
      )?_c('a-tab-pane',{key:"3",attrs:{"tab":"JWW/DXF(部材)"}},[_c('JWW')],1):_vm._e(),(
        (_vm.isIchijoUser() || _vm.isGCUser() || _vm.isGCPortalUser()) && !_vm.isSalesUser()
      )?_c('a-tab-pane',{key:"4",attrs:{"tab":"DXF(商品)"}},[_c('DXFProduct')],1):_vm._e(),(
        _vm.isAdmin() ||
          _vm.isGCPortalUser() ||
          _vm.isInCharge() ||
          _vm.isIchijoUser() ||
          _vm.isGCUser() ||
          _vm.isGCUserNew()
      )?_c('a-tab-pane',{key:"5",attrs:{"tab":"仕様マニュアル"}},[_c('ShiyoManual')],1):_vm._e(),_c('a-tab-pane',{key:"10",attrs:{"tab":"仕様マニュアル(PROPOSAL)"}},[_c('ShiyoManualNew')],1),(_vm.isAdmin())?_c('a-tab-pane',{key:"6",attrs:{"tab":"設計業務マニュアル"}},[_c('SekkeiGyoumu')],1):_vm._e(),(_vm.isAdmin())?_c('a-tab-pane',{key:"7",attrs:{"tab":"設計企画通信"}},[_c('SekkeiKikaku')],1):_vm._e(),(!_vm.isSalesUser())?_c('a-tab-pane',{key:"8",attrs:{"tab":"CAD依頼資料"}},[_c('CADRequestMaterials')],1):_vm._e(),(_vm.isAdmin())?_c('a-tab-pane',{key:"9",attrs:{"tab":"CAD依頼指示方法"}},[_c('PlannersReference')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }