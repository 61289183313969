<template>
  <div v-if="!isShiyoManualHistory">
    <v-card class="ma-2 pa-2" flat style="border:1px solid gray; background-color: #f6f5f5">
        <v-row dense no-gutters>
          <v-col class="d-flex" cols="3">
              <span class="mt-1"><v-icon class="ml-5" color="blue">mdi-home</v-icon>
                  全文検索：
              </span>
                <v-text-field
                    style="zoom:90%;"
                    outlined
                    dense
                    hide-details
                    clearable
                    append-icon="mdi-magnify"
                    :clear-icon="customClearIcon"
                    v-model="searchText"
                    @click:clear="clearSearch()"
                    @keyup.enter="selectedCondition='AND',onSearch()"
                ></v-text-field>
            </v-col>
            <v-col cols="1" class="mt-1">
              <span class="d-flex ml-10">
                <h4 class="mr-auto">構法</h4>
                <h4 class="mr-1">:</h4>
              </span>
            </v-col>
            <v-col cols="3" xs="2" class="d-flex">
              <v-row dense no-gutter>
                <v-checkbox
                    v-for="item in frameworkList"
                    :key="item"
                    :value="item"
                    :label="item"
                    v-model="selectedFramework"
                    style="zoom:100%"
                    dense
                    hide-details
                ></v-checkbox>
                    <!-- <v-checkbox
                    label="枠組壁工法"
                    style="zoom:100%"
                    class="mt-6"
                    label="軸組構法"
                    dense
                ></v-checkbox> -->
              </v-row>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="2">
              <v-row dense no-gutter>
                <v-btn
                    color="gray"
                    dense
                    block
                    hide-details
                >フィルタ表示</v-btn>
              </v-row>
            </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col cols="3"></v-col>
          <v-col cols="1">
            <!-- <span class="d-flex mt-3 ml-11">建物タイプ :</span> -->
            <span class="d-flex mt-1 ml-10">
                <h4 class="mr-auto">建物タイプ</h4>
                <h4 class="mr-1">:</h4>
              </span>
          </v-col>
            <v-col cols="6" class="d-flex align-content-start flex-wrap">
              <v-row dense no-gutter>

                <v-checkbox
                    v-for="item in buildingTypeList"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                    hide-details
                    dense
                    style="zoom:100%"
                    v-model="selectedBuildingType"
                ></v-checkbox>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-row dense no-gutter>
                <v-btn
                    class="mt-3"
                    color="gray"
                    hide-details
                    dense
                    block
                    @click="
                    isFavorite = !isFavorite;
                    filterFavorites();
                  "
                >お気に入り
                <v-icon
                  small
                  :style="isFavorite ? 'color:#ffa62b' : 'color:#214252'"
                  class="mr-1"
                >{{ isFav }}
                </v-icon>
                </v-btn>
              </v-row>
            </v-col>
        </v-row>
        <v-row dense no-gutters>
            <v-col cols="3"></v-col>
            <v-col cols="1">
              <!-- <span class="mt-2 d-flex ml-15 mt-4">資料区分:</span> -->
              <span class="d-flex ml-9 mt-3">
                <h4 class="mr-auto">資料区分</h4>
                <h4 class="mr-1">:</h4>
              </span>
            </v-col>
            <v-col cols="4" class="d-flex align-content-start flex-wrap">
              <v-row dense no-gutter>
                <v-checkbox
                    style="zoom:100%"
                    class="mt-3"
                    label="商品仕様・特徴"
                    dense
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    style="zoom:100%"
                    class="mt-3"
                    label="計画ルール"
                    dense
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    style="zoom:100%"
                    class="mt-3"
                    label="参照資料"
                    dense
                    hide-details
                ></v-checkbox>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-row dense no-gutter>
                <v-btn
                    class="mt-1"
                    color="gray"
                    dense
                    block
                    hide-details
                    @click="goToShiyoHistory()"
                >更新履歴</v-btn>
              </v-row>
            </v-col>
        </v-row>
    </v-card>
    <v-layout align-center justify-center v-if="loadingShiyo">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <v-card
        v-if="!isSearching && !loadingShiyo"
        fluid
        class="mt-4"
    >
        <v-simple-table
            dense
            :height="`${screenHeight-300}px`"
            class="tableProps"
            style="border:1px solid black;"
            fixed-header
        >
            <thead>
              <tr style="border:1px solid black !important;">
                <th class="ma-0 pa-0" width="10%" rowspan="2" style="border: 1px solid black !important;">
                  <div class="header-content text-center">
                    <div class="mx-auto" row wrap>
                      <a-select
                        v-model="selectedRoomPart"
                        style="font-weight: bold; font-size: 14px; width: 150px"
                        mode="multiple"
                        >
                        <a-select-option
                          v-for="item in roomPartList"
                          :key="item.value"
                          v-value="item.value"
                        >{{ item.text }}</a-select-option>
                      </a-select>
                      <v-tooltip bottom v-if="sortByRoomPartASC == false">
                        <template v-slot:activator="{ on }">
                          <span
                              class="mt-2 button"
                              style="font-size: 15px"
                              v-on="on"
                              v-if="sortByRoomPartASC == false"
                              @click="
                                sortByRoomPart(sortByRoomPartASC),
                                  (sortByRoomPartASC = !sortByRoomPartASC)"
                          >
                                🔽
                                部屋・部位</span>
                        </template>
                          <span style="font-family:Arial"> 🔽 - 昇順 </span>
                      </v-tooltip>
                              
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on }">
                          <span
                              class="mt-2 button"
                              style="font-size: 15px"
                              v-on="on"
                              @click="
                                  sortByRoomPart(sortByRoomPartASC),
                                    (sortByRoomPartASC = !sortByRoomPartASC)"
                          >
                              🔼
                              部屋・部位</span>
                        </template>     
                        <span style="font-family:Arial"> 🔼 - 降順 </span>                           
                      </v-tooltip><br>
                            </div>
                        </div>
                    </th>
                    <th class="pa-2" style="border: 1px solid black !important;">
                        <v-text-field
                            outlined
                            dense
                            label="検索："
                            hide-details
                            clearable
                            style="border: 1px solid black !important;"
                            append-icon="mdi-magnify"
                            v-model="searchTitle"
                        ></v-text-field>
                    </th>
                    <th class="pa-2" colspan="2" style="border: 1px solid black !important;">
                        <v-text-field
                            outlined
                            dense
                            label="検索："
                            hide-details
                            clearable
                            style="border: 1px solid black !important;"
                            append-icon="mdi-magnify"
                            v-model="searchDocNo"
                        ></v-text-field>
                    </th>
                    <th class="pa-2" colspan="5" style="border: 1px solid black !important;">
                        <v-text-field
                            outlined
                            dense
                            label="検索："
                            hide-details
                            clearable
                            style="border: 1px solid black !important;"
                            append-icon="mdi-magnify"
                            v-model="searchAttachmentTitle"
                        ></v-text-field>
                    </th>
                    <th class="ma-0 pa-0" width="11%" rowspan="2" style="border: 1px solid black !important;">
                      <a-range-picker
                          v-model="loadYear"
                          style="width: 85%;"
                          class="ml-2 mt-3"
                          >
                          <template #suffixIcon>
                            <svg focusable="false" data-icon="calendar" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg>
                          </template>
                        </a-range-picker>
                      <div class="header-content">
                        <div class="my-2 mx-auto" style="font-size: 14px">
                          <v-tooltip bottom v-if="sortByDateASC == false">
                            <template v-slot:activator="{ on }">
                              <span
                                class="button"
                                style="font-weight: bold; font-size: 14px"
                                v-if="sortByDateASC == false"
                                v-on="on"
                                @click="
                                  sortByDate(sortByDateASC), (sortByDateASC = !sortByDateASC)
                                "
                              >
                                🔽 更新日:</span
                              >
                            </template>
                            <span style="font-family:Arial"> 🔽 - 昇順 </span>
                          </v-tooltip>

                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                              <span
                                class="button mt-2"
                                style="font-weight: bold; font-size: 14px"
                                v-on="on"
                                @click="
                                  sortByDate(sortByDateASC), (sortByDateASC = !sortByDateASC)
                                "
                              >
                                🔼 更新日:
                              </span>
                            </template>
                            <span style="font-family:Arial"> 🔼 - 降順 </span>
                          </v-tooltip>
                        </div>
                      </div>
                    </th>
              </tr>
                <tr style="border: 1px solid black !important;">
                    <th width="20%" class="text-center" style="border: 1px solid black !important;">商品名（項目）</th>
                    <th width="10%" class="text-center" style="border: 1px solid black !important;">商品仕様・特徴</th>
                    <th width="10%" class="text-center" style="border: 1px solid black !important;">計画ルール</th>
                    <th width="29%" class="text-center" colspan="5" style="border: 1px solid black !important;">参照資料</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in paginatedShiyo" :key="i">
                    <td :size="[0, 'small']">
                        <a-tag class="my-1" color="#4527A0" style="font-size: 12px; width: 100%; text-align: center; padding: 10px;">
                            {{item.roomPart ? item.roomPart.join("・") : ''}}
                        </a-tag>
                    </td>
                    <td>
                        <a id="title_ellipsis" style="color:black; font-weight: bold; font-size:15px;">
                            {{ item.productName }}
                        </a>
                    </td>
                    <td class="text-center">
                        <a-tag color="#43A047" style="font-size: 15px; padding: 10px; cursor: pointer;" v-if="item.specificationNo" @click="openSpecification(item)">
                            {{item.specificationNo}}
                        </a-tag>
                    </td>
                    <td class="text-center">
                        <a-tag style="font-size: 15px; padding: 12px; background-color: #FFB74D; cursor: pointer;" v-if="item.planRuleNo" @click="openPlanRule(item)">
                            {{item.planRuleNo}}
                        </a-tag>
                    </td>

                    <!-- 参照資料 -->
                    <td class="text-center" style="width:7.25%">
                      <a v-if="item.attachment1Id" target="_blank" :href="`/shiyoDocument/${item.attachment1Id}?productName=${item.attachment1ProductName}`">{{ item.attachment1ProductName }}</a>
                    </td>
                    <td class="text-center" style="width:7.25%">
                      <a v-if="item.attachment2Id" target="_blank" :href="`/shiyoDocument/${item.attachment2Id}?productName=${item.attachment2ProductName}`">{{ item.attachment2ProductName }}</a>
                    </td>
                    <td class="text-center" style="width:7.25%">
                      <a v-if="item.attachment3Id" target="_blank" :href="`/shiyoDocument/${item.attachment3Id}?productName=${item.attachment3ProductName}`">{{ item.attachment3ProductName }}</a>
                    </td>
                    <td class="text-center" style="width:7.25%">
                      <a v-if="item.attachment4Id" target="_blank" :href="`/shiyoDocument/${item.attachment4Id}?productName=${item.attachment4ProductName}`">{{ item.attachment4ProductName }}</a>
                    </td>
                    <td class="text-center" style="width:7.25%">
                    </td>

                    <!-- 更新日 -->
                    <td class="text-center">
                        <a-tag color="#0288D1" style="font-size: 15px; padding: 10px">
                            {{item.updatedDate}}
                        </a-tag>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-pagination
          v-model="pageNumber"
          :length="paginationLength"
          :total-visible="5"
        ></v-pagination>
    </v-card>

    <v-card
      v-else-if="!loadingShiyo"
      flat
      class="ma-1 pa-1 resetScrollTop"
    >
      <v-row dense>
        <v-col cols="8"></v-col>
        <v-layout>
          <v-col cols="1">
            <v-checkbox
              class="ml-2 pa-0 ma-0"
              hide-details
              v-model="isAllChecked"
              @change="allShowSubline()"
            ></v-checkbox>
          </v-col>
        </v-layout>
      </v-row>
      <v-card elevation="0" v-for="(item, i) in paginatedShiyo" :key="i">
        <v-divider />
        <v-row class="mb-n2">
          <v-layout>
            <v-col cols="1">
                <a-tag
                  style="width: 100%; color: white; text-align: center"
                  small
                  class="my-1"
                  color="#5c969e"
                >
                  <span>{{ item.framework }} </span>
                </a-tag>
            </v-col>
            <v-col cols="2">
                  <a-tooltip placement="left">
                    <template slot="title">
                      {{
                        Array.isArray(item.roomPart) &&
                        Array.isArray(item.roomPart).length >= 3
                          ? getRoomPart(item.roomPart)
                          : getRoomPart(item.roomPart)
                      }}
                    </template>
                    <a-tag
                      style="width: 100%; color: white; text-align: center"
                      color="#424874"
                      class="my-1"
                    >
                      {{
                        Array.isArray(item.roomPart) &&
                        Array.isArray(item.roomPart).length >= 3
                          ? getRoomPart(item.roomPart)
                          : getRoomPart(item.roomPart)
                      }}
                    </a-tag>
                  </a-tooltip>
                </v-col> 
            <v-col cols="12" md="5">
              <v-layout>
                    <span
                      id="title_ellipsis"
                      @click="
                        openShiyo(item);
                        searchLists.push(item.id, item.productName);
                      "
                      :class="listColorSearch(item.id, item.productName)"
                      class="mx-2"
                      style="font-size: 20px; font-weight: bold;  cursor:pointer"
                      v-html="
                        `<span style='border-radius:4px' class='px-3 mr-2 black--text'><span style='font-size:15px;font-weight:normal'></span>${item.productName}${title_spacer}`
                      "
                    />
              </v-layout>
            </v-col>
            <!-- NOTE: added compression of subline after searching 2024-05-23 -->
            <v-col>
              <v-spacer></v-spacer>
              <div v-if="searchShiyoIconAlignment && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'">
                <v-btn color="primary" icon @click="toggleShowLine(item)">
                  <v-icon large>{{item.showLine ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                </v-btn>
              </div>
            </v-col>
            <!-- Half 2022-02-08 -->
            <v-col cols="12" md="5">
              <v-row dense>
                <v-col cols="2">
                  <a-tag
                    style="width: 100%; color: white; text-align: center"
                    color="#e36387"
                    class="my-1"
                  >
                    {{ item.specifications }}
                  </a-tag>
                </v-col>
              <v-col cols="2">
                <a-tag
                    style="width: 100%; color: white; text-align: center; font-size:15px; font-weight:normal;"
                    color="#31b16e"
                    :class="'my-1 ' + listColorSearch(item.id, item.productName)"
                  >
                    {{ item.shiyoNumber }}
                  </a-tag>
              </v-col>
  
                <v-col cols="2" class="mt-1 mr-5">
                  <a-tag
                    color="#3d7ea6"
                    style="width: 100%; color: white; text-align: center"
                    small
                    class="ml-5"
                  >
                    <span class="hidden-md-and-down">更新日:</span>
                    {{ item.updatedDate }}
                  </a-tag>
                </v-col>
  
                <v-col cols="1">
                  <v-row no-gutters>
                    <div class="mt-1 ">
                      <v-icon color="#6ebaa7" style="font-size: xx-small"
                        >mdi-eye</v-icon
                      ><span
                        v-if="item.CountViews"
                        style="font-size: font-size:xx-small"
                        >{{ item.CountViews }}
                      </span>
                      <span v-else style="font-size: 12px"> 0 </span>
                    </div>
  
                    <div class="mt-1 ml-1">
                      <v-icon small color="#ffa62b">mdi-star</v-icon>
                      <span style="font-size: 12px" v-if="item.FavoritesCount">
                        {{ item.FavoritesCount }}
                      </span>
                      <span v-else style="font-size: 12px"> 0 </span>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-layout>
        </v-row>
        
        <v-row v-if="searchShiyoIconAlignment && item.showLine && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'">
          <!-- Record Text Content -->
          <v-col cols="11" md="11" class="mt-n4">
            <v-layout>
              <!-- 2022-04-26 -->
              <span
                class="title"
                v-if="searchShiyoIconAlignment && item.showLine && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'"
                v-html="`${item.textContent}`"
                :style="[
                  {
                    color:
                      item.textContent == 'Attachment Only' ||
                      item.textContent == '<h1>Attachment Only </h1>' ||
                      item.textContent == '<h1>Attachment Only</h1>'
                        ? 'white'
                        : '#555555',
                  },
                  { 'margin-left': '20px' },
                  { 'font-size': '12px' },
                ]"
              >
              </span>
            </v-layout>
          </v-col>
        </v-row>
      </v-card>

      <!-- No Data Record Label -->
      <a-empty v-if="!loadingShiyo && paginatedShiyo.length == 0" />
      <v-pagination
        v-model="pageNumber"
        :length="paginationLength"
        :total-visible="5"
        dark
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </v-card>
  </div>
  <div v-else>
    <v-card
      v-if="isShiyoManualHistory"
      class="ma-1 pa-1"
      flat
      height="50px"
      style="background-color: #f6f5f5"
    >
      <v-row dense>
        <v-col cols="6" sm="8" md="3">
          <a-input
            addon-before="検索"
            v-model="searchShiyoHistory"
            placeholder="検索"
            append-icon="search"
            outlined
            @compositionstart="StartCompose()"
            @compositionend="EndCompose()"
          >
            <a-icon
              slot="suffix"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
              @click="searchShiyoHistory ? clearSearchShiyoHistory() : ''"
            />
          </a-input>
        </v-col>

        <v-col cols="6" sm="2" md="2" lg="4">
          <a-range-picker
            style="width: 70%;"
          >
            <template #suffixIcon>
              <svg focusable="false" data-icon="calendar" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg>
            </template>
          </a-range-picker>
          
          <!-- <a-icon
              slot="suffix"
              type="calendar"
              style="color: rgba(0, 0, 0, 0.45)"
            /> -->
        </v-col>

        <v-col cols="12" sm="2" md="2" lg="2">
        </v-col>
        <v-col cols="12" sm="2" md="2" lg="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                @click="goToShiyoHistory()"
                style="font-family:Arial"
                v-on="on"
                block
                color="primary"
              >
                <v-icon v-if="!isShiyoManualHistory">mdi-refresh</v-icon>
                {{ historyLabel }}
                <v-icon>mdi-home</v-icon>
              </v-btn>
            </template>
            <span style="font-family:Arial"> Home </span>
          </v-tooltip>
        </v-col>
      </v-row>

      <!-- Filter Section -->
      <a-row
        class="ml-4"
        :xs="24"
        :sm="12"
        :md="10"
        :gutter="[0, 3]"
        v-if="!isFilterOn && !hideFilter"
      >
      </a-row>
    </v-card>
    <!------HISTORY TABLE------->
    <v-card flat>
      <template v-if="isShiyoManualHistory">
        <div>
          <v-row class="mb-n2">
            <v-col cols="8">
              <span class="ma-4" style="color: #0f3057; font-weight: bold" flat>
                Total: {{ filteredShiyoHistoryArr.length }}</span
              >|
              <span class="ma-4" style="color: #0f3057; font-weight: bold" flat>
                Total New: {{ shiyoHistoryNewData.length }}
              </span>
              |
              <span class="ma-4" style="color: #0f3057; font-weight: bold" flat>
                Total Revised: {{ shiyoHistoryReviseData.length }}
              </span>
              |
              <span class="ma-4" style="color: #0f3057; font-weight: bold" flat>
                Total Deleted: {{ shiyoHistoryDeletedData.length }}
              </span>
            </v-col>
            <v-col cols="4">
              <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
                >LEGEND :</span
              >
              <!-- NOTE: for sorting based on legend selected 2024-04-29 -->
              <v-chip
                color="#FCF3CF"
                text-color="#0f3057"
                width
                :style="`width: 80px; font-weight: bold; height: 20px; background-color:${sortedNewHistory? 'pink': '#FCF3CF'};`"
                class="pl-6 mx-1"
                @click="changeSortedLegend('NEW')"
              >
                New
              </v-chip>
              <v-chip
                :color="`${sortedDeletedHistory? 'pink':'#0f3057'}`"
                outlined
                style="width: 80px; font-weight: bold; height: 20px"
                class="mx-1"
                @click="changeSortedLegend('DELETED')"
              >
                Deleted
              </v-chip>
              <v-chip
                color="#D1F2EB"
                text-color="#0f3057"
                :style="`width: 80px; font-weight: bold; height: 20px; background-color:${sortedRevisedHistory? 'pink': '#D1F2EB'};`"
                class="mx-1"
                @click="changeSortedLegend('REVISED')"
              >
                Revised
              </v-chip>
            </v-col>
          </v-row>

          <a-table
            class="hideSortIcon"
            :rowClassName="rowColor"
            showSizeChange
            :pagination="false"
            size="middle"
            row-key="id"
            bordered
            :columns="headerAdmin"
            :data-source="paginatedShiyoHistoryArr"
            :scroll="{ y: screenSize-310 }"
          >
            <template slot="updatedDate" slot-scope="text">
              <span v-if="text.updatedDate">
                {{ text.updatedDate }}
              </span>
              <span v-else>
                {{ text.createdDate }}
              </span>
            </template>
            <template slot="remarks" slot-scope="text">
              <span>{{ text.remarks }}</span>
            </template>
            <template slot="shiyoNumber" slot-scope="text">
              {{ text.shiyoNumber }}
            </template>
            <template slot="framework" slot-scope="text">
              <a-tag
                style="width: 80px"
                v-if="text.framework"
                :color="
                  text.framework == '軸組'
                    ? 'blue'
                    : text.framework == '枠組'
                    ? 'green'
                    : 'orange'
                "
              >
                <center>{{ text.framework }}</center>
              </a-tag>
            </template>
          </a-table>
        </div>
      </template>
    </v-card>

    <!-- NOTE: added pagination for shiyoHistoryLogs 2024-04-19 start -->
    <v-pagination
      v-model="pageNumberShiyoHistory"
      :length="paginationShiyoHistoryLength"
      :total-visible="5"
      dark
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      v-if="paginatedShiyoHistoryArr"
    ></v-pagination>
  </div>
</template>

<script>
import moji from "moji";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
export default {
    data(){
        return{
            sortedDeletedHistory:false,
            pageNumberShiyoHistory: 1,
            sortedRevisedHistory:false,
            sortedNewHistory:false,
            hideFilter: false,
            isFilterOn: false,
            isShiyoManualHistory:false,
            searchCategory: "",
            isAllChecked:false,
            initialData: [
              {
              "planrule": "10-62033001",
              "planspec": "60-62040101",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "",
              "attachment1": "",
              "attachment2": "70-69041001",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-69093001",
              "planspec": "",
              "attachment1": "",
              "attachment2": "70-69041001",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61060401",
              "planspec": "60-61060401",
              "attachment1": "70-61060401",
              "attachment2": "70-62031801",
              "attachment3": "70-24013001",
              "attachment4": ""
              },
              {
              "planrule": "10-61060402",
              "planspec": "60-61060401",
              "attachment1": "70-61060401",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62071901",
              "planspec": "60-62071902",
              "attachment1": "70-62071904",
              "attachment2": "70-61060401",
              "attachment3": "70-62031801",
              "attachment4": "70-24013001"
              },
              {
              "planrule": "10-63021602",
              "planspec": "60-63021601",
              "attachment1": "70-63021604",
              "attachment2": "70-61060401",
              "attachment3": "60-60021070",
              "attachment4": ""
              },
              {
              "planrule": "10-63050901",
              "planspec": "",
              "attachment1": "70-63021604",
              "attachment2": "70-61060401",
              "attachment3": "60-60021070",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "",
              "attachment1": "70-23092101",
              "attachment2": "70-61110101",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-65082202",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60020612",
              "attachment1": "",
              "attachment2": "70-60020601",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "",
              "attachment1": "70-60070801",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-04120152",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64022001",
              "planspec": "60-63100701",
              "attachment1": "70-67032801",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-65121501",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61031811",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63081501",
              "planspec": "60-63081501",
              "attachment1": "70-64022201",
              "attachment2": "70-16102801",
              "attachment3": "70-60021114",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-03123114",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-03123104",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-14071601",
              "planspec": "60-60020609",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-04020901",
              "attachment1": "70-06041301",
              "attachment2": "70-06041302",
              "attachment3": "70-06041303",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60051009",
              "attachment1": "70-03123113",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-04093007",
              "attachment1": "70-06121501",
              "attachment2": "70-18040503",
              "attachment3": "70-23112001",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-18070901",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13090501",
              "planspec": "60-13090601",
              "attachment1": "70-13090502",
              "attachment2": "70-18040501",
              "attachment3": "70-18040502",
              "attachment4": "70-18122601"
              },
              {
              "planrule": "",
              "planspec": "60-60021019",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021014",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60020610",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63072401",
              "planspec": "60-60021024",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60040601",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61042401",
              "planspec": "60-61042401",
              "attachment1": "70-61042401",
              "attachment2": "70-61051001",
              "attachment3": "70-60021114",
              "attachment4": "70-61051003"
              },
              {
              "planrule": "10-61042402",
              "planspec": "60-61042402",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-04120151",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-08042201",
              "attachment1": "70-12051801",
              "attachment2": "70-08042202",
              "attachment3": "70-08042203",
              "attachment4": ""
              },
              {
              "planrule": "10-07082909",
              "planspec": "60-13081502",
              "attachment1": "70-13081501",
              "attachment2": "70-08042203",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-07082910",
              "planspec": "60-04120104",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-62041601",
              "attachment1": "70-20040101",
              "attachment2": "70-63022401",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03121406",
              "planspec": "60-60020613",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03121405",
              "planspec": "60-60020613",
              "attachment1": "70-09080701",
              "attachment2": "70-21010701",
              "attachment3": "70-10031203",
              "attachment4": ""
              },
              {
              "planrule": "10-07082912",
              "planspec": "60-68040401",
              "attachment1": "70-06080301",
              "attachment2": "70-06080302",
              "attachment3": "70-21012101",
              "attachment4": ""
              },
              {
              "planrule": "10-03121407",
              "planspec": "60-03121407",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-03121408",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64020401",
              "planspec": "60-60021061",
              "attachment1": "70-15040301",
              "attachment2": "70-24030101",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-68070501",
              "planspec": "60-60050703",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61041902",
              "attachment1": "70%E2%80%90",
              "attachment2": "70-61093001",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-63082002",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60071403",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64022101",
              "planspec": "60-60020605",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63100702",
              "planspec": "60-61101801",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64010901",
              "planspec": "60-61101802",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03121301",
              "planspec": "60-60021105",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-10040201",
              "planspec": "60-61101901",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62042801",
              "planspec": "60-60020603",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-69122601",
              "planspec": "60-60021041",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62032101",
              "planspec": "60-60020607",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61041903",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-69122701",
              "planspec": "60-69122701",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61020701",
              "planspec": "60-60021044",
              "attachment1": "70-60021114",
              "attachment2": "70-06082601",
              "attachment3": "70-62021501",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021016",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61032301",
              "planspec": "60-61032301",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-65122101",
              "planspec": "60-65122201",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61071401",
              "planspec": "60-61071401",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61041901",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61101202",
              "planspec": "60-61101201",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62052401",
              "planspec": "60-60021045",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-67101401",
              "planspec": "60-60021029",
              "attachment1": "70-07112601",
              "attachment2": "70-23100601",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63073101",
              "planspec": "60-60021029",
              "attachment1": "70-23100601",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64031101",
              "planspec": "60-60021030",
              "attachment1": "70-07112601",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-60082101",
              "planspec": "60-63080201",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021106",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03121002",
              "planspec": "60-60021031",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-67022401",
              "planspec": "60-60021032",
              "attachment1": "70-64012402",
              "attachment2": "70-69112801",
              "attachment3": "70-23091401",
              "attachment4": "70-23091402"
              },
              {
              "planrule": "",
              "planspec": "60-60021033",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021034",
              "attachment1": "70-20112601",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62012401",
              "planspec": "60-62012501",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-07083004",
              "planspec": "60-60020602",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63081502",
              "planspec": "60-60021102",
              "attachment1": "70-23120601",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021040",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61101901",
              "planspec": "60-60051008",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03121008",
              "planspec": "60-60121202",
              "attachment1": "70-10031201",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021009",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-21081801",
              "planspec": "60-69062801",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-11102001",
              "planspec": "60-11102001",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021073",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03121702",
              "planspec": "60-60021108",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61101401",
              "planspec": "60-60021063",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021107",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61101701",
              "planspec": "60-61101203",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61102002",
              "planspec": "60-60021062",
              "attachment1": "70-09040101",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-09012301",
              "planspec": "60-09012301",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60051101",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61072601",
              "planspec": "60-61072601",
              "attachment1": "70-23111001",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-11091401",
              "planspec": "60-11091401",
              "attachment1": "70-12062601",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-69080301",
              "planspec": "60-69080301",
              "attachment1": "70-19082401",
              "attachment2": "70-19092801",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-16022201",
              "planspec": "60-16022201",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61021701",
              "planspec": "60-61021701",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03121101",
              "planspec": "60-60021042",
              "attachment1": "70-61092501",
              "attachment2": "70-18102204",
              "attachment3": "70-23112002",
              "attachment4": ""
              },
              {
              "planrule": "10-14030401",
              "planspec": "60-60021042",
              "attachment1": "70-23072701",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-09012101",
              "planspec": "60-64031701",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61031705",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63100703",
              "planspec": "60-63100703",
              "attachment1": "70-22121501",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-69051301",
              "planspec": "60-69051301",
              "attachment1": "70-61092701",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13072202",
              "planspec": "60-13072301",
              "attachment1": "70-23101201",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61102701",
              "planspec": "60-61102701",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-07071001",
              "planspec": "60-07071001",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63092601",
              "planspec": "60-60021068",
              "attachment1": "70-64050801",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61102101",
              "planspec": "60-60021070",
              "attachment1": "70-20112601",
              "attachment2": "70-24012001",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61102103",
              "planspec": "60-61102101",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61042801",
              "planspec": "60-60070701",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-11102003",
              "planspec": "60-60021064",
              "attachment1": "70-65102701",
              "attachment2": "70-17072701",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62060701",
              "planspec": "60-60021064",
              "attachment1": "70-65102702",
              "attachment2": "70-17072701",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-15102701",
              "planspec": "60-60021064",
              "attachment1": "70-65102702",
              "attachment2": "70-17072701",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-19100802",
              "planspec": "60-60082206",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-19100803",
              "planspec": "60-69122101",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03122003",
              "planspec": "60-60021064",
              "attachment1": "70-21111701",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021064",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60071402",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-64030601",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62011401",
              "planspec": "60-62011401",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61102104",
              "planspec": "60-61102102",
              "attachment1": "70-20112601",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-60110501",
              "planspec": "60-60020611",
              "attachment1": "70-20112601",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-66122701",
              "planspec": "60-62071001",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03122001",
              "planspec": "60-61102601",
              "attachment1": "70-07092879",
              "attachment2": "70-18040401",
              "attachment3": "70-21032503",
              "attachment4": "70-23041201"
              },
              {
              "planrule": "10-17072801",
              "planspec": "60-17072801",
              "attachment1": "70-17073101",
              "attachment2": "70-19030501",
              "attachment3": "70-23071801",
              "attachment4": "70-23071802"
              },
              {
              "planrule": "10-19101102",
              "planspec": "60-19101102",
              "attachment1": "70-24022901",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64030701",
              "planspec": "60-63100702",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61032901",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03123013",
              "planspec": "60-03123013",
              "attachment1": "70-22060701",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62041801",
              "planspec": "60-62042101",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61112402",
              "planspec": "60-61031801",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61031809",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-19100401",
              "planspec": "60-19100401",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021020",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62062801",
              "planspec": "60-60021049",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-68082701",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-65021901",
              "planspec": "60-60021018",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021025",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021026",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61021601",
              "planspec": "60-60021058",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61070201",
              "planspec": "60-60021067",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021065",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021066",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62061601",
              "planspec": "60-61031701",
              "attachment1": "70-21111702",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62061802",
              "planspec": "60-61031810",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64030602",
              "planspec": "60-60021059",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61112201",
              "planspec": "60-60021060",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021055",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021056",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62011402",
              "planspec": "60-60021071",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63073102",
              "planspec": "60-60030801",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021109",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-60082201",
              "planspec": "60-60082205",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021008",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021007",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021057",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021110",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61022301",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021013",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021015",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050201",
              "planspec": "60-04050201",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050101",
              "planspec": "60-60021072",
              "attachment1": "70-20040101",
              "attachment2": "70-23111501",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63050902",
              "planspec": "60-63083001",
              "attachment1": "70-20040101",
              "attachment2": "70-23111501",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13082302",
              "planspec": "60-61122601",
              "attachment1": "70-20040101",
              "attachment2": "70-23111501",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-19100303",
              "planspec": "60-60082201",
              "attachment1": "70-20040101",
              "attachment2": "70-23111501",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04042801",
              "planspec": "",
              "attachment1": "70-10122201",
              "attachment2": "70-15040902",
              "attachment3": "70-07092882",
              "attachment4": ""
              },
              {
              "planrule": "10-04050103",
              "planspec": "",
              "attachment1": "70-09101401",
              "attachment2": "70-23102301",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050104",
              "planspec": "",
              "attachment1": "70-21052003",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64050106",
              "planspec": "60-68073101",
              "attachment1": "70-08052802",
              "attachment2": "70-21021701",
              "attachment3": "70-23100302",
              "attachment4": ""
              },
              {
              "planrule": "10-04050102",
              "planspec": "60-22032901",
              "attachment1": "70-10112001",
              "attachment2": "70-07092882",
              "attachment3": "70-22011501",
              "attachment4": ""
              },
              {
              "planrule": "10-04050113",
              "planspec": "60-60021072",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050114",
              "planspec": "",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-65082501",
              "planspec": "",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050107",
              "planspec": "60-64060402",
              "attachment1": "70-20091901",
              "attachment2": "70-19122102",
              "attachment3": "70-20090801",
              "attachment4": "70-24022902"
              },
              {
              "planrule": "10-03120110",
              "planspec": "60-64060402",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13082303",
              "planspec": "60-61122601",
              "attachment1": "70-20013101",
              "attachment2": "70-20040101",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-19100304",
              "planspec": "60-60082201",
              "attachment1": "70-20040101",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050117",
              "planspec": "60-60021072",
              "attachment1": "70-20040101",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-23051101",
              "planspec": "60-63083001",
              "attachment1": "70-20040101",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04042802",
              "planspec": "60-60021072",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61031704",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050118",
              "planspec": "60-04050118",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04042803",
              "planspec": "60-04042803",
              "attachment1": "70-08052801",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-05120601",
              "planspec": "60-60021072",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-03120122",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050114",
              "planspec": "60-19070501",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61112403",
              "planspec": "60-61031703",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61111001",
              "planspec": "60-60021051",
              "attachment1": "70-20040101",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62060702",
              "planspec": "60-60021051",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120302",
              "planspec": "60-03120302",
              "attachment1": "70-23042701",
              "attachment2": "70-15021701",
              "attachment3": "70-21113001",
              "attachment4": ""
              },
              {
              "planrule": "10-19100202",
              "planspec": "60-60082202",
              "attachment1": "70-20040101",
              "attachment2": "70-21050601",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-21110901",
              "planspec": "60-60082202",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-23050801",
              "planspec": "60-60082202",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-23082401",
              "planspec": "60-60082202",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120303",
              "planspec": "60-60021112",
              "attachment1": "70-09040101",
              "attachment2": "70-18100101",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120305",
              "planspec": "60-03120305",
              "attachment1": "70-23112003",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120306",
              "planspec": "60-03120306",
              "attachment1": "70-21122401",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120307",
              "planspec": "60-03120307",
              "attachment1": "70-07092874",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050401",
              "planspec": "60-60021104",
              "attachment1": "70-09022003",
              "attachment2": "70-20112601",
              "attachment3": "70-23092201",
              "attachment4": ""
              },
              {
              "planrule": "10-03120402",
              "planspec": "60-60050801",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-05060108",
              "planspec": "60-60021021",
              "attachment1": "70-07092866",
              "attachment2": "70-07092859",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120501",
              "planspec": "60-60050802",
              "attachment1": "70-23061501",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-03120504",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120502",
              "planspec": "60-60050802",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120502",
              "planspec": "60-03120504",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120503",
              "planspec": "",
              "attachment1": "70-07092852",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04083101",
              "planspec": "60-03120506",
              "attachment1": "70-12122101",
              "attachment2": "70-20011001",
              "attachment3": "70-20070801",
              "attachment4": "70-23060601"
              },
              {
              "planrule": "10-03120601",
              "planspec": "60-60021036",
              "attachment1": "70-07092877",
              "attachment2": "70-13120201",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120603",
              "planspec": "60-03120603",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120604",
              "planspec": "60-60021035",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120602",
              "planspec": "60-60021036",
              "attachment1": "70-07092861",
              "attachment2": "70-15021901",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-07083157",
              "planspec": "60-60021036",
              "attachment1": "70-07092870",
              "attachment2": "70-09111101",
              "attachment3": "70-23082801",
              "attachment4": ""
              },
              {
              "planrule": "10-07083158",
              "planspec": "60-60021036",
              "attachment1": "70-11012001",
              "attachment2": "70-13122101",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-67033001",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120606",
              "planspec": "60-61031804",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62060301",
              "planspec": "60-04083109",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120609",
              "planspec": "60-03120609",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-06021001",
              "planspec": "60-63033103",
              "attachment1": "70-14091702",
              "attachment2": "70-63033102",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120608",
              "planspec": "60-60021038",
              "attachment1": "70-09022002",
              "attachment2": "70-21032501",
              "attachment3": "70-20040101",
              "attachment4": ""
              },
              {
              "planrule": "10-09022001",
              "planspec": "60-09022001",
              "attachment1": "70-15021902",
              "attachment2": "70-20040101",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-20061201",
              "planspec": "60-61031804",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63072402",
              "planspec": "60-60021043",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61112202",
              "planspec": "60-60021043",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61111002",
              "planspec": "60-60021043",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61111003",
              "planspec": "60-60021043",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62061801",
              "planspec": "60-60021053",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021050",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120706",
              "planspec": "60-61031805",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-64011101",
              "planspec": "60-60102601",
              "attachment1": "70-10122801",
              "attachment2": "70-19122701",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61031806",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-19101003",
              "planspec": "60-60082204",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-22061001",
              "planspec": "60-60021113",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-68112901",
              "attachment1": "70-07092872",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-61110702",
              "planspec": "60-61110801",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04050705",
              "planspec": "60-60021047",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-19100302",
              "planspec": "60-60082203",
              "attachment1": "70-21012201",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63050903",
              "planspec": "60-63082902",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13041001",
              "planspec": "60-63022101",
              "attachment1": "70-20070701",
              "attachment2": "70-19092802",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-18090401",
              "planspec": "60-18090401",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-60021901",
              "planspec": "60-60021901",
              "attachment1": "70-20021901",
              "attachment2": "70-20070701",
              "attachment3": "70-21010501",
              "attachment4": "70-22061602"
              },
              {
              "planrule": "10-63100701",
              "planspec": "60-60021101",
              "attachment1": "70-13100701",
              "attachment2": "70-08020802",
              "attachment3": "70-07092869",
              "attachment4": "70-19061201"
              },
              {
              "planrule": "10-66022301",
              "planspec": "60-66022301",
              "attachment1": "70-19061201",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "",
              "attachment1": "70-07112205",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-63081502",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13121901",
              "planspec": "60-14010901",
              "attachment1": "70-19050801",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04020801",
              "planspec": "60-60071401",
              "attachment1": "70-09090301",
              "attachment2": "70-07092853",
              "attachment3": "70-60120101",
              "attachment4": "70-07092882"
              },
              {
              "planrule": "10-13120501",
              "planspec": "60-64021201",
              "attachment1": "70-14091701",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-03120802",
              "planspec": "60-61121601",
              "attachment1": "70-07092893",
              "attachment2": "70-17051901",
              "attachment3": "70-21052001",
              "attachment4": ""
              },
              {
              "planrule": "10-06103101",
              "planspec": "60-60121201",
              "attachment1": "70-08082601",
              "attachment2": "70-23102401",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-11101301",
              "planspec": "60-63082001",
              "attachment1": "70-60021114",
              "attachment2": "70-23072702",
              "attachment3": "70-23102401",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-04090901",
              "attachment1": "70-11062101",
              "attachment2": "70-11083101",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-04090902",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-65082201",
              "attachment1": "70-10040201",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-08091001",
              "planspec": "60-63091001",
              "attachment1": "70-10111101",
              "attachment2": "70-08091001",
              "attachment3": "70-21032502",
              "attachment4": ""
              },
              {
              "planrule": "10-03120803",
              "planspec": "60-60021039",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13072602",
              "planspec": "60-13072602",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-08101501",
              "planspec": "60-65060601",
              "attachment1": "70-15082204",
              "attachment2": "70-23033101",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13081502",
              "planspec": "60-65052101",
              "attachment1": "70-13081502",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-65082601",
              "attachment1": "70-23033101",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04090904",
              "planspec": "60-04090904",
              "attachment1": "70-11101301",
              "attachment2": "70-07092883",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-07090702",
              "planspec": "",
              "attachment1": "70-07091004",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-05120604",
              "planspec": "60-63082301",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-63082301",
              "planspec": "60-61041301",
              "attachment1": "70-09081802",
              "attachment2": "70-14012501",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-07090707",
              "planspec": "60-05120605",
              "attachment1": "70-14012501",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-07090704",
              "planspec": "60-05120605",
              "attachment1": "70-23061501",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13082301",
              "planspec": "60-13082301",
              "attachment1": "70-14031701",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-04020802",
              "planspec": "60-60111001",
              "attachment1": "70-11022501",
              "attachment2": "70-17072702",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-60021022",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-20112601",
              "planspec": "60-20112601",
              "attachment1": "70-20112601",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-62011301",
              "planspec": "60-60021054",
              "attachment1": "70-60021103",
              "attachment2": "70-07090702",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13082001",
              "planspec": "60-12122101",
              "attachment1": "70-15040401",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-13082001",
              "planspec": "60-12070501",
              "attachment1": "70-60021103",
              "attachment2": "70-23032301",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "10-11120201",
              "planspec": "60-61120801",
              "attachment1": "70-11110901",
              "attachment2": "70-60021114",
              "attachment3": "70-24030601",
              "attachment4": ""
              },
              {
              "planrule": "10-13072201",
              "planspec": "60-62082001",
              "attachment1": "70-12102401",
              "attachment2": "70-62082301",
              "attachment3": "",
              "attachment4": ""
              },
              {
              "planrule": "",
              "planspec": "60-61120802",
              "attachment1": "",
              "attachment2": "",
              "attachment3": "",
              "attachment4": ""
              }
            ],
            historyLabel: "History",
            isFavorite: false,
            selectedBuildingType: ["ALL"],
            buildingTypeList: [
                { text: "グラン・スマート", value: "Gsmart" },
                { text: "i-smart", value: "Ismart" },
                { text: "i-cube", value: "Icube" },
                { text: "i-smile+", value: "Ismiles" },
                { text: "i-smile", value: "Ismile" },
                { text: "HUGme", value: "HUGme" },
                { text: "HUGme fam", value: "HUGmefam" },
                { text: "グラン・セゾン", value: "G" },
                { text: "セゾンF", value: "F" },
                { text: "セゾンA", value: "A" },
                { text: "ブリアール", value: "B" },
                { text: "ファミーユ ", value: "H" },
                { text: "和風百年 ", value: "J" },
            ],
            frameworkList:[
              "枠組壁工法",
              "軸組構法",
            ],
            frameworks: "consFrameAssembly",
            title_spacer: "　",
            selectedFramework: "ALL",
            selectedCondition: "AND",
            searchShiyoIconAlignment: false,
            searchDocNo: "",
            searchText: "",
            searchTitle: "",
            searchAttachmentTitle: "",
            loadYear: [],
            data:[],
            selectedRoomPart:"ALL",
            selectedSpecs: "ALL",
            isSearching: false,
            roomPartList: [
                { text: "ALL", value: "ALL" },
                { text: "基本仕様", value: "基本仕様" },
                { text: "付帯", value: "付帯" },
                { text: "構造", value: "構造" },
                { text: "屋根", value: "屋根" },
                { text: "外壁", value: "外壁" },
                { text: "窓まわり", value: "窓まわり" },
                { text: "基礎", value: "基礎" },
                { text: "バルコニー", value: "バルコニー" },
                { text: "外部その他", value: "外部その他" },
                { text: "玄関", value: "玄関" },
                { text: "洋室", value: "洋室" },
                { text: "和室", value: "和室" },
                { text: "書斎", value: "書斎" },
                { text: "キッチン", value: "キッチン" },
                { text: "洗面所", value: "洗面所" },
                { text: "トイレ", value: "トイレ" },
                { text: "浴室", value: "浴室" },
                { text: "収納", value: "収納" },
                { text: "インテリア", value: "インテリア" },
                { text: "給湯器", value: "給湯器" },
                { text: "電気工事", value: "電気工事" },
                { text: "設備その他", value: "設備その他" },
            ],
            searchLists: [],
            shiyoData: [],
            pageNumber: 1,
            intRowNoShiyo: 30,
            loadingShiyo: false,
            sortByRoomPartASC: false,
            sortByFrameworkASC: false,
            sortBySpecsASC: false,
            sortByDateASC: false,
            sortByFavoriteASC: false,
            searchShiyoHistory: "",
            composing: false,
            headerAdmin: [
            {
            title: () => (
              <div class="custom-header-cell d-flex">
                更新日 <div class="mdi mdi-sort ml-2" />
              </div>
            ),
            width: 60,
            scopedSlots: { customRender: "updatedDate" },
            key: "updatedDate",
            align: "left",
            sorter: (a, b) => a.updatedDate.localeCompare(b.updatedDate),
            },

            {
            title: () => (
              <div class="custom-header-cell d-flex">
                変更区分 <div class="mdi mdi-sort ml-2" />
              </div>
            ),
            width: 80,
            key: "remarks",
            scopedSlots: { customRender: "remarks" },
            align: "left",
            sorter: (a, b) => a.remarks.localeCompare(b.remarks),
            },

            {
            title: () => (
              <div class="custom-header-cell d-flex">
                部屋・部位 <div class="mdi mdi-sort ml-2" />
              </div>
            ),
            dataIndex: "roomPart",
            width: 60,
            key: "roomPart",
            align: "left",
            sorter: (a, b) => a.roomPart.localeCompare(b.roomPart),
            },

            {
            title: () => (
              <div class="custom-header-cell d-flex">
                商品名（項目 <div class="mdi mdi-sort ml-2" />
              </div>
            ),
            dataIndex: "productName",
            width: 80,
            key: "productName",
            align: "left",
            sorter: (a, b) => a.productName.localeCompare(b.productName),
            },

            {
            title: () => (
              <div class="custom-header-cell d-flex">
                資料区分 <div class="mdi mdi-sort ml-2" />
              </div>
            ),
            key: "reviseReason",
            width: 90,
            dataIndex: "reviseReason",
            scopedSlots: { customRender: "reviseReason" },
            align: "left",
            },

            {
            title: () => (
              <div class="custom-header-cell d-flex">
                管理番号 <div class="mdi mdi-sort ml-2" />
              </div>
            ),
            key: "shiyoNumber",
            width: 50,
            scopedSlots: { customRender: "shiyoNumber" },
            align: "left",
            sorter: (a, b) => a.shiyoNumber.localeCompare(b.shiyoNumber),
            },

            {
            title: () => (
              <div class="custom-header-cell d-flex">
                構法 <div class="mdi mdi-sort ml-2" />
              </div>
            ),
            width: 50,
            key: "framework",
            scopedSlots: { customRender: "framework" },
            align: "left",
            sorter: (a, b) => a.framework.localeCompare(b.framework),
            },
        ],
      }
    },
  created() {
    this.loadShiyoRecords();
    this.getUserFavorites();
  },
watch:{
  searchShiyoHistory(val) {
      // 2022-12-23
      if (val) {
        this.pageNumberShiyoHistory = 1;
      }
    },
  pageNumberShiyoHistory(){
    let tableScrollTop = document.querySelector('.ant-table-body')
    tableScrollTop.scrollTop = 0
  },
  searchText(val){
    if(!val){
      this.isSearching = false
      this.loadShiyoRecords()
    }
  },
  selectedFramework(val) {
    if (val) {
      this.pageNumber = 1;
    }
  },
  isAllChecked(val){
    if(val){
      this.shiyoData.forEach(item => {
        this.$set(item, 'showLine', true);
      });
    }else{
      this.shiyoData.forEach(item => {
        this.$set(item, 'showLine', false);
      });
    }
  },
  selectedBuildingType() {
    if (
      this.selectedBuildingType.includes("ALL") &&
      this.selectedBuildingType.length == 2
    ) {
      this.selectedBuildingType.splice(0, 1);
    }
    if (this.selectedBuildingType.length == 0) {
      this.selectedBuildingType = ["ALL"];
    }
    if (
      this.selectedBuildingType.includes("ALL") &&
      this.selectedBuildingType.length != 1
    ) {
      this.selectedBuildingType = ["ALL"];
    }
    this.pageNumber = 1;
  },
  selectedRoomPart() {
    if (
      this.selectedRoomPart.includes("ALL") &&
      this.selectedRoomPart.length == 2
    ) {
      this.selectedRoomPart.splice(0, 1);
    }
    if (this.selectedRoomPart.length == 0) {
      this.selectedRoomPart = ["ALL"];
    }
    if (
      this.selectedRoomPart.includes("ALL") &&
      this.selectedRoomPart.length != 1
    ) {
      this.selectedRoomPart = ["ALL"];
    }

    this.pageNumber = 1; 
    },
  },
methods:{
  clearSearchShiyoHistory() {
      // 2022-12-23
      this.searchShiyoHistory = "";
      // Load Shiyo History function here
    },
  changeSortedLegend(legend){
    if(legend=='NEW'){
      this.sortedNewHistory = !this.sortedNewHistory
      if(this.sortedNewHistory){
        this.sortedDeletedHistory = false
        this.sortedRevisedHistory = false
      }
    }else if(legend=='DELETED'){
      this.sortedDeletedHistory = !this.sortedDeletedHistory
      if(this.sortedDeletedHistory){
        this.sortedNewHistory = false
        this.sortedRevisedHistory = false
      }
    }
    else if(legend=='REVISED'){
      this.sortedRevisedHistory = !this.sortedRevisedHistory
      if(this.sortedRevisedHistory){
        this.sortedNewHistory = false
        this.sortedDeletedHistory = false
      }
    }
  },
  openUpdateDetails(val, index) {
    console.log(val.id), console.log(index);
    console.log(val.shiyoNumber);
    // return;
    let previousVersion;
    let otherVersions = this.shiyoData.filter(
      (el) =>
        el.shiyoNumber == val.shiyoNumber &&
        el.id != val.id &&
        el.version < val.version
    );
    // otherVersions.push({ version: 3 });
    console.log(otherVersions);
    if (otherVersions.length > 1) {
      let prevVersion = val.version - 1;
      previousVersion = otherVersions.filter(
        (el) => el.version == prevVersion
      );
      console.log(previousVersion);
    }
    if (otherVersions.length === 1) {
      previousVersion = otherVersions[0];
      console.log("previousVersion version:", previousVersion);
    }
    let id = val.id;
    let id2 = previousVersion ? previousVersion[0].id : "";
    window.open(
      `shiyoHistoryComparing/${id}?search=${this.searchText}&id2=${id2}`,
      "_blank"
      );
    },
  rowColor(rec) {
      return rec.deleted
        ? "white"
        : rec.version > 1
        ? "bg-green"
        : "bg-yellow";
    },
  goToShiyoHistory() {
      // 2022-12-23
      this.isShiyoManualHistory = !this.isShiyoManualHistory;
      if (this.isShiyoManualHistory) {
        this.historyLabel = "Home";
        this.searchShiyoHistory = "";
        // Load Shiyo History function  here
      } else {
        this.historyLabel = "History";
        this.searchShiyoHistory = "";
        this.loadShiyoRecords();
      }
    },
  SortByColumn(columnName) {
    // 2022-12-23
    this.columnOrder = this.columnOrder == "desc" ? "asc" : "desc";
    this.shiyoHistoryArr = _.orderBy(
      this.shiyoHistoryArr,
      [columnName.toString()],
      [this.columnOrder.toString()]
      );
    },
  sortByDocNumber(action) {
    // Sort By Shiyo Number
    // this.loadShiyoRecords(); // 2022-01-19
    this.loadShiyoRecordsWithNoLoading();
    action = !action;
    console.log("line658", action);
    setTimeout(() => {
      if (!action) {
        return this.shiyoData.sort((a, b) => {
          return a.shiyoNumber.localeCompare(b.shiyoNumber);
        });
      } else {
        return this.shiyoData.sort((a, b) => {
          return b.shiyoNumber.localeCompare(a.shiyoNumber);
        });
      }
    }, 800);
  },
  async openShiyo(val) {
      // Not Exceeded
      if (val.isWithPDFAttachment == true) {
        val.productName = this.removeTags(val.productName);
        this.shiyoDetails = val;
        // 2022-05-23
        window.open(
          `${this.linkURL}/shiyoDocumentViewPDF/${val.id}?productName=${val.productName}`,
          "_blank"
        );
        console.log("line996", this.shiyoDetails);
      } else {
        this.SET_FAVORITE(0);
        this.SET_FAVORITE(val.FavoritesCount);
        if (!this.searchText) {
          window.open(
            `/shiyoDocument/${val.id}?productName=${val.productName}`,
            "_blank"
          );
        } else if (
          this.searchText.match(/^[0-9]/g) == null &&
          this.selectedCondition != "MULTI"
        ) {
          window.open(
            `${this.linkURL}/shiyoDocument/${val.id}?search=${this.searchText}&productName=${val.productName}`,
            "_blank"
          );
        } else if (this.searchText && this.selectedCondition == "MULTI") {
          window.open(
            // 2022-05-20
            `${this.linkURL}/shiyoDocument/${val.id}?search=${this.searchText}&productName=${val.productName}`,
            "_blank"
          );
        } else {
          window.open(
            `${this.linkURL}/shiyoDocument/${val.id}?productName=${val.productName}`,
            "_blank"
          );
        }
        let url = `${this.api}shiyo/addCount/${val.id}`;
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        axios.post(url).then(() => {
          let index = this.shiyoData.indexOf(val);
          if (this.shiyoData[index].CountViews) {
            this.shiyoData[index].CountViews =
              this.shiyoData[index].CountViews + 1;
          } else {
            this.shiyoData[index].CountViews = 1;
          }
        });
      }
    },
  allSearch(){
      if(this.searchDocNo){
        this.selectedCondition = '帳票番号'
        this.onSearch()
      }
      else{
        this.selectedCondition = 'AND'
        this.fullTextSearch()
      }
    },
  fullTextSearch(){
    this.isSearching = !this.isSearching
    this.loadingShiyo = true;
    this.searchDocNo = ""
    axios.defaults.headers.common["x-api-key"] =
      "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    const url = `${this.api}check/multi?group=${this.searchText}`;
    axios.get(url).then((res)=>{
      if(res.data){
        this.onMultipleSearch()
      }
      else{
        this.onSearch()
        }
      })
    },
  allShowSubline() {
      if (!this.isAllChecked) {
        return;
      } else {
        this.shiyoData.forEach(item => {
          this.$set(item, 'showLine', !item.showLine);
        });
      }
    },
  toggleShowLine(item){
      this.showLine = false
      let index = this.shiyoData.indexOf(item)
      if(index > -1){
        this.$set(this.shiyoData[index],'showLine',!this.shiyoData[index].showLine)
        console.log(this.shiyoData[index].showLine)
      }
    },
  async onMultipleSearch() {
      // 2023-03-04
    try {
      this.pageNumber = 1;
      if (!this.composing) {
        this.searchShiyoIconAlignment = true;
        this.isMultiple = true;
        this.loadingShiyo = true;

        if (this.searchText) {
          let shiyoRecords = await this.promiseGetMultipleSearchWords(
            this.searchText,
            this.exceptText
          );
          this.shiyoData = shiyoRecords.map((rec) => {
            rec.updatedDate = moment(rec.updatedDate).format("YYYY-MM-DD");
            rec.textContent =
              rec.textContent == " Attachment Only " || // 2022-08-16
               rec.textContent == "Attachment Only " ||
              rec.textContent == " Attachment Only" ||
              rec.textContent == "Attachment Only"
                ? "添付ファイルのみとなります。"
                : rec.textContent;
            return rec;
          }).map(r=>{
            this.$set(r,'showLine',false)
            return r
          });
          this.isMultiple = false;
          this.loadingShiyo = false;
        } else {
          alert("Please provide search word..");
          this.shiyoData = [];
          this.loadingShiyo = false;
        }
      }
    } catch (err) {
      alert(
        "Error fetching data from multiple condition, Please try again.."
      );
      console.log(err.message);
      this.shiyoData = [];
      this.loadingShiyo = false;
    }
  },
    promiseGetMultipleSearchWords(searchKeyWord = "", exceptWord = "") {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        const url = `${this.api}shiyo/search/multiple/?search1=${searchKeyWord}&exceptWord=${exceptWord}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWordByDocumentCategoryNew(condition = "", search = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}shiyo/search2/shiyo/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    async onSearch() {
      try {
        this.isSearching = !this.isSearching
        this.loadingShiyo = true;
        this.searchShiyoIconAlignment = true;
        this.pageNumber = 1;
        let search = "";
        let japaneseSpace = "　";
        if (this.selectedCondition == "帳票番号") {
          this.searchText = ""
          if (this.searchDocNo.trim()) {
            let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
              "byShiyoNumber",
              this.searchDocNo
            );
            this.shiyoData = finalData.map((r) => {
              r.updatedDate = r.updatedDate
                ? moment(r.updatedDate).format("YYYY-MM-DD")
                : "新規作成";
              r.textContent =
                r.textContent == " Attachment Only " ||
                r.textContent == "Attachment Only " ||
                r.textContent == " Attachment Only" ||
                r.textContent == "Attachment Only"
                  ? "添付ファイルのみとなります。"
                  : r.textContent;
              return r;
            });
            this.loadingShiyo = false;
            this.isSearching = false;
          } else {
            alert(`Please Input Reference Number`);
            this.loadingShiyo = false;
          }
        }
        // Check If Selected Condition is BY TITLE
        else if (this.selectedCondition == "タイトル") {
          if (this.searchText.trim()) {
            let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
              "byDocumentTitle",
              this.searchText
            );
            this.shiyoData = finalData.map((r) => {
              r.updatedDate = r.updatedDate
                ? moment(r.updatedDate).format("YYYY-MM-DD")
                : "新規作成";
              r.textContent =
                r.textContent == " Attachment Only " ||
                r.textContent == "Attachment Only " ||
                r.textContent == " Attachment Only" ||
                r.textContent == "Attachment Only"
                  ? "添付ファイルのみとなります。"
                  : r.textContent;
              return r;
            });
            this.loadingShiyo = false;
          } else {
            alert(`Please Input Document Title`);
            this.loadingShiyo = false;
          }
        }
        // Check If SELECTED CONDITION IS BY Document Number and Title
        else if (this.selectedCondition == "ID & TITLE") {
          if (this.searchDocNo.trim() && this.searchTitle.trim()) {
            let finalData = await this.promiseGetSearchWord(
              "byShiyoNumberTitle",
              this.searchDocNo,
              this.searchTitle
            );
            this.shiyoData = finalData.map((r) => {
              r.updatedDate = r.updatedDate
                ? moment(r.updatedDate).format("YYYY-MM-DD")
                : "新規作成";
              r.textContent =
                r.textContent == " Attachment Only " ||
                r.textContent == "Attachment Only " ||
                r.textContent == " Attachment Only" ||
                r.textContent == "Attachment Only"
                  ? "添付ファイルのみとなります。"
                  : r.textContent;
              return r;
            }).map(r=>{
              this.$set(r,'showLine',false)
              return r
            });
            this.loadingShiyo = false;
          } else {
            alert(`Please Input Reference Number and Title`);
            this.shiyoData = [];
            this.loadingShiyo = false;
          }
        } else {
          if (this.searchText) {
            // 2 Words
            if (
              this.searchText.includes(" ") ||
              this.searchText.includes(japaneseSpace)
            ) {
              // Split Two Words into array
              let splitKeyWord = this.searchText
                .replace(/\s+/g, " ")
                .split(" ");
              // Get Keyword per index ( as of now 2 words only )
              let keywordsArr1 = Object.values(
                this.convertSearchText(splitKeyWord[0])
              );
              let keywordsArr2 = Object.values(
                this.convertSearchText(splitKeyWord[1])
              );
              console.log("Search word 1 --> ", keywordsArr1);
              console.log("Search word 2 --> ", keywordsArr2);
              // Selected Condition
              // ===================================== AND =========================================== //
              if (this.selectedCondition == "AND") {
                if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                  let finalData = await this.promiseGetSearchWordNew(
                    "and",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  this.shiyoData = _.uniqBy(finalData.flat(), "id").map(r=>{
                    this.$set(r,'showLine',false)
                    return r
                  });
                  this.shiyoData = this.shiyoData
                    .map((r) => {
                      r.updatedDate = r.updatedDate
                        ? moment(r.updatedDate).format("YYYY-MM-DD")
                        : "新規作成";
                      r.textContent =
                        r.textContent == " Attachment Only " ||
                        r.textContent == "Attachment Only " ||
                        r.textContent == " Attachment Only" ||
                        r.textContent == "Attachment Only"
                          ? "添付ファイルのみとなります。"
                          : r.textContent;
                      // r.specifications = r.specifications && r.specifications.match(/[0-9]/g) ? r.specifications.substring(0, r.specifications.length - 3) : ''
                      return r;
                    })
                    .sort((a, b) => {
                      return a.updatedDate > b.updatedDate ? -1 : 1;
                    });
                }
              } else {
                // ==================================== OR ============================================== //
                if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                  let finalData = await this.promiseGetSearchWordNew(
                    "or",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  this.shiyoData = _.uniqBy(finalData.flat(), "id").map(r=>{
                    this.$set(r,'showLine',false)
                    return r
                  });
                  this.shiyoData = this.shiyoData
                    .map((r) => {
                      r.updatedDate = r.updatedDate
                        ? moment(r.updatedDate).format("YYYY-MM-DD")
                        : "新規作成";
                      r.textContent =
                        r.textContent == " Attachment Only " ||
                        r.textContent == "Attachment Only " ||
                        r.textContent == " Attachment Only" ||
                        r.textContent == "Attachment Only"
                          ? "添付ファイルのみとなります。"
                          : r.textContent;
                      // r.specifications = r.specifications && r.specifications.match(/[0-9]/g) ? r.specifications.substring(0, r.specifications.length - 3) : ''
                      return r;
                    })
                    .sort((a, b) => {
                      return a.updatedDate > b.updatedDate ? -1 : 1;
                    });
                }
              }
              this.loadingShiyo = false;
            } else {
              // 1 Word  AND
              if (this.selectedCondition == "AND") {
                search = this.convertSearchText(this.searchText);
                let searchKeyArr = Object.values(search);
                console.log("Search Word --> ", _.uniq(searchKeyArr));
                let finalData = await this.promiseGetSearchWordNew(
                  "and",
                  this.searchText
                );
                this.shiyoData = _.uniqBy(finalData.flat(), "id").map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });
                this.shiyoData = this.shiyoData
                  .map((r) => {
                    r.updatedDate = r.updatedDate
                      ? moment(r.updatedDate).format("YYYY-MM-DD")
                      : "新規作成";
                    r.textContent =
                      r.textContent == " Attachment Only " ||
                      r.textContent == "Attachment Only " ||
                      r.textContent == " Attachment Only" ||
                      r.textContent == "Attachment Only"
                        ? "添付ファイルのみとなります。"
                        : r.textContent;
                    // r.specifications = r.specifications && r.specifications.match(/[0-9]/g) ? r.specifications.substring(0, r.specifications.length - 3) : ''
                    return r;
                  })
                  .sort((a, b) => {
                    return a.updatedDate > b.updatedDate ? -1 : 1;
                  });

                this.loadingShiyo = false;
              } else {
                alert(
                  "Search words should be atleast 2 words separated with space"
                );
                this.loadingShiyo = false;
              }
            }
          }
        }
      } catch (err) {
        console.log(err.message);
        alert("Error in fetching records, Please try again..");
        this.gijyutsuKanrenArr = [];
        this.loadingShiyo = false;
        }
      },
    promiseGetSearchWordNew(condition = "and", searchText) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}shiyo/search2/shiyo/${condition}/?searchText=${searchText}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
  LoadFrameWorkList() {
      const url = `${this.api}documentController/getAllDocumentControllerShiyo`;
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            let resIndex = res.data.findIndex((rec) => {
              return rec.documentName == "Shiyo";
            });
            let isJikuShow = res.data[resIndex].isShowJikuShiyo;
            if (
              (this.isIchijoUser() == true ||
                this.isGCUserNew() == true ||
                this.isGCPortalUser() == true) &&
              (isJikuShow == false || isJikuShow == undefined) &&
              this.isShiyoAdmin() == false
            ) {
              this.frameworkList = ["軸組構法", "枠組壁工法", "軸組構法/枠組壁工法"];
            } else {
              this.frameworkList = ["枠組壁工法", "枠組壁工法", "軸組構法/枠組壁工法"];
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  getUserFavorites() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.loadingShiyo = true;
      let toGet = {
        userId: this.userInfo.id,
      };
      let url = `${this.api}shiyo/getFavorites`;
      axios.post(url, toGet).then((res) => {
        this.favoriteList = res.data;
      });
    },
  async filterFavorites() {
      await this.getUserFavorites();
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.shiyoData = [];
      console.log("line1146", this.favoriteList);
      if (this.isFavorite) {
        if (this.favoriteList.length > 0) {
          for (let i = 0; i < this.favoriteList.length; i++) {
            let url2 = `${this.api}shiyo/getDetailsFavorite?id=${this.favoriteList[i].id}&productName=${this.favoriteList[i].productName}`;
            await axios.get(url2).then((res) => {
              console.log("line1147", res.data);
              if (res.data.specifications) {
                res.data.updatedDate = moment(res.data.updatedDate).format(
                  "YYYY-MM-DD"
                );
                if (res.data.specifications.match(/[0-9]/g)) {
                  res.data.specifications = res.data.specifications.substring(
                    0,
                    res.data.specifications.length - 3
                  );
                }
              }
              this.shiyoData.push(res.data);
              this.loadingShiyo = false;
            });
          }
        } else {
          this.loadingShiyo = false;
        }
      } else {
        this.clearSearch();
      }
    },
  getRoomPart(roompart) {
    if (roompart.length > 0) {
      return roompart.toString().replace(/,/g, "・");
    } else {
      return roompart.toString();
    }
  },
  clearSearch() {
      this.isMultiple = false;
      this.searchShiyoIconAlignment = false;
      this.isFavorite = false;
      this.loadingShiyo = true;
      this.sortByRoomPartASC = false;
      this.sortByRoomPartDES = false;
      this.sortBySpecsASC = false;
      this.sortBySpecsDES = false;
      this.sortByFavoriteASC = false;
      this.sortByFavoriteDES = false;
      this.sortByDateASC = false;
      this.sortByDateDES = false;
      this.loadShiyoRecords();
      this.searchText = "";
      this.exceptText = "";

      this.searchDocNo = "";
      this.searchTitle = "";
      this.isSearching = false
      
    },
  convertSearchText(word) {
      return {
        ZE_HE: moji(word)
          .convert("ZE", "HE")
          .toString(),
        HE_ZE: moji(word)
          .convert("HE", "ZE")
          .toString(),
        HK_ZK: moji(word)
          .convert("HK", "ZK")
          .toString(),
        ZK_HK: moji(word)
          .convert("ZK", "HK")
          .toString(),
        HG_KK: moji(word)
          .convert("HG", "KK")
          .toString(),
        KK_HG: moji(word)
          .convert("KK", "HG")
          .toString(),
      };
    },
  openSpecification(item){
       window.open(`/shiyoDocument/${item.specificationId}?productName=${item.productName}`, '_blank')
    },
  openPlanRule(item){
      if(!item.planRuleNo.match(/62-/g)){
        window.open(`/shiyoDocument/${item.planRuleId}?productName=${item.productName}`, '_blank')
      }
    },
  listColorSearch(id){
    if(this.searchLists.includes(id)) 
      {
      return "viewed";
      }
    },
  StartCompose() {
    this.composing = true;
    },
  EndCompose() {
    this.composing = false;
    },
  loadShiyoRecordsWithNoLoading() {
    // this.loadingShiyo = false;
    let documentType = "SHIYO";
    axios.defaults.headers.common["x-api-key"] =
      "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    let url = `${this.api}shiyo/load_shiyo_manual/${documentType}`;
    axios.get(url).then((res) => {
      this.shiyoData = res.data.map((r) => {
          ////Mapping the specifications to remove the (1)////
          // r.shiyoUpdatedDate = r.updatedDate
        r.frameWorkKatakana = r.framework == "枠組" ? "ワクグミ" : "ジクグミ"; // 2022-01-19

        if (
          r.updatedDate ||
          r.updatedDate != undefined ||
          r.updatedDate != null
        ) {
          r.updatedDate = moment(r.updatedDate).format("YYYY-MM-DD");
        } else {
            // r.updatedDate = 'Original Copy'
          r.updatedDate = "新規作成"; // 2023-02-14
        }

        if (r.specifications) {
          if (r.specifications.match(/[0-9]/g)) {
            r.specifications = r.specifications.substring(
              0,
              r.specifications.length - 3
            );
          }
        }
        if (r.roomPart) {
          if (typeof r.roomPart == "string") {
            r.roomPart = [r.roomPart];
          }
        }
        return r;
      });

        ////CODE FOR SORTING SPECIFICATIONS////
      let temp2 = this.shiyoData.sort((a, b) => {
        if (a.productName && b.productName) {
          return a.productName.localeCompare(b.productName);
        }
      });

      this.shiyoData = _.orderBy(temp2, ["shiyoNumber"], ["asc"]).map(r=>{
        this.$set(r,'showLine',false)
        return r
        }); // 2023-05-23
      });
    },
  loadShiyoRecords() {
      // With Loading
    this.loadingShiyo = true;
    let documentType = "SHIYO";
    axios.defaults.headers.common["x-api-key"] =
      "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    let url = `${this.api}shiyo/load_shiyo_manual/${documentType}`;
    axios
      .get(url)
      .then((res) => {
        let url2 = `${this.api}documentController/getAllDocumentControllerShiyo`;
        axios
          .get(url2)
          .then((res2) => {
            if (res2.data) {
              this.shiyoData = res.data.map((r) => {
                  ////Mapping the specifications to remove the (1)////
                  // r.shiyoUpdatedDate = r.updatedDate
                r.frameWorkKatakana =
                  r.framework == "'枠組壁工法'" ? "ワクグミ" : "ジクグミ";
                if (
                  r.updatedDate ||
                  r.updatedDate != undefined ||
                  r.updatedDate != null
                ) {
                  r.updatedDate = moment(r.updatedDate).format("YYYY-MM-DD");
                } else {
                    // r.updatedDate = 'Original Copy'
                  r.updatedDate = "新規作成";
                }
                if (r.specifications) {
                  if (r.specifications.match(/[0-9]/g)) {
                    r.specifications = r.specifications.substring(
                      0,
                      r.specifications.length - 3
                      );
                    }
                  }
                if (r.roomPart) {
                  if (typeof r.roomPart == "string") {
                    r.roomPart = [r.roomPart];
                  }
                }
                return r;
                });
                ////CODE FOR SORTING SPECIFICATIONS////
              let temp2 = this.shiyoData.sort((a, b) => {
                if (a.productName && b.productName) {
                    // 2022-03-25
                  return a.productName.localeCompare(b.productName);
                  }
                })
              this.shiyoData = _.orderBy(temp2, ["shiyoNumber"], ["asc"]); // NEW 2022-09-15 -> 2022-12-17
              console.log('shiyoDatasssss',this.shiyoData.filter(r=>!r.version))
              this.shiyoHistoryArr = this.shiyoData.map(r=>{
                this.$set(r,'showLine',false)
                return r
                });
              let initialDatas = this.initialData.map((r,i)=>{
                let planRule = this.shiyoData.filter(pr=>{
                  return pr.shiyoNumber == r.planrule
                })[0]
                let planSpec = this.shiyoData.filter(ps=>{
                  return ps.shiyoNumber == r.planspec
                })[0]
                let attachment1 =  this.shiyoData.filter(attach1=>{
                  return attach1.shiyoNumber == r.attachment1
                })[0]
                let attachment2 =  this.shiyoData.filter(attach2=>{
                  return attach2.shiyoNumber == r.attachment2
                })[0]
                let attachment3 =  this.shiyoData.filter(attach3=>{
                  return attach3.shiyoNumber == r.attachment3
                })[0]
                let attachment4 =  this.shiyoData.filter(attach4=>{
                  return attach4.shiyoNumber == r.attachment4
                })[0]

                let noRuleNSpec = ''

                if(!planSpec && !planRule){
                  let attachments = []
                  if(attachment1) attachments.push(attachment1)
                  if(attachment2) attachments.push(attachment2)
                  if(attachment3) attachments.push(attachment3)
                  if(attachment4) attachments.push(attachment4)

                  noRuleNSpec = attachments ? _.orderBy(attachments, ["updatedDate"], ["desc"])[0] : []
                }
                  
                return {
                  id: i,
                  productName: planRule ? planRule.productName : planSpec ? planSpec.productName : '',
                  specificationNo: planSpec ? planSpec.shiyoNumber : '',
                  specificationId: planSpec ? planSpec.id : '',
                  planRuleNo: planRule ? planRule.shiyoNumber : '',
                  planRuleId: planRule ? planRule.id : '',
                  updatedDate: planRule ? planRule.updatedDate : planSpec ? planSpec.updatedDate : noRuleNSpec.updatedDate,
                  roomPart: planRule ? planRule.roomPart : planSpec ? planSpec.roomPart : noRuleNSpec.roomPart,
                  attachment1ProductName: attachment1 ? attachment1.productName : '',
                  attachment1Id: attachment1 ? attachment1.id : '',
                  attachment2ProductName: attachment2 ? attachment2.productName : '',
                  attachment2Id: attachment2 ? attachment2.id : '',
                  attachment3ProductName: attachment3 ? attachment3.productName : '',
                  attachment3Id: attachment3 ? attachment3.id : '',
                  attachment4ProductName: attachment4 ? attachment4.productName : '',
                  attachment4Id: attachment4 ? attachment4.id : '',
                  }
                })
                
                // let productNames = [...new Set(this.shiyoData
                // .filter(r=>{
                //   if (typeof r.shiyoNumber === 'string') {
                //     return r.shiyoNumber.match(/60-|10-|62-/ig);
                //   }
                // })
                // .map(r=>{
                //   return r.productName
                // }))]
                
                // let mergeData = productNames.map((r,i)=>{
                //   let specificationIndex = this.shiyoData.findIndex(a=>{
                //     return a.productName == r && a.shiyoNumber.match(/10-/ig)
                //   })
                //   let planRuleIndex = this.shiyoData.findIndex(a=>{
                //     return a.productName == r && a.shiyoNumber.match(/60-|62-/ig)
                //   })         
                //   return {
                //     id: i,
                //     productName: r,
                //     specificationNo: specificationIndex >= 0 ? this.shiyoData[specificationIndex].shiyoNumber : '',
                //     specificationId:specificationIndex >= 0 ? this.shiyoData[specificationIndex].id : '',
                //     planRuleNo: planRuleIndex >= 0 ? this.shiyoData[planRuleIndex].shiyoNumber : '',
                //     planRuleId: planRuleIndex >= 0 ? this.shiyoData[planRuleIndex].id : '',
                //     updatedDate: specificationIndex >= 0 ? this.shiyoData[specificationIndex].updatedDate : planRuleIndex >= 0 ? this.shiyoData[planRuleIndex].updatedDate : '',
                //     CountViews: specificationIndex >= 0 ? this.shiyoData[specificationIndex].CountViews : planRuleIndex >= 0 ? this.shiyoData[planRuleIndex].CountViews : '',
                //     FavoritesCount: specificationIndex >= 0 ? this.shiyoData[specificationIndex].FavoritesCount : planRuleIndex >= 0 ? this.shiyoData[planRuleIndex].FavoritesCount : '',
                //     roomPart: specificationIndex >= 0 ? this.shiyoData[specificationIndex].roomPart : planRuleIndex >= 0 ? this.shiyoData[planRuleIndex].roomPart : '',
                //   }
                // })

              this.shiyoData = initialDatas
              console.log('merge datas',initialDatas)
              this.loadingShiyo = false;
              }
            })
          .catch((err2) => {
            console.log(err2.message);
            alert("Controller Master Failed, No Record Found");
            this.loadingShiyo = false;
          });
        })
      .catch((err1) => {
        console.log(err1.message);
        alert("Error Fetching");
        this.loadingShiyo = false;
        });
      },
    sortByRoomPart(action) {
      if (action == false) {
        console.log(false);
        this.shiyoData = this.shiyoData.sort((a,b)=>{
          if (a.roomPart < b.roomPart) {
            return -1;
          }
          if (a.roomPart > b.roomPart) {
            return 1;
          }
          return 0;
        });
        this.sortByFrameworkASC = false;
        this.sortBySpecsASC = false;
        this.sortByDateASC = false;
        this.sortByFavoriteASC = false;
      } else {
        console.log(true);
        this.shiyoData = this.shiyoData.sort((a,b)=>{
          if (a.roomPart > b.roomPart) {
            return -1;
          }
          if (a.roomPart < b.roomPart) {
            return 1;
          }
          return 0;
        });
        this.sortByRoomPartASC = true;
        this.sortByFrameworkASC = false;
        this.sortBySpecsASC = false;
        this.sortByDateASC = false;
        this.sortByFavoriteASC = false;
      }
    },
    sortByDate(action) {
      if (action == false) {
        this.shiyoData = _.orderBy(this.shiyoData, ["updatedDate"], ["desc"]);
        this.sortByFrameworkASC = false;
        this.sortByRoomPartASC - false;
        this.sortBySpecsASC = false;
        this.sortByFavoriteASC = false;
      } else {
        this.shiyoData = _.orderBy(this.shiyoData, ["updatedDate"], ["asc"]);
        this.sortByDateASC = true;
        this.sortByFrameworkASC = false;
        this.sortByRoomPartASC - false;
        this.sortBySpecsASC = false;
        this.sortByFavoriteASC = false;
        }
      },
    sortByFramework(action) {
      if (action == false) {
        console.log(false);
        this.shiyoData = this.shiyoData.sort((a,b)=>{
          if (a.framework < b.framework) {
            return -1;
          }
          if (a.framework > b.framework) {
            return 1;
          }
          return 0;
        });
        this.sortByRoomPartASC = false;
        this.sortBySpecsASC = false;
        this.sortByDateASC = false;
        this.sortByFavoriteASC = false;
      } else {
        console.log(true);
        this.shiyoData = this.shiyoData.sort((a,b)=>{
          if (a.framework > b.framework) {
            return -1;
          }
          if (a.framework < b.framework) {
            return 1;
          }
          return 0;
        });
        this.sortByFrameworkASC = true;
        this.sortByRoomPartASC = false;
        this.sortBySpecsASC = false;
        this.sortByDateASC = false;
        this.sortByFavoriteASC = false;
        }
      },
    },
  computed: {
    isFav() {
      if (this.isFavorite) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    customClearIcon() {
      return "mdi-close-circle-outline";
    },
    paginatedMemo() {
      const start = (this.pageNumber - 1) * this.intRowNoShiyo,
        end = start + this.intRowNoShiyo;
      return this.filteredShiyo.slice(start, end);
    },
    HTypeWidth() {
        if (
          this.$vuetify.breakpoint.name == "md" ||
          this.$vuetify.breakpoint.name == "lg" ||
          this.$vuetify.breakpoint.name == "xl"
        ) {
          return "width:5%";
        } else return "width:10%";
      },
    paginationLength() {
      let l = this.filteredShiyo.length,
        s = this.intRowNoShiyo;
      return Math.ceil(l / s);
    },
    paginatedShiyo() {
      const start = (this.pageNumber - 1) * this.intRowNoShiyo,
        end = start + this.intRowNoShiyo;
      return this.filteredShiyo.slice(start, end);
    },
    paginatedShiyoHistoryArr() {
      const start = (this.pageNumberShiyoHistory - 1) * this.intRowNoShiyo,
        end = start + this.intRowNoShiyo;
      return this.filteredShiyoHistoryArr.slice(start, end);
    },
    filteredShiyo() {
      return (
        this.shiyoData
          .filter((r) => {
            return !r.deleted
          })
          // .filter((r) => {
          //   if (!this.selectedBuildingType) {
          //     return r;
          //   } else {
          //     return r.productNames == this.selectedBuildingType;
          //   }
          // })
          .filter((r) => {
            if (this.selectedFramework == "ALL") {
              return r;
            }else{
              if(this.selectedFramework == '軸組構法/枠組壁工法'){
                return r.framework == this.selectedFramework;
              } else {
                return r.framework == this.selectedFramework || r.framework == '軸組構法/枠組壁工法';
              }
            }
          })
          .filter((r) => {
            ////FILTERING ROOMPART////
            if (this.selectedRoomPart.includes("ALL")) {
              return r;
            } else {
              let a = this.selectedRoomPart;
              let b = r.roomPart.toString().split("・");
              for (let i = 0; i < a.length; i++) {
                for (let ii = 0; ii < b.length; ii++) {
                  if (a[i].includes(b[ii].toString())) {
                    return b[ii];
                  }
                }
              }
             }
          })

          .filter((r) => {
            //Product Name/Title
            if(!this.searchTitle){
              return r;
            } else {
              let regex = new RegExp(this.searchTitle,"ig")
              return r.productName.match(regex)
            }
          })

          .filter((r) => {
            //Attachment Product Name/Attachment Title
            if(!this.searchAttachmentTitle){
              return r;
            } else {
              let regex = new RegExp(this.searchAttachmentTitle,"ig")
              return (
                r.attachment1ProductName.match(regex) ||
                r.attachment2ProductName.match(regex) ||
                r.attachment3ProductName.match(regex) ||
                r.attachment4ProductName.match(regex)
              )
            }
          })
          

          // .filter((r) => {
          //   // HUGme  2023-02-21
          //   if (
          //     this.selectedBuildingType.includes("HUGme") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.HUGme == true;
          //   } else {
          //     return r;
          //   }
          // })

          // .filter((r) => {
          //   // HUGme  2023-02-21
          //   if (
          //     this.selectedBuildingType.includes("HUGmefam") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.HUGmefam == true;
          //   } else {
          //     return r;
          //   }
          // })

          // .filter((r) => {
          //   //i-smile
          //   if (
          //     this.selectedBuildingType.includes("Ismile") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Ismile == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   //i-cube
          //   if (
          //     this.selectedBuildingType.includes("Icube") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Icube == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   //i-smile+
          //   if (
          //     this.selectedBuildingType.includes("Ismiles") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Ismiles == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   //i-smart
          //   if (
          //     this.selectedBuildingType.includes("Ismart") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Ismart == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // グラン・スマート
          //   if (
          //     this.selectedBuildingType.includes("Gsmart") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Gsmart == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // ブリアール
          //   if (
          //     this.selectedBuildingType.includes("B") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.B == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // ファミーユ
          //   if (
          //     this.selectedBuildingType.includes("H") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.F == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // 和風百年
          //   if (
          //     this.selectedBuildingType.includes("J") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.J == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // セゾンA
          //   if (
          //     this.selectedBuildingType.includes("A") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.A == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // セゾンF2-TD
          //   if (
          //     this.selectedBuildingType.includes("F2TD") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.F2TD == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // セゾンF
          //   if (
          //     this.selectedBuildingType.includes("F") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.F == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // グラン・セゾン
          //   if (
          //     this.selectedBuildingType.includes("G") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.G == true;
          //   } else {
          //     return r;
          //   }
          // })

          .filter((r) => {
            //Plan Rule Number and Plan Spec Number
            if(!this.searchDocNo){
              return r
            } else {
              return r.planRuleNo.match(this.searchDocNo) || r.specificationNo.match(this.searchDocNo)
            }
          })
      );
    },
    paginationShiyoHistoryLength() {
      let l = this.filteredShiyoHistoryArr.length,
        s = this.intRowNoShiyo;
      return Math.ceil(l / s);
    },
    filteredShiyoHistoryArr() {
      //NOTE: for sorting based on legend selected 2024-04-29
      let finalData = this.shiyoHistoryArr
      if(this.sortedNewHistory){
        finalData = this.shiyoHistoryNewData
      }
      else if(this.sortedDeletedHistory){
        finalData = this.shiyoHistoryDeletedData
      }
      else if(this.sortedRevisedHistory){
        finalData = this.shiyoHistoryReviseData
      }
      return (
        finalData
          .filter((a) => {
            let search = new RegExp(this.searchShiyoHistory.toUpperCase(), "g");
            if (!search) {
              return a;
            } else {
              return JSON.stringify(Object.values(a))
                .toUpperCase()
                .match(search);
            }
          })
          .filter((r) => {
            if (this.selectedSpecs == "ALL") {
              return r;
            } else {
              return r.specifications == this.selectedSpecs;
            }
          })
          .filter((r) => {
            if (this.selectedFramework == "ALL") {
              return r;
            } else {
              return r.framework == this.selectedFramework;
            }
          })
          .filter((r) => {
            ////FILTERING ROOMPART////
            if (this.selectedRoomPart.includes("ALL")) {
              return r;
            } else {
              let a = this.selectedRoomPart;
              let b = r.roomPart.toString().split("・");
              for (let i = 0; i < a.length; i++) {
                for (let ii = 0; ii < b.length; ii++) {
                  if (a[i].includes(b[ii].toString())) {
                    return b[ii];
                  }
                }
              }
              // return this.selectedRoomPart.includes(r.roomPart.toString())
            }
          })

          // 2022-02-15
          // .filter((r) => {
          //   // 一般
          //   if (
          //     this.selectedBuildingType.includes("Ippan") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Ippan == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // 夢の家
          //   if (
          //     this.selectedBuildingType.includes("YumeNoIe") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.YumeNoIe == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   //i-smile
          //   if (
          //     this.selectedBuildingType.includes("Ismile") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Ismile == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   //i-cube
          //   if (
          //     this.selectedBuildingType.includes("Icube") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Icube == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   //i-smart
          //   if (
          //     this.selectedBuildingType.includes("Ismart") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Ismart == true;
          //   } else {
          //     return r;
          //   }
          // })

          // .filter((r) => {
          //   // HUGme 2023-02-21
          //   if (
          //     this.selectedBuildingType.includes("HUGme") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.HUGme == true;
          //   } else {
          //     return r;
          //   }
          // })

          // .filter((r) => {
          //   // HUGme 2023-02-21
          //   if (
          //     this.selectedBuildingType.includes("HUGmefam") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.HUGmefam == true;
          //   } else {
          //     return r;
          //   }
          // })

          // .filter((r) => {
          //   // グラン・スマート
          //   if (
          //     this.selectedBuildingType.includes("Gsmart") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.Gsmart == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // ブリアール
          //   if (
          //     this.selectedBuildingType.includes("B") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.B == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // セゾンA
          //   if (
          //     this.selectedBuildingType.includes("A") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.A == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // セゾンF2-TD
          //   if (
          //     this.selectedBuildingType.includes("F2TD") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.F2TD == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // セゾンF
          //   if (
          //     this.selectedBuildingType.includes("F") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.F == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   // グラン・セゾン
          //   if (
          //     this.selectedBuildingType.includes("G") &&
          //     !this.selectedBuildingType.includes("ALL")
          //   ) {
          //     return r.G == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   //耐震
          //   if (
          //     this.selectedBuildingSpecification.includes("Taishin") &&
          //     !this.selectedBuildingSpecification.includes("ALL")
          //   ) {
          //     return r.Taishin == true;
          //   } else {
          //     return r;
          //   }
          // })
          // .filter((r) => {
          //   //免震
          //   if (
          //     this.selectedBuildingSpecification.includes("Menshin") &&
          //     !this.selectedBuildingSpecification.includes("ALL")
          //   ) {
          //     return r.Menshin == true;
          //   } else {
          //     return r;
          //   }
          // })
      );
    },
    shiyoHistoryNewData() {
      return this.filteredShiyo.filter((rec) => {
        return (rec.version == 1 || rec.version == 0) && !rec.deleted == true;
      });
    },
    shiyoHistoryReviseData() {
      return this.filteredShiyo.filter((rec) => {
        return rec.version > 1 && !rec.deleted == true;
      });
    },
    shiyoHistoryDeletedData() {
      return this.filteredShiyo.filter((rec) => {
        return rec.deleted == true;
      });
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
  },
}

</script>

<style>
.hideSortIcon div[title="Sort"]{
  display:none;
}
.tableProps .v-data-table__wrapper thead tr th {
  background-color: white !important;
  color: black !important;
  /* border: 1px solid black !important; */
}
.tableProps .v-data-table__wrapper tbody tr td {
  border: 1px solid black !important;
}
.header-content {
  display: flex;
  justify-content: space-between;
}
.search-label{
  padding-top: 3px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom:0%;
}
#title_ellipsis {
  display: inline-block;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>