<template>
  <v-container pa-0 ma-0 fluid>
    <!-- NOTE: disable transation in this page -->
    <div translate="no">
    <v-layout align-center justify-center v-if="loadingJapanMemo">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <v-card class="ma-2 pa-2" flat style="background-color: #f6f5f5" v-else>
      <v-row dense>
        <!-- 2023-05-30 -->
        <!--- <v-col cols="12" sm="8" md="2"  v-if="selectedCondition == 'ID & TITLE'" > -- changed to 帳票番号＆タイトル 20230704 -->
        <!-- <v-col
          cols="12"
          sm="8"
          md="2"
          v-if="selectedCondition == '帳票番号＆タイトル'"
        >
          <a-input
            addon-before="ID"
            v-model="searchDocNo"
            placeholder="Reference No."
            append-icon="search"
            outlined
          >
          </a-input>
        </v-col> -->

        <!-- 2023-05-30 -->
        <!--- <v-col cols="12" sm="8" md="2"  v-if="selectedCondition == 'ID & TITLE'" > -- changed to 帳票番号＆タイトル 20230704 -->
        <!-- <v-col
          cols="12"
          sm="8"
          md="2"
          v-if="selectedCondition == '帳票番号＆タイトル'"
        >
          <a-input
            addon-before="Title"
            v-model="searchTitle"
            placeholder="Document Title"
            append-icon="search"
            outlined
          >
          </a-input>
        </v-col> -->

        <!----<v-col cols="12" sm="8" md="4" v-if="selectedCondition != 'ID & TITLE'"  > -- changed to 帳票番号＆タイトル 20230704 -->
        <v-col
          cols="12"
          sm="8"
          md="4"
          v-if="selectedCondition != '帳票番号＆タイトル'"
        >
          <!--- changed to 全文検索 20230704 -->
          <!------ Removed a-input component replaced with v-text-field 20231104----->
          <!-- <a-input
            addon-before="検索"
            v-model="searchText"
            placeholder="検索"
            append-icon="search"
            outlined
            @compositionstart="StartCompose()"
            @compositionend="EndCompose()"
            @keyup.enter="
              selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()
            "
          >
            <a-icon
              slot="suffix"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
              @click="searchText ? clearSearch() : ''"
            />
          </a-input> -->
          <v-row dense no-gutters>
            <!-- <p
              class="search-label mb-0"
              style="font-size:15px; color:#000000a6"
            >
              検索
            </p>
            <v-text-field
              solo
              height="31px"
              flat
              clearable
              hide-details
              @click:clear="clearSearch()"
              class="custom-text-field"
              :clear-icon="customClearIcon"
              v-model="searchText"
              @keyup.enter="
                selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()
              "
            ></v-text-field> -->
            <!-- NOTE: added full text search with "AND" condition and "MULTI" 2024-06-15 -->
            <v-text-field
              style="zoom:80%; background-color:white;"
              outlined
              dense
              hide-details
              label="全文検索"
              clearable
              @keyup.enter="selectedCondition='MULTI',andMultiSearch()"
              @input="searchDocNo=''"
              @click:clear="clearSearch()"
              :clear-icon="customClearIcon"
              v-model="searchText"
            ></v-text-field>
          </v-row>
        </v-col>
        <!-- AND/OR Select Box -->
        <v-col cols="4" :sm="2" :md="1">
          <!-- <a-select v-model="selectedCondition" style="width: 100%">
            <a-select-option
              v-for="item in condition"
              :key="item"
              v-value="item"
              >{{ item }}</a-select-option
            >
          </a-select> -->
          <!-- NOTE: added document number search 2024-06-15 -->
          <v-text-field
            style="zoom:80%; background-color:white;"
            outlined
            dense
            hide-details
            label="番号検索"
            clearable
            @keyup.enter="selectedCondition='帳票番号',onSearch()"
            @input="searchText=''"
            @click:clear="clearSearch()"
            :clear-icon="customClearIcon"
            v-model="searchDocNo"
          ></v-text-field>
        </v-col>
        <!-- Except box -->

        <!-- NOTE: eliminate マイナス検索 2024-04-18 start -->
        <!-- <v-col cols="12" sm="6" md="3">
          <a-input
            addon-before="マイナス検索"
            v-model="exceptText"
            placeholder="マイナス検索"
          >
            <a-icon
              slot="suffix"
              @click="exceptText ? clearSearch() : ''"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-input>
        </v-col> -->
        <!-- NOTE: eliminate マイナス検索 2024-04-18 end -->

        <!-- Search Button -->
        <v-col cols="4" :sm="2" :md="2">
          <a-button
            :style="Width"
            class="mx-1"
            dark
            type="primary"
            icon="search"
            @click="selectedCondition != 'MULTI' ? onSearch() : andMultiSearch()"
            :disabled="!searchText && !searchDocNo && !searchTitle"
          >
            Search
          </a-button>
          <!-- NOTE: previous function allSearch() 2024-07-01 -->
          <!-- <a-button @click="searchText ? clearSearch() : ''" class='ml-1' :disabled="!searchText" dense type='primary'>Reset</a-button> -->
        </v-col>
        <v-spacer v-if="!isFilterOn" />

        <!-- Show Filter Button -->
        <!-- <v-btn
          @click="readPDF()"
        >read pdf</v-btn> -->
        <a-button
          v-if="!isFilterOn"
          icon="filter"
          @click="hideFilter = !hideFilter"
        >
          {{ hideFilter ? "View Filter" : "Hide Filter" }}
        </a-button>
      </v-row>
      <!-- //FILTER -->
      <v-row
        dense
        v-if="!isFilterOn && !hideFilter"
      >
        <v-col :xs="24" :sm="14" :md="3">
          <!-- <v-layout row wrap> -->

            <v-tooltip bottom v-if="sortByCategoryASC == false">
              <template v-slot:activator="{ on }">
                <span
                  class="mt-2 button"
                  style="font-weight: bold; font-size: 14px"
                  v-if="sortByCategoryASC == false"
                  v-on="on"
                  @click="
                    sortByCategory(sortByCategoryASC),
                      (sortByCategoryASC = !sortByCategoryASC)
                  "
                >
                  🔽 種類:
                </span>
              </template>
              <span style="font-family:Arial"> 🔽 - 昇順 </span>
            </v-tooltip>

            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <span
                  class="mt-2 button"
                  style="font-weight: bold; font-size: 14px"
                  v-on="on"
                  @click="
                    sortByCategory(sortByCategoryASC),
                      (sortByCategoryASC = !sortByCategoryASC)
                  "
                >
                  🔼 種類:
                </span>
              </template>
              <span style="font-family:Arial"> 🔼 - 降順 </span>
            </v-tooltip>

            <!-- <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              @click="
                sortByCategory(sortByCategoryASC), sortByCategoryASC = !sortByCategoryASC
              "
            >
              <span v-if="!sortByCategoryASC">🔽</span>
              <span v-else>🔼</span>
               種類:
            </span> -->

            <a-select
              class="mt-1"
              mode="multiple"
              v-model="selectedCategory"
              style="width: 50%"
            >
              <a-select-option
                v-for="item in categoryList"
                :key="item.value"
                v-value="item.value"
                >{{ item.text }}</a-select-option
              >
            </a-select>
          <!-- </v-layout> -->
        </v-col>
        <v-col :xs="24" :sm="7" :md="3">
          <span class="mt-3 mr-2" style="font-weight: bold; font-size: 14px">
            西暦:</span
          >
          <a-range-picker
            v-model="loadYear"
            style="width: 70%;"
            class="mt-2"
            @change="onChangeYear"
          >
            <a-icon
              slot="suffix"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-range-picker>
        </v-col>
        <v-col :xs="24" :sm="7" :md="3">
          <!-- <v-layout row wrap> -->
            <!-- <span
              class="button mt-1"
              v-if="sortByFavoriteASC == false"
              @click="
                sortByFavoriteASC = true;
                sortByFavoriteDES = false;
              "
            >
               🔽
            </span>
            <span
              class="button mt-1"
              v-else
              @click="
                sortByFavoriteDES = true;
                sortByFavoriteASC = false;
              "
            >
              🔼
            </span> -->

            <v-tooltip bottom v-if="sortByFavoriteASC == false">
              <template v-slot:activator="{ on }">
                <span
                  class="mt-1 button"
                  style="font-weight: bold; font-size: 14px"
                  v-if="sortByFavoriteASC == false"
                  v-on="on"
                  @click="
                    sortByFavorites(sortByFavoriteASC),
                      (sortByFavoriteASC = !sortByFavoriteASC)
                  "
                >
                  🔽
                </span>
              </template>
              <span style="font-family:Arial"> 🔽 - 昇順 </span>
            </v-tooltip>

            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <span
                  class="mt-1 button"
                  style="font-weight: bold; font-size: 14px"
                  v-on="on"
                  @click="
                    sortByFavorites(sortByFavoriteASC),
                      (sortByFavoriteASC = !sortByFavoriteASC)
                  "
                  >🔼
                </span>
              </template>
              <span style="font-family:Arial"> 🔼 - 降順 </span>
            </v-tooltip>

            <!-- <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              @click="
                sortByDocNumber(sortByFavoriteASC), sortByFavoriteASC = !sortByFavoriteASC
              "
            >
              <span v-if="!sortByFavoriteASC">🔽</span>
              <span v-else>🔼</span>
            </span> -->

            <v-icon
              small
              :style="isFavorite ? 'color:#ffa62b' : 'color:#214252'"
              class="mr-1"
              >{{ isFav }}</v-icon
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="mt-1"
                  id="favStyle"
                  @click="
                    isFavorite = !isFavorite;
                    filterFavorites();
                  "
                  outlined
                  type="primary"
                  :style="`border-color:gray; ${Width}`"
                >
                  お気に入り
                </v-btn>
              </template>
              <span>Filter Favorites</span>
            </v-tooltip>
          <!-- </v-layout> -->
        </v-col>
        <v-col :xs="24" :sm="5" :md="3">
          <v-row>
            <!-- <v-layout> -->
              <v-tooltip bottom v-if="sortByDateASC == false">
                <template v-slot:activator="{ on }">
                  <span
                    class="mt-4 button"
                    style="font-weight: bold; font-size: 14px"
                    v-if="sortByDateASC == false"
                    v-on="on"
                    @click="
                      sortByDate(sortByDateASC),
                        (sortByDateASC = !sortByDateASC)
                    "
                  >
                    🔽 更新日:</span
                  >
                </template>
                <span style="font-family:Arial"> 🔽 - 昇順 </span>
              </v-tooltip>

              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <span
                    class="button mt-4"
                    style="font-weight: bold; font-size: 14px"
                    v-on="on"
                    @click="
                      sortByDate(sortByDateASC),
                        (sortByDateASC = !sortByDateASC)
                    "
                  >
                    🔼 更新日:
                  </span>
                </template>
                <span style="font-family:Arial"> 🔼 - 降順 </span>
              </v-tooltip>

              <!-- <span
                class="mt-2 button"
                style="font-weight: bold; font-size: 14px"
                @click="
                  sortByDate(sortByDateASC), sortByDateASC = !sortByDateASC
                "
              >
                <span v-if="!sortByDateASC">🔽</span>
                <span v-else>🔼</span>
                更新日:
              </span> -->

              <!-- <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              v-if="!sortByDateASC"
              @click="
                sortByDateASC = true;
                sortByDateDES = false;
              "
            >
              🔽 更新日:</span
            >
            <span
              class="mt-2 button"
              style="font-weight: bold; font-size: 14px"
              v-else
              @click="
                sortByDateASC = false;
                sortByDateDES = true;
              "
            >
              🔼 更新日:</span
            > -->
            <!-- </v-layout> -->
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-layout align-center justify-center v-if="loadingJapanMemo2">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <v-layout class="ma-1" v-if="!loadingJapanMemo">
      <span
        style="color: #0f3057; font-weight: bold"
        v-if="!isMultiple && searchMemoIconAlignment"
      >
        About {{ filteredMemo.length }} results for keywords "{{ searchText }}"
      </span>
      <span
        style="color: #0f3057; font-weight: bold"
        v-else-if="isMultiple && searchMemoIconAlignment"
      >
        About {{ filteredMemo.length }} results for multiple keywords
      </span>

      <span
        style="color: #0f3057; font-weight: bold"
        flat
        v-else-if="
          (isMultiple && searchMemoIconAlignment) || !loadingJapanMemo2
        "
      >
        Total: {{ filteredMemo.length }}
      </span>

      <!-- <span style="color:red; font-weight:bold;font-family:Arial" class="ml-5" v-if="isFileDownloading">
        Please wait while downloading file..
      </span> -->

      <v-spacer />
    </v-layout>
    <v-card
      flat
      style="overflow-y: scroll"
      :height="hideFilter ? screenSize - 260 : screenSize - 300"
      class="ma-1 pa-1"
      v-if="!loadingJapanMemo"
    >
      <template v-if="!loadingJapanMemo2">
        <div>
          <!-- <pre> {{paginatedMemo}} </pre> -->
          <v-card elevation="0" v-for="(item, i) in paginatedMemo" :key="i">
            <v-divider />

            <v-row dense class="mb-n1">
              <v-col cols="12" md="7">
                <v-layout>
                  <!-- NOTE: delete icon not necessary in aws 2024-08-07 -->
                  <!-- <v-icon color="#f55666" class="ml-2"
                    >mdi-file-document</v-icon
                  > -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        :color="isFavColor(item)"
                        class="ml-2"
                        @click="favoriteFunction(item)"
                        >{{ isFavIcon(item) }}</v-icon
                      >
                    </template>
                    <span>
                      {{
                        isFavIcon(item) != "mdi-star"
                          ? "Add to Favorite"
                          : "Delete from Favorite"
                      }}
                    </span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span
                        v-on="on"
                        id="title_ellipsis"
                        @click="
                          openMemo(item);
                          searchLists.push(item.id, item.category);
                        "
                        :class="listColorSearch(item.id, item.category)"
                        class="mx-2 mt-n2;"
                        style="font-size: 20px; font-weight: bold;  cursor:pointer"
                        v-html="
                          `<span style='background-color:#31b16e; border-radius:4px' class='px-3 mr-2 white--text'><span style='font-size:15px;font-weight:normal;'>${item.docNo}</span></span>${item.title}${title_spacer}`
                        "
                      />
                    </template>
                    <span
                      v-html="
                        item.title.replace(
                          'background-color:yellow',
                          'background-color:none'
                        )
                      "
                    />
                  </v-tooltip> -->
                  <!-- NOTE: remove tooltip 2024-04-30 start -->
                      <span
                        id="title_ellipsis"
                        @click="
                          openMemo(item);
                          searchLists.push(item.id, item.category);
                        "
                        :class="listColorSearch(item.id, item.category)"
                        class="mx-2 mt-n2;"
                        style="font-size: 20px; font-weight: bold;  cursor:pointer"
                        v-html="
                          `<span style='background-color:#31b16e; border-radius:4px' class='px-3 mr-2 white--text'><span style='font-size:15px;font-weight:normal;'>${item.docNo}</span></span>${item.title}${title_spacer}`
                        "
                      />
                  <!-- NOTE: remove tooltip 2024-04-30 end -->
                </v-layout>
              </v-col>
              <!-- NOTE: added compression of subline after searching 2024-05-23 -->
              <v-col cols="1" class="d-flex">
                <v-spacer></v-spacer>
                <div v-if="searchMemoIconAlignment && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'">
                  <v-btn color="primary" icon @click="toggleShowLine(item)">
                    <v-icon large>{{item.showLine ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col class="pt-1" cols="12" md="4">
                <v-row dense>
                  <v-col cols="5"
                    ><a-tag
                      style="width: 100%; color: white; text-align: center"
                      :color="
                        item.category == '連絡票'
                          ? '#108ee9'
                          : item.category == '設計課通達'
                          ? '#87d068'
                          : '#f50'
                      "
                      >{{ item.category }}</a-tag
                    ></v-col
                  >

                  <v-col cols="4"
                    ><a-tag
                      color="#3d7ea6"
                      style="width: 100%; color: white; text-align: center"
                      small
                    >
                      <span class="hidden-md-and-down">更新日:</span>
                      {{ item.memoUpdatedDate }}</a-tag
                    ></v-col
                  >

                  <v-col cols="1">
                    <v-icon color="#6ebaa7" style="font-size: xx-small"
                      >mdi-eye</v-icon
                    ><span
                      v-if="item.countViews"
                      style="font-size: font-size:xx-small"
                      >{{ item.countViews }}
                    </span>
                    <span v-else style="font-size: 12px"> 0 </span>
                  </v-col>

                  <v-col cols="1"
                    ><v-icon small color="#ffa62b">mdi-star</v-icon>
                    <span style="font-size: 12px" v-if="item.FavoritesCount"
                      >{{ item.FavoritesCount }}
                    </span>
                    <span v-else style="font-size: 12px"> 0 </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12">
                <!-- <pre> {{ item.textContent }} </pre> -->
                <v-layout>
                  <span
                    class="title"
                    v-if="searchMemoIconAlignment && item.showLine && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'"
                    v-html="`${item.textContent}`"
                    style="color: #555555; margin-left: 30px; font-size: 12px"
                  >
                  </span>
                </v-layout>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </template>

      <a-empty
        v-if="
          !loadingJapanMemo && !loadingJapanMemo2 && paginatedMemo.length == 0
        "
      />
    </v-card>
    <!---- Removed 20231124------->
    <!-- <v-pagination
      v-model="pageNumber"
      :length="paginationLength"
      :total-visible="5"
      dark
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      v-if="!loadingJapanMemo && !loadingJapanMemo2"
    ></v-pagination> -->

    <!-- <v-dialog  
      v-model="ViewPdfDialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-if="ViewPdfDialog">
        <memo-pdf-viewer :closeDialog="onClosePdfDialog" :memoDetails="memoDetails" :linkURLBlob="linkURLBlob"/>
        
      </template>
    </v-dialog> -->

    <loading-file :isFileDownloadingProps="isFileDownloading" />
    <!-- 2022-12-07 -->
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import moji from "moji";

// import AWS from "aws-sdk";
// import tunnel from "tunnel";
// import config from "../config";
// import * as binconv from "binconv";

import Swal from "sweetalert2";
import moment from "moment";
// import FileSaver from "file-saver";
// import converter from "base64-arraybuffer";
import _ from "lodash";

import LoadingFile from "./LoadingComponent.vue";

export default {
  components: {
    // "memo-pdf-viewer": MemoPdfViewer,
    // 'japan-memo-link': JapanMemoLink
    "loading-file": LoadingFile,
  },
  data() {
    return {
      linkURLBlob: null,
      memoDetails: {},
      ViewPdfDialog: false,
      loadingJapanMemo2: false,
      loadYear: [],
      selectedYear: "",
      yearList: [],
      searchLists: [],
      title_spacer: "　",
      isMultiple: false,
      searchMemoIconAlignment: false,
      sortByCategoryDES: false,
      sortByCategoryASC: false,
      isFilterOn: false,
      hideFilter: false,//NOTE: show the filter in default 2024-04-18
      searchText: "",
      exceptText: "",
      selectedCondition: "AND",
      // condition: ["AND", "OR", "MULTI", "ID-TITLE"],
      //  condition: ["AND", "OR", "MULTI"],//removed 20230703
      // condition: ["AND", "OR", "MULTI" , "REFERENCE #", "TITLE", "ID & TITLE" ],//20230704 some change to japanese text
      // condition: ["AND", "OR", "MULTI" , "帳票番号", "タイトル", "帳票番号＆タイトル" ], // removed 帳票番号＆タイトル 20230711
      condition: ["AND", "OR", "MULTI", "帳票番号", "タイトル"],
      pageNumber: 1,
      intRowNoMemo: 30,
      loadingJapanMemo: true,
      memoData: [],
      selectedCategory: ["ALL"],
      categoryList: [
        { text: "ALL", value: "ALL" },
        { text: "連絡票", value: "連絡票" },
        { text: "設計課通達", value: "設計課通達" },
        { text: "設計課・工事課通達", value: "設計課・工事課通達" },
      ],
      sortByDateASC: false,
      sortByDateDES: false,
      sortByDocNoASC: false,
      sortByDocNoDES: false,
      sortByFavoriteASC: false,
      sortByFavoriteDES: false,
      isFavorite: false,
      composing: false,
      favoriteList: [],

      isAllSearch: false, // 2022-05-04

      searchDocNo: "", // 2023-05-30
      searchTitle: "",
    };
  },
  async created() {
    this.selectedYear = moment().format("YYYY");

    if (this.globalSearchWord && this.globalCondition) {
      if (this.isSearchAll) {
        this.searchText = this.globalSearchWord;
        this.selectedCondition = this.globalCondition;
        this.exceptText = this.globalExceptWord;

        if (this.selectedCondition != "MULTI") {
          this.onSearch();
        } else {
          this.onMultipleSearch();
        }
      }
    } else {
      this.loadMemoByYear();
    }
    await this.getUserFavorites();

    // await this.getAllYears();

    // 2023-04-19
    if (this.isSalesUser()) {
      this.categoryList = [
        { text: "ALL", value: "ALL" },
        { text: "連絡票", value: "連絡票" },
      ];
    } else {
      this.categoryList = [
        { text: "ALL", value: "ALL" },
        { text: "連絡票", value: "連絡票" },
        { text: "設計課通達", value: "設計課通達" },
        { text: "設計課・工事課通達", value: "設計課・工事課通達" },
      ];
    }
  },
  watch: {
    selectedCategory() {
      if (
        this.selectedCategory.includes("ALL") &&
        this.selectedCategory.length == 2
      ) {
        this.selectedCategory.splice(0, 1);
      }
      if (this.selectedCategory.length == 0) {
        this.selectedCategory = ["ALL"];
      }
      if (
        this.selectedCategory.includes("ALL") &&
        this.selectedCategory.length != 1
      ) {
        this.selectedCategory = ["ALL"];
      }
    },

    selectedCondition(val) {
      // 2023-05-30
      if (val) {
        // this.searchDocNo = "";
        this.searchTitle = "";
      }
    },

    // ViewPdfDialog(val){   // 2022-03-05
    //   if(val){
    //     // console.log('line512',this.$router.currentRoute)
    //     if( this.$router.currentRoute.name == 'home') {
    //        document.title = '@Document Search System'

    //     }

    //   }
    // }
  },
  methods: {
    //NOTE: merged search function for "AND" & "MULTI" 2024-09-25
    async andMultiSearch(){
      try{
        this.loadingJapanMemo = true;
        // this.selectedYear = "All";
        this.selectedCondition='MULTI'
        this.ViewPdfDialog = false; // 2022-03-05
        this.pageNumber = 1; // 2022-02-03
        this.searchMemoIconAlignment = true;
        let notIncludesCategory = ["設計課通達", "設計課・工事課通達"];
        this.memoData = await this.mergedSearch()
        this.memoData = _.uniqBy(this.memoData.flat(), "id").map(r=>{
          this.$set(r,'showLine',false)
          return r
        });
  
        // 2023-04-19 Check If Sales User
        if (this.isSalesUser()) {
          this.memoData = this.memoData
            .filter((rec) => {
              return !notIncludesCategory.includes(rec.category);
            })
            .sort((a, b) => {
              return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
            });
        } else {
          this.memoData = this.memoData.sort((a, b) => {
            return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
          });
        }
        this.loadingJapanMemo = false;
        this.searchDialog = false;

        // let dateString = [this.loadYear[0], this.loadYear[1]];

        // if (this.isAllSearch == false) {
        //   // 2022-05-04
        //   this.onChangeYear("a", dateString, "from Search");
        // }
      }catch(err){
        if(err){
          console.log(err)
        }
      }
    },
    mergedSearch() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search2/japanMemo/andmulti/?searchText=${this.searchText}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    // NOTE: added search function for search button 2024-06-21
    allSearch(){
      if(this.searchDocNo){
        this.selectedCondition = '帳票番号'
        this.onSearch()
      }
      else{
        this.selectedCondition = 'AND'
        this.fullTextSearch()
      }
    },
    // NOTE: added fulltext search include "AND" conditiona and "MULTI" 2024-06-15
    fullTextSearch(){
      this.loadingJapanMemo = true;
      this.searchDocNo = ""
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      const url = `${this.api}check/multi?group=${this.searchText}`;
      axios.get(url).then((res)=>{
        if(res.data){
          this.onMultipleSearch()
        }
        else{
          this.onSearch()
        }
      })
    },
    // NOTE: added toggle for show/hide of subline after searching 2024-05-23
    toggleShowLine(item){
      let index = this.memoData.indexOf(item)
      if(index > -1){
        this.memoData[index].showLine = !this.memoData[index].showLine
      }
    },

    readPDF(){
      console.log('read pdf')
        fetch('https://s3.us-east-2.amazonaws.com/rulebook.files/Memo/%E9%80%9A%E9%81%942023-023.pdf').then(async(res)=>{
          let file = await res.blob()
          var reader = new FileReader();
          reader.readAsDataURL(file,'SHIFT-JIS');
          reader.onload = function () {
            console.log(reader.result);
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
        })
    },
    sortByDocNumber(action) {
      if (action) {
        return this.memoData.sort((a, b) => {
          return a.docNo.localeCompare(b.docNo);
        });
      } else {
        return this.memoData.sort((a, b) => {
          return b.docNo.localeCompare(a.docNo);
        });
      }
    },
    sortByFavorites(action) {
      // 2022-06-14
      if (action == false) {
        let tmpMemoData = this.memoData.map((rec) => {
          if (rec.FavoritesCount == undefined) {
            rec.FavoritesCount = 0;
          }
          return rec;
        });
        this.memoData = tmpMemoData.sort((a, b) =>
          a.FavoritesCount < b.FavoritesCount ? 1 : -1
        );
        this.sortByCategoryASC = false;
        this.sortByDateASC = false;
      } else {
        let tmpMemoData = this.memoData.map((rec) => {
          if (rec.FavoritesCount == undefined) {
            rec.FavoritesCount = 0;
          }
          return rec;
        });
        this.memoData = tmpMemoData.sort((b, a) =>
          b.FavoritesCount > a.FavoritesCount ? 1 : -1
        );
        this.sortByCategoryASC = false;
        this.sortByFavoriteASC = true;
        this.sortByDateASC = false;
      }
    },
    sortByCategory(action) {
      // 2022-06-14
      if (this.selectedCategory.includes("ALL")) {
        console.log("line585", action);
        if (action == false) {
          this.memoData = _.orderBy(this.memoData, ["category"], ["desc"]);
          this.sortByDateASC = false;
          this.sortByFavoriteASC = false;
        } else {
          this.memoData = _.orderBy(this.memoData, ["category"], ["asc"]);
          this.sortByCategoryASC = true;
          this.sortByDateASC = false;
          this.sortByFavoriteASC = false;
        }
        // if(action){
        //   return this.memoData.sort((a,b) => {
        //     return a.category.localeCompare(b.category)
        //   })
        // }else{
        //   return this.memoData.sort((a,b) => {
        //     return b.category.localeCompare(a.category)
        //   })
        // }
      } else {
        this.sortByDocNumber(action);
      }
    },

    sortByDate(action) {
      // 2022-06-14
      if (action == false) {
        console.log("sort by updated date");
        this.memoData = _.orderBy(this.memoData, ["memoUpdatedDate"], ["desc"]);
        this.sortByCategoryASC = false;
        this.sortByFavoriteASC = false;
      } else {
        this.memoData = _.orderBy(this.memoData, ["memoUpdatedDate"], ["asc"]);
        this.sortByCategoryASC = false;
        this.sortByFavoriteASC = false;
        this.sortByDateASC = true;
      }
    },
    onChangeYear(date, dateString, fromSearch) {
      // Get All Japan Memos
      if (dateString[0] == "") {
        // 2022-05-04
        this.memoData = [];
        this.loadingJapanMemo2 = true;
        this.isAllSearch = true;
        let url = `${this.api}japanMemo/filterByAllJapanMemos`;
        axios.get(url).then((r) => {
          this.memoData = _.orderBy(
            r.data,
            ["memoUpdatedDate", "docNo"],
            ["desc", "desc"]
          );
          this.loadingJapanMemo2 = false;
        });
      }

      if (dateString.length == 2 && dateString[0] != "") {
        // 2022-05-04
        // this.loadingJapanMemo2 = true
        this.isAllSearch = false;
        this.loadYear = dateString;
        //==========EUGENE===============================
        if (fromSearch === undefined) {
          // add if/else -Eugene
          if (this.searchText != "") {
            // add nested if/else for changing date while search has text  -Eugene

            this.onSearch();
          } else {
            this.loadingJapanMemo2 = true;
            let url = `${this.api}japanMemo/getByYear?from=${this.loadYear[0]}&to=${this.loadYear[1]}`;

            axios
              .get(url)
              .then((r) => {
                return (this.memoData = r.data);
              })
              .then(() => {
                this.loadingJapanMemo2 = false;
              });
          }
        } else {
          this.memoData = this.memoData.filter((rec) => {
            return (
              moment(rec.memoUpdatedDate).isSameOrAfter(this.loadYear[0]) &&
              moment(rec.memoUpdatedDate).isSameOrBefore(this.loadYear[1])
            );
          });
        }

        //================================================
      }

      // console.log('line608', this.isAllSearch)
    },
    // getAllYears(){
    //   let url = `${this.api}japanMemo/getAllYear`
    //   axios.get(url).then(res => {
    //     let temp = new Set(res.data.map(r => {
    //       return moment(r).format('YYYY')
    //     }))
    //     this.yearList = [...temp]
    //     this.yearList.unshift('All')

    //   })
    // },
    favoriteFunction(data) {
      let favorite = true;
      if (this.isFavIcon(data) == "mdi-star") {
        favorite = false;
      }

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      let toInsert = {
        id: data.id,
        docNo: data.docNo,
        category: data.category,
        userId: this.userInfo.id,
        isFavorite: favorite,
      };
      // let url = `${this.api}japanMemo/addFavorite/memo`;
      // let url2 = `${this.api}japanMemo/addFavoriteCount/memo`;
      let url = `${this.api}addFavorite/memo`;
      let url2 = `${this.api}addFavoriteCount/memo`;
      axios.post(url2, toInsert).then(() => {
        axios.post(url, toInsert).then((res) => {
          console.log(res.data);
          if (res.data == "Add Favorite") {
            Swal.fire({
              icon: "success",
              title: `${data.docNo} added to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: `${data.docNo} deleted to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          }
          if (this.searchText != "") {
            this.onSearch();
            this.getUserFavorites();
          } else {
            this.loadMemoByYear();
            this.getUserFavorites();
          }
        });
      });
    },
    listColorSearch(id, category) {
      if (
        this.searchLists.includes(id) &&
        this.searchLists.includes(category)
      ) {
        return "viewed";
      }
    },

    async openMemo(data) {
      // 2022-12-06
      try {
        this.memoDetails = data;
        // this.isFileDownloading = true
        let url = `${this.api}japanMemo/addCount/memo/${data.id}?docNo=${data.docNo}`;
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        await axios.post(url);

        // 2022-12-06
        // let folderName = `Memo`
        // let fileSource = `${this.s3URL}/${folderName}/${data.attachment}`

        // this.downloadOriginalFile(fileSource , data.attachment) // Download Attachment
        // this.openNewTabPDFFile(fileSource)  // Open New Tab 2023-03-22
        console.log(data);
        window.open(
          `${this.linkURL}/japan_memo/${data.id}/?docNo=${data.docNo}`, // 2023-03-22
          "_blank"
        );
      } catch (err) {
        console.log(err);
        this.isFileDownloading = false;
      }
    },

    openMemo2(data) {
      // OLD
      // this.ViewPdfDialog = true
      this.memoDetails = data;

      let url = `${this.api}japanMemo/addCount/memo/${data.id}?docNo=${data.docNo}`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.post(url).then(() => {
        if (this.searchText !== "") {
          this.onSearch(data);
          this.getUserFavorites();
        } else {
          this.loadMemoByYear();
          this.getUserFavorites();
        }
      });

      // window.open(
      //     `${this.linkURL}/japan_memo/${data.id}?docNo=${data.docNo}`,
      //  "_blank")

      // var tunnelingAgent = tunnel.httpsOverHttp({
      //   proxy: {
      //     // Proxy settings
      //     host: "hrdproxy.hrd-s.com",
      //     port: 81,
      //     proxyAuth: "administrator:system",
      //   },
      // });

      // AWS.config.update({
      //   httpOptions: { agent: tunnelingAgent },
      //   region: "us-east-2",
      //   credentials: config,
      // });
      // var s3 = new AWS.S3({
      //   AccessKeyID: this.accessKeyId
      //   SecretAccessKey: this.secretAccessKey,
      //   Region: "us-east-2",
      //   params: {
      //     bucket: "rulebook.files",
      //   },
      // });

      // var options = {
      //   Bucket: "rulebook.files",
      //   Key: `Memo/${data.attachment}`,
      // };
      // s3.getObject(options, function (err, data) {
      //   if (err) {
      //     console.log(err, err.stack);
      //   }
      //   // an error occurred
      //   else {
      //     var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
      //       type: `${data.ContentType};charset=utf-8`,
      //     });
      //     let url = URL.createObjectURL(blob);
      //     console.log('line880', url );
      //     window.open(url, "_blank");
      //   }
      //   // });
      // });
    },
    isFavColor(data) {
      let isFavMemo = false;
      this.favoriteList.map((r) => {
        if (r.id == data.id && r.category == data.category) {
          isFavMemo = true;
        }
      });
      if (isFavMemo) {
        return "yellow";
      } else return "black";
    },
    StartCompose() {
      this.composing = true;
    },
    EndCompose() {
      this.composing = false;
    },

    convertSearchText(word) {
      return {
        ZE_HE: moji(word)
          .convert("ZE", "HE")
          .toString(),
        HE_ZE: moji(word)
          .convert("HE", "ZE")
          .toString(),
        HK_ZK: moji(word)
          .convert("HK", "ZK")
          .toString(),
        ZK_HK: moji(word)
          .convert("ZK", "HK")
          .toString(),
        HG_KK: moji(word)
          .convert("HG", "KK")
          .toString(),
        KK_HG: moji(word)
          .convert("KK", "HG")
          .toString(),
      };
    },

    promiseGetSearchWord(condition = "and", search1 = "", search2 = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search/japanMemo/${condition}/?search1=${search1}&search2=${search2}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    //added 2024-01-25 FOR DYNAMIC SEARCHING
    promiseGetSearchWordNew(condition = "and", searchText) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search2/japanMemo/${condition}/?searchText=${searchText}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    // added 20230704
    promiseGetSearchWordByDocumentCategory(condition = "", search = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search/japanMemo/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    //2024-01-24 FOR DYNAMIC SEARCHING
    promiseGetSearchWordByDocumentCategoryNew(condition = "", search = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}japanMemo/search2/japanMemo/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    async onSearch() {
      try {
        this.loadingJapanMemo = true;
        this.selectedYear = "All";
        this.selectedCondition='帳票番号'
        this.ViewPdfDialog = false; // 2022-03-05
        this.pageNumber = 1; // 2022-02-03
        this.searchMemoIconAlignment = true;
        this.isMultiple = false;
        let search = "";
        let japaneseSpace = "　";

        let notIncludesCategory = ["設計課通達", "設計課・工事課通達"];

        // Check if Selected Condition is BY Reference Number
        // if( this.selectedCondition == 'REFERENCE #' ) { // change to 帳票番号 20230704
        if (this.selectedCondition == "帳票番号") {
          this.searchText = ""
          if (this.searchDocNo.trim()) {
            let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
              "byDocumentNumber",
              this.searchDocNo
            );
            this.memoData = finalData;
            this.searchDialog = false;
            this.loadingJapanMemo = false;
          } else {
            alert(`Please Input Reference Number`);
            this.loadingJapanMemo = false;
          }
        }
        // Check If Selected Condition is BY TITLE
        // else if( this.selectedCondition == 'TITLE') { // changed to タイトル 20230704
        else if (this.selectedCondition == "タイトル") {
          if (this.searchText.trim()) {
            let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
              "byDocumentTitle",
              this.searchText
            );
            this.memoData = finalData;
            this.searchDialog = false;
            this.loadingJapanMemo = false;
          } else {
            alert(`Please Input Document Title`);
            this.loadingJapanMemo = false;
          }
        }
        // Check If SELECTED CONDITION IS BY Document Number and Title
        //else if( this.selectedCondition == 'ID & TITLE') {  //changed to 帳票番号＆タイトル 20230704
        else if (this.selectedCondition == "帳票番号＆タイトル") {
          if (this.searchDocNo.trim() && this.searchTitle.trim()) {
            // alert('search id title')
            let finalData = await this.promiseGetSearchWord(
              "docNoTitle",
              this.searchDocNo,
              this.searchTitle
            );
            this.memoData = finalData.map(r=>{
              this.$set(r,'showLine',false)
              return r
            });
            this.searchDialog = false;
            this.loadingJapanMemo = false;
          } else {
            alert(`Please Input Reference Number and Title`);
            this.loadingJapanMemo = false;
          }
        } else {
          if (this.searchText) {
            // 2 Words
            if (
              this.searchText.includes(" ") ||
              this.searchText.includes(japaneseSpace)
            ) {
              // Split Two Words into array
              let splitKeyWord = this.searchText
                .replace(/\s+/g, " ")
                .split(" ");

              // Get Keyword per index ( as of now 2 words only )
              let keywordsArr1 = Object.values(
                this.convertSearchText(splitKeyWord[0])
              );
              let keywordsArr2 = Object.values(
                this.convertSearchText(splitKeyWord[1])
              );

              console.log("Search word 1 --> ", keywordsArr1);
              console.log("Search word 2 --> ", keywordsArr2);

              // Selected Condition
              // ===================================== AND =========================================== //
              if (this.selectedCondition == "AND") {
                if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                  let finalData = await this.promiseGetSearchWordNew(
                    "and",
                    this.searchText
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                  );
                  this.memoData = _.uniqBy(finalData.flat(), "id").map(r=>{
                    this.$set(r,'showLine',false)
                    return r
                  });

                  // OLD
                  // this.memoData = this.memoData.sort((a,b) => {
                  //    return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
                  // })

                  // 2023-04-19 Check If Sales User
                  if (this.isSalesUser()) {
                    this.memoData = this.memoData
                      .filter((rec) => {
                        return !notIncludesCategory.includes(rec.category);
                      })
                      .sort((a, b) => {
                        return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
                      });
                  } else {
                    this.memoData = this.memoData.sort((a, b) => {
                      return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
                    });
                  }

                  this.searchDialog = false;
                  this.loadingJapanMemo = false;

                  let dateString = [this.loadYear[0], this.loadYear[1]];

                  if (this.isAllSearch == false) {
                    // 2022-05-04
                    this.onChangeYear("a", dateString, "from Search");
                  }
                }
              } else {
                // ==================================== OR ============================================== //
                if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                  let finalData = await this.promiseGetSearchWordNew(
                    "or",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  this.memoData = _.uniqBy(finalData.flat(), "id").map(r=>{
                    this.$set(r,'showLine',false)
                    return r
                  });

                  // OLD
                  //  this.memoData = this.memoData.sort((a,b) => {
                  //    return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
                  // })
                  // 2023-04-19 Check If Sales User
                  if (this.isSalesUser()) {
                    this.memoData = this.memoData
                      .filter((rec) => {
                        return !notIncludesCategory.includes(rec.category);
                      })
                      .sort((a, b) => {
                        return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
                      });
                  } else {
                    this.memoData = this.memoData.sort((a, b) => {
                      return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
                    });
                  }

                  this.searchDialog = false;
                  this.loadingJapanMemo = false;

                  let dateString = [this.loadYear[0], this.loadYear[1]];

                  if (this.isAllSearch == false) {
                    // 2022-05-04
                    this.onChangeYear("a", dateString, "from Search");
                  }
                }
              }

              this.loadingJapanMemo = false;
            } else {
              // 1 Word  AND
              if (this.selectedCondition == "AND") {
                search = this.convertSearchText(this.searchText);
                let searchKeyArr = Object.values(search);

                console.log("Search Word --> ", _.uniq(searchKeyArr));

                let finalData = await this.promiseGetSearchWordNew(
                  "and",
                  this.searchText
                );

                this.memoData = _.uniqBy(finalData.flat(), "id").map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });

                // OLD
                // this.memoData = this.memoData.sort((a,b) => {
                //     return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
                // })

                // 2023-04-19 Check If Sales User
                if (this.isSalesUser()) {
                  this.memoData = this.memoData
                    .filter((rec) => {
                      return !notIncludesCategory.includes(rec.category);
                    })
                    .sort((a, b) => {
                      return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
                    });
                } else {
                  this.memoData = this.memoData.sort((a, b) => {
                    return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
                  });
                }

                this.searchDialog = false;
                this.loadingJapanMemo = false;

                let dateString = [this.loadYear[0], this.loadYear[1]];

                if (this.isAllSearch == false) {
                  // 2022-05-04
                  this.onChangeYear("a", dateString, "from Search");
                }
              } else {
                alert(
                  "Search words should be atleast 2 words separated with space"
                );
                this.searchDialog = false;
                this.loadingJapanMemo = false;
              }
            }
          }
        }
      } catch (err) {
        console.log(err.message);
        alert("Error in fetching records, Please try again..");
        this.memoData = [];
        this.loadingJapanMemo = false;
      }
    },
    // async onSearch() {
    //   try{
    //       this.loadingJapanMemo = true
    //       this.selectedYear = 'All'
    //       this.ViewPdfDialog = false // 2022-03-05
    //       this.pageNumber = 1  // 2022-02-03
    //       this.searchMemoIconAlignment = true;
    //       this.isMultiple = false;
    //       let search = ""
    //

    //       let notIncludesCategory = ['設計課通達', '設計課・工事課通達']

    //       // if( this.selectedCondition == 'ID-TITLE') {

    //       // }

    //       if( this.searchText ) {
    //           // 2 Words
    //           if( this.searchText.includes(' ') || this.searchText.includes( japaneseSpace ) ) {

    //               // Split Two Words into array
    //               let splitKeyWord = this.searchText.replace(/\s+/g, ' ').split(' ');

    //               // Get Keyword per index ( as of now 2 words only )
    //               let keywordsArr1 =  Object.values(  this.convertSearchText( splitKeyWord[0] ) )
    //               let keywordsArr2 =  Object.values(  this.convertSearchText( splitKeyWord[1] ) )

    //               console.log( 'Search word 1 --> ' , keywordsArr1 )
    //               console.log( 'Search word 2 --> ' , keywordsArr2 )

    //               // Selected Condition
    //               // ===================================== AND =========================================== //
    //               if ( this.selectedCondition == 'AND') {

    //                   if( keywordsArr1.length != 0 && keywordsArr2.length != 0 ) {
    //                       let finalData = await this.promiseGetSearchWord( 'and', splitKeyWord[0], splitKeyWord[1] );
    //                       this.memoData  = _.uniqBy (finalData.flat() , 'id');

    //                       // OLD
    //                       // this.memoData = this.memoData.sort((a,b) => {
    //                       //    return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                       // })

    //                       // 2023-04-19 Check If Sales User
    //                       if( this.isSalesUser() ) {
    //                         this.memoData = this.memoData
    //                         .filter(rec=>{
    //                             return !notIncludesCategory.includes(rec.category)
    //                         })
    //                         .sort((a,b) => {
    //                             return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                         })
    //                       }
    //                       else{
    //                         this.memoData = this.memoData.sort((a,b) => {
    //                             return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                         })
    //                       }

    //                       this.searchDialog = false;
    //                       this.loadingJapanMemo = false;

    //                       let dateString = [this.loadYear[0], this.loadYear[1]]

    //                       if( this.isAllSearch == false) { // 2022-05-04
    //                           this.onChangeYear('a' , dateString, 'from Search')
    //                       }

    //                   }
    //               }

    //               else{
    //               // ==================================== OR ============================================== //
    //                   if( keywordsArr1.length != 0 && keywordsArr2.length != 0 ) {

    //                       let finalData = await this.promiseGetSearchWord( 'or', splitKeyWord[0], splitKeyWord[1] );
    //                       this.memoData  = _.uniqBy (finalData.flat() , 'id');

    //                       // OLD
    //                       //  this.memoData = this.memoData.sort((a,b) => {
    //                       //    return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                       // })
    //                       // 2023-04-19 Check If Sales User
    //                       if( this.isSalesUser() ) {
    //                         this.memoData = this.memoData
    //                         .filter(rec=>{
    //                             return !notIncludesCategory.includes(rec.category)
    //                         })
    //                         .sort((a,b) => {
    //                             return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                         })
    //                       }
    //                       else{
    //                         this.memoData = this.memoData.sort((a,b) => {
    //                             return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                         })
    //                       }

    //                       this.searchDialog = false;
    //                       this.loadingJapanMemo = false;

    //                       let dateString = [this.loadYear[0], this.loadYear[1]]

    //                       if( this.isAllSearch == false) { // 2022-05-04
    //                           this.onChangeYear('a' , dateString, 'from Search')
    //                         }

    //                   }
    //               }

    //               this.loadingJapanMemo = false
    //           }
    //           else {
    //               // 1 Word  AND
    //               if( this.selectedCondition == 'AND') {

    //                   search = this.convertSearchText( this.searchText )
    //                   let searchKeyArr =  Object.values( search )

    //                   console.log( 'Search Word --> ' , _.uniq( searchKeyArr) )

    //                   let finalData = await this.promiseGetSearchWord( 'and', this.searchText );

    //                   this.memoData  = _.uniqBy (finalData.flat() , 'id');

    //                   // OLD
    //                   // this.memoData = this.memoData.sort((a,b) => {
    //                   //     return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                   // })

    //                   // 2023-04-19 Check If Sales User
    //                   if( this.isSalesUser() ) {
    //                      this.memoData = this.memoData
    //                      .filter(rec=>{
    //                         return !notIncludesCategory.includes(rec.category)
    //                      })
    //                      .sort((a,b) => {
    //                         return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                     })
    //                   }
    //                   else{
    //                     this.memoData = this.memoData.sort((a,b) => {
    //                         return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1
    //                     })
    //                   }

    //                   this.searchDialog = false;
    //                   this.loadingJapanMemo = false;

    //                   let dateString = [this.loadYear[0], this.loadYear[1]]

    //                   if( this.isAllSearch == false) { // 2022-05-04
    //                       this.onChangeYear('a' , dateString, 'from Search')
    //                   }
    //               }
    //               else{
    //                     alert('Search words should be atleast 2 words separated with space');
    //                     this.searchDialog = false;
    //                     this.loadingJapanMemo = false;
    //               }

    //           }
    //       }
    //   }catch(err) {
    //       console.log(err.message)
    //       alert('Error in fetching records, Please try again..');
    //       this.memoData = []
    //       this.loadingJapanMemo = false
    //   }
    // },

    onSearch1() {
      // backup old

      this.selectedYear = "All";
      this.ViewPdfDialog = false; // 2022-03-05
      this.pageNumber = 1; // 2022-02-03
      if (!this.composing) {
        this.searchMemoIconAlignment = true;
        this.isMultiple = false;
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        let search = this.convert2FullKatakana(this.searchText).trim();
        // console.log('lineSEARCH', search )
        if (this.searchText != "") {
          this.loadingJapanMemo = true;
        }
        if (this.searchText) {
          let url = "";
          let url2 = ""; // 2022-08-13

          if (this.selectedCondition == "OR") {
            url2 = `${this.api}japanMemo/search_memo/or?search=${search}&except=${this.exceptText}`; // 2022-08-13

            let ishiragana = moji(search)
              .filter("HG")
              .toString();
            let iskatakana = moji(search)
              .filter("KK")
              .toString();
            let hiragana = "";
            let katakana = "";
            if (ishiragana) {
              hiragana = moji(this.searchText)
                .convert("HG", "KK")
                .toString();
              search = `${search}${this.title_spacer}${hiragana}`;
            } else if (iskatakana) {
              search = this.convert2FullKatakana(this.searchText);
              katakana = moji(search)
                .convert("KK", "HG")
                .toString();
              search = this.convert2FullKatakana(search);
              katakana = this.convert2FullHiragana(katakana);
              search = `${search}${this.title_spacer}${katakana}`;
            } else {
              search = this.convert2FullKatakana(this.searchText);
            }
            //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
            let numSmall = moji(search)
              .filter("HE")
              .toString();
            let numBig = moji(search)
              .filter("ZE")
              .toString();

            if (search.match(/[0-9]/g) || search.match(/[０-９]/g)) {
              if (numSmall) {
                numSmall = moji(search)
                  .convert("HE", "ZE")
                  .toString();
                search = `${search}${this.title_spacer}${numSmall}`;
              } else if (numBig) {
                numBig = search = `${search}${this.title_spacer}${moji(search)
                  .convert("ZE", "HE")
                  .toString()}`;
              }
            }
            //-----HALF AND FULL WIDTH  CHARACTERS

            let fullKatakana = moji(search)
              .filter("ZK")
              .toString();
            let halfKatakana = moji(search)
              .filter("HK")
              .toString();
            if (fullKatakana) {
              fullKatakana = moji(search)
                .convert("ZK", "HK")
                .toString();
              search = `${search}${this.title_spacer}${fullKatakana}`;
            } else if (halfKatakana) {
              halfKatakana = moji(search)
                .convert("HK", "ZK")
                .toString();
              search = `${search}${this.title_spacer}${halfKatakana}`;
            }
            url = `${this.api}japanMemo/search_memo/or?search=${search}&except=${this.exceptText}`;
          } else {
            // Search AND Function
            // IF TWO WORDS SEARCH
            if (search.includes(this.title_spacer) || search.includes(" ")) {
              url2 = `${this.api}japanMemo/search_memoLatest/and?search=${search}&except=${this.exceptText}`; // 2022-08-13

              let searchReplaceWords = [];
              if (search.includes(this.title_spacer)) {
                searchReplaceWords.push(search);
              } else {
                search.replace(" ", this.title_spacer);
                searchReplaceWords.push(search);
              }

              console.log("line835", searchReplaceWords);

              // Check if Space is japanese space or normal space
              let newSearchReplaceWords = searchReplaceWords[0].includes(
                this.title_spacer
              )
                ? searchReplaceWords.join().split(this.title_spacer)
                : searchReplaceWords.join().split(" ");

              // Convert Normal English to Japanese English
              let normalEngToJapEng = moji(newSearchReplaceWords[0])
                .convert("HE", "ZE")
                .toString();
              let japEngToNormalEng2 = moji(newSearchReplaceWords[0])
                .convert("ZE", "HE")
                .toString();

              // Convert Jap English to Normal English
              let japEngToNormalEng = moji(newSearchReplaceWords[1])
                .convert("ZE", "HE")
                .toString();
              let normalEngToJapEng2 = moji(newSearchReplaceWords[1])
                .convert("HE", "ZE")
                .toString();

              let toSearch4Words = [
                normalEngToJapEng,
                japEngToNormalEng,
                japEngToNormalEng2,
                normalEngToJapEng2,
              ];
              console.log(">", toSearch4Words);

              console.log("2Words", searchReplaceWords);
              console.log("2Words", searchReplaceWords.join());
              console.log("4Words", [toSearch4Words.join(this.title_spacer)]);
              console.log(
                "4Words",
                [toSearch4Words.join(this.title_spacer)].join()
              );

              search = [toSearch4Words.join(this.title_spacer)].join();

              // url = `${this.api}japanMemo/search_memo_New/and?search=${search}&except=${this.exceptText}`; // OLD
              url = `${this.api}japanMemo/search_memoLatest/and?search=${search}&except=${this.exceptText}`; // 2022-08-13
              console.log("line1005", url);
              // url = `${this.api}japanMemo/search_memo/and?search=${searchReplaceWords.join()}&except=${this.exceptText}`;
            } else {
              // IF ONE WORD
              if (search.match(/[０-９|ａ-ｚ|Ａ-Ｚ|A-Z|a-z]/g)) {
                // search = `${moji(search).convert("ZE", "HE").toString()}`;
                // 2022-05-20
                console.log("d2 :D");

                // Convert Normal English to Japanese English
                let normalEngToJapEng = moji(search)
                  .convert("HE", "ZE")
                  .toString();

                // Convert Jap English to Normal English
                let japEngToNormalEng = moji(search)
                  .convert("ZE", "HE")
                  .toString();

                console.log("d3 >>", normalEngToJapEng);
                console.log("d3 >>", japEngToNormalEng);

                search = `${normalEngToJapEng}${this.title_spacer}${japEngToNormalEng}`;

                console.log("d3 >>", search);

                url = `${this.api}japanMemo/search_memo_New/and?search=${search}&except=${this.exceptText}`;
                url2 = `${this.api}japanMemo/search_memoLatest/and?search=${search}&except=${this.exceptText}`; // 2022-08-13
                console.log("line1030", url);
              } else if (search.match(/平均GL/g)) {
                url = `${this.api}japanMemo/search_memo/and?search=平均ＧＬ&except=${this.exceptText}`;
                url2 = `${this.api}japanMemo/search_memoLatest/and?search=平均ＧＬ&except=${this.exceptText}`; // 2022-08-13
                console.log("line1034", url);
              } else {
                url2 = `${this.api}japanMemo/search_memoLatest/and?search=${search}&except=${this.exceptText}`; // 2022-08-13

                // let ishiragana = moji(search).filter("HG").toString();
                // let iskatakana = moji(search).filter("KK").toString();

                // let numSmall = moji(search).filter("HE").toString();
                // let numBig = moji(search).filter("ZE").toString();

                // console.log('>>>', ishiragana );
                // console.log('>>>', iskatakana );
                // console.log('>>>', this.convert2FullKatakana(this.searchText) );
                // console.log('>>>', numSmall );
                // console.log('>>>', numBig );

                // url = `${this.api}japanMemo/search_memo/and?search=${search}&except=${this.exceptText}`;

                // 2022-06-14
                let bigWordToNormalEng = moji(search)
                  .convert("ZE", "HE")
                  .toString();
                let normalEngToBigWord = moji(search)
                  .convert("HE", "ZE")
                  .toString();
                search = `${bigWordToNormalEng}${this.title_spacer}${normalEngToBigWord}`;
                url = `${this.api}japanMemo/search_memo_New/and?search=${search}&except=${this.exceptText}`;

                // console.log('line1060', url )
                //   console.log('line831 >> dito pumasok')
                //   let ishiragana = moji(search).filter("HG").toString();
                //   let iskatakana = moji(search).filter("KK").toString();
                //   let hiragana = "";
                //   let katakana = "";
                //   if (ishiragana) {
                //     hiragana = moji(this.searchText).convert("HG", "KK").toString();
                //     search = `${search}${this.title_spacer}${hiragana}`;
                //     console.log('1')
                //   } else if (iskatakana) {
                //     search = this.convert2FullKatakana(this.searchText);
                //     katakana = moji(search).convert("KK", "HG").toString();
                //     search = this.convert2FullKatakana(search);
                //     katakana = this.convert2FullHiragana(katakana);
                //     search = `${search}${this.title_spacer}${katakana}`;
                //     console.log('2')
                //   } else {
                //     search = this.convert2FullKatakana(this.searchText);
                //     console.log('3')
                //   }
                //   //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
                //   let numSmall = moji(search).filter("HE").toString();
                //   let numBig = moji(search).filter("ZE").toString();

                //   if (search.match(/[0-9]/g) || search.match(/[０-９]/g)) {
                //     if (numSmall) {
                //       numSmall = moji(search).convert("HE", "ZE").toString();
                //       search = `${search}${this.title_spacer}${numSmall}`;
                //        console.log('4')
                //     } else if (numBig) {
                //       numBig = search = `${search}${this.title_spacer}${moji(search)
                //         .convert("ZE", "HE")
                //         .toString()}`;
                //          console.log('5')
                //     }

                //   }
                //   //-----HALF AND FULL WIDTH  CHARACTERS

                //   let fullKatakana = moji(search).filter("ZK").toString();
                //   let halfKatakana = moji(search).filter("HK").toString();
                //   if (fullKatakana) {
                //     fullKatakana = moji(search).convert("ZK", "HK").toString();
                //     search = `${search}${this.title_spacer}${fullKatakana}`;
                //      console.log('6')
                //   } else if (halfKatakana) {
                //     halfKatakana = moji(search).convert("HK", "ZK").toString();
                //     search = `${search}${this.title_spacer}${halfKatakana}`;
                //      console.log('7')
                //   }

                //  url = `${this.api}japanMemo/search_memo/or?search=${search}&except=${this.exceptText}`;
                //  url = `${this.api}japanMemo/search_memo_New/and?search=${search}&except=${this.exceptText}`;
              }
            }
          }

          // console.log('line117', url )
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          axios.get(url).then((res) => {
            //  console.log('line1120', res.data )
            // this.memoData.sort(function (a, b) {
            //   return ("" + a.countViews).localeCompare(b.countViews);
            // });

            if (res.data) {
              axios.get(url2).then((res2) => {
                // 2022-05-30
                let regexText = new RegExp(
                  search
                    .split(this.title_spacer)
                    .join("|")
                    .toString(),
                  "g"
                );
                // console.log('Regex984',regexText)

                let uniqRes3Data = _.uniqBy(
                  [...res.data, ...res2.data].map((r) => {
                    r.updatedDate = r.memoUpdatedDate;
                    r.documentType = "JAPANMEMO";
                    return r;
                  }),
                  "id"
                );

                // let mapTextContentData = res.data.map(rec=>{ // OLD
                let mapTextContentData = uniqRes3Data.map((rec) => {
                  // 2022-08-13
                  rec.textContent = rec.textContent.replace(regexText, function(
                    str
                  ) {
                    return `<span style="background-color:yellow"><b>${str}</b></span>`;
                  });
                  return rec;
                });

                //2022-05-20
                this.memoData = _.uniqBy(
                  _.orderBy(
                    mapTextContentData,
                    ["docNo", "memoUpdatedDate"],
                    ["desc", "desc"]
                  ),
                  "id"
                );
                // this.memoData = _.uniqBy(  _.orderBy( res.data, ['memoUpdatedDate'], ['desc']) , 'docNo')

                // console.log('this.memoData', this.memoData )
                this.searchDialog = false;
                this.loadingJapanMemo = false;
                // let a = this.loadYear[0]
                // let b = this.loadYear[1]
                // console.log(this.memoData , 'aaaaaaaaaaaaaaaa')
                let dateString = [this.loadYear[0], this.loadYear[1]];

                // console.log(a,b)
                if (this.isAllSearch == false) {
                  // 2022-05-04
                  this.onChangeYear("a", dateString, "from Search");
                }
              });
            }
          });
        } else {
          this.memoData = [];
        }
      }
    },

    promiseGetMultipleSearchWords(searchKeyWord = "", exceptWord = "") {
      // 2023-03-15
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        const url = `${this.api}japanMemo/search/multiple/?search1=${searchKeyWord}&exceptWord=${exceptWord}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    async onMultipleSearch() {
      // 2023-03-15
      try {
        this.pageNumber = 1;
        this.searchMemoIconAlignment = true;
        this.isMultiple = true;
        this.loadingJapanMemo = true;

        if (this.searchText) {
          let japanMemoRecords = await this.promiseGetMultipleSearchWords(
            this.searchText,
            this.exceptText
          );
          this.memoData = japanMemoRecords.sort((a, b) => {
            return a.memoUpdatedDate > b.memoUpdatedDate ? -1 : 1;
          }).map(r=>{
            this.$set(r,'showLine',false)
            return r
          });
          this.isMultiple = false;
          this.loadingJapanMemo = false;
        } else {
          alert("Please provide search word..");
          this.memoData = [];
          this.loadingJapanMemo = false;
        }
      } catch (err) {
        console.log(err.message);
        alert(
          "Error fetching data from multiple condition, Please try again.."
        );
        this.memoData = [];
        this.loadingJapanMemo = false;
      }
    },
    onMultipleSearch1() {
      // backup old 2023-03-15
      this.pageNumber = 1;
      if (!this.composing) {
        this.searchMemoIconAlignment = true;
        this.isMultiple = true;
        let search = this.convert2FullKatakana(this.searchText).trim();
        // this.searchLists = [];
        if (this.searchText != "") {
          this.loadingJapanMemo = true;
        }
        if (this.searchText) {
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          let url = "";
          url = `${this.api}japanMemo/search_memo_New/multi?search=${search}&except=${this.exceptText}&dateFrom=${this.loadYear[0]}&dateTo=${this.loadYear[1]}`;
          axios
            .get(url)
            .then((res) => {
              if (res.data) {
                // 2022-05-30
                console.log(res.data);
                let regexText = new RegExp(
                  res.data.searchArr.join("|").toString(),
                  "g"
                );
                console.log("line1032", regexText);

                let mapTextContentData = res.data.responseData.map((rec) => {
                  rec.textContent = rec.textContent.replace(regexText, function(
                    str
                  ) {
                    return `<span style="background-color:yellow"><b>${str}</b></span>`;
                  });
                  return rec;
                });

                this.memoData = _.orderBy(
                  mapTextContentData,
                  ["countViews", "memoUpdatedDate"],
                  ["asc", "desc"]
                ).map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });
                this.loadingJapanMemo = false;
                this.isMultiple = false;
              }

              // if (typeof res.data != "string") {
              //   this.memoData = res.data;
              //   this.memoData.sort(function (a, b) {
              //     return ("" + a.countViews).localeCompare(b.countViews);
              //   });
              //   this.loadingJapanMemo = false;
              // } else {
              //   this.loadingJapanMemo = true;
              //   this.selectedCondition = "OR";
              //   this.onSearch();
              //   this.isMultiple = false;
              // }
            })
            .catch((err) => {
              alert(
                `Keyword ${this.searchText} is not yet registered, Please confirm to administrator`
              );
              this.memoData = [];
              this.loadingJapanMemo = false;
              console.log(err.message);
            });
        } else {
          this.memoData = [];
        }
      }
    },
    clearSearch() {
      this.isMultiple = false;
      this.searchMemoIconAlignment = false;
      this.isFavorite = false;
      this.loadingJanMeo = true;
      this.sortByCategoryASC = false;
      this.sortByCategoryDES = false;
      this.sortByDateASC = false;
      this.sortByDateDES = false;
      this.sortByDocNoASC = false;
      this.sortByDocNoDES = false;
      this.sortByFavoriteASC = false;
      this.sortByFavoriteDES = false;
      this.loadMemoByYear();
      this.getUserFavorites();
      this.searchText = "";
      this.exceptText = "";

      this.isAllSearch = false; // 2022-05-04
    },
    getUserFavorites() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.loadingJapanMemo = true;
      let toGet = {
        userId: this.userInfo.id,
      };
      let url = `${this.api}getFavorites/memo`;
      axios.post(url, toGet).then((res) => {
        this.favoriteList = res.data;
      });
    },
    async filterFavorites() {
      await this.getUserFavorites();
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.memoData = [];
      if (this.isFavorite) {
        if (this.favoriteList.length > 0) {
          for (let i = 0; i < this.favoriteList.length; i++) {
            let url2 = `${this.api}getDetailsFavorite/memo?id=${this.favoriteList[i].id}&docNo=${this.favoriteList[i].docNo}`;
            await axios.get(url2).then((res) => {
              this.memoData.push(res.data);
              this.loadingJapanMemo = false;
            });
          }
        } else {
          this.loadingJapanMemo = false;
        }
      } else {
        this.clearSearch();
      }
    },

    loadMemoByYear() {
      // if(this.selectedYear != 'All'){
      this.searchMemoIconAlignment = false;
      this.isMultiple = true;
      this.searchText = "";
      this.exceptText = "";
      this.loadingJapanMemo = true;
      let dateFrom = moment()
        .subtract(1, "y")
        .format("YYYY-MM-DD");
      let dateTo = moment().format("YYYY-MM-DD");
      this.loadYear = [dateFrom, dateTo];

      let url = `${this.api}japanMemo/getByYear?from=${dateFrom}&to=${dateTo}`;
      axios.get(url).then((r) => {
        // this.memoData = r.data
        // .sort((a,b) => {
        //   return b.memoUpdatedDate.localeCompare(a.memoUpdatedDate)
        // })
        // .sort((a,b) => {
        //   return b.docNo.localeCompare(a.docNo)
        // })

        // 2022-05-04
        // this.memoData =  _.orderBy(r.data, ['memoUpdatedDate','docNo'], ['desc','desc'])

        // 2023-04-19
        let notIncludesCategory = ["設計課通達", "設計課・工事課通達"];
        if (this.isSalesUser()) {
          this.memoData = _.orderBy(
            _.filter(r.data, function(o) {
              return !notIncludesCategory.includes(o.category);
            }),
            ["memoUpdatedDate", "docNo"],
            ["desc", "desc"]
          );
        } else {
          this.memoData = _.orderBy(
            r.data,
            ["memoUpdatedDate", "docNo"],
            ["desc", "desc"]
          );
        }

        this.loadingJapanMemo = false;
      });
      //////
      // let url = `${this.api}japanMemo/loadByYear?year=${this.selectedYear}`;
      // axios.defaults.headers.common["x-api-key"] =
      //   "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      //  axios.get(url).then((res) => {
      //   this.memoData = res.data
      //   this.memoData
      //   .sort((a,b) => {
      //     return moment(b.memoUpdatedDate) - moment(a.memoUpdatedDate) || b.docNo.localeCompare(a.docNo);
      //   })
      //   this.loadingJapanMemo = false;
      // });
      // }
      // else{
      //   this.loadAllMemo()
      // }
    },
    loadAllMemo() {
      if (this.selectedYear == "All") {
        this.loadingJapanMemo = true;
        let url = `${this.api}japanMemo/load_japan_memo`;
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        axios.get(url).then((res) => {
          this.memoData = res.data;
          this.memoData.sort((a, b) => {
            return (
              moment(b.memoUpdatedDate) - moment(a.memoUpdatedDate) ||
              b.docNo.localeCompare(a.docNo)
            );
          });
          this.loadingJapanMemo = false;
        });
      }
    },
    isFavIcon(data) {
      let isFavMemo = false;
      this.favoriteList.map((r) => {
        if (r.id == data.id && r.category == data.category) {
          isFavMemo = true;
        }
      });
      if (isFavMemo) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
  },
  computed: {
    isFav() {
      if (this.isFavorite) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    Width() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:60%";
      } else return "width:98%";
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    filteredMemo() {
      let data = [];
      if (this.selectedCategory == "ALL") {
        // data = this.memoData;
        data = _.orderBy(
          this.memoData,
          ["memoUpdatedDate", "docNo"],
          ["desc", "desc"]
        );
      } else {
        data = this.memoData.filter((r) => {
          if (
            this.selectedCategory.includes(r.category) &&
            !this.selectedCategory.includes("ALL")
          ) {
            return r;
          } else if (this.selectedCategory.includes("ALL")) {
            return r;
          }
        });
        data = _.orderBy(
          data,
          ["memoUpdatedDate", "docNo"],
          ["desc", "desc"]
        );
        console.log(data);
      }

      // if (this.sortByCategoryASC) {
      //   data.sort((a, b) => (a.category > b.category ? 1 : -1));
      // }
      // if (this.sortByCategoryDES) {
      //   data.sort((a, b) => (a.category < b.category ? 1 : -1));
      // }
      // if (this.sortByDateASC) {
      //   data.sort((a, b) => {
      //     let key1 = a.memoUpdatedDate;
      //     let key2 = b.memoUpdatedDate;
      //     if (key1 < key2) {
      //       return -1;
      //     } else if (key1 == key2) {
      //       return 0;
      //     } else {
      //       return 1;
      //     }
      //   });
      // }
      // if (this.sortByDateDES) {
      //   data.sort(function (a, b) {
      //     if (a.memoUpdatedDate > b.memoUpdatedDate) return -1;
      //     if (a.memoUpdatedDate < b.memoUpdatedDate) return 1;
      //     return 0;
      //   });
      // }
      return data;
    },
    paginationLength() {
      let l = this.filteredMemo.length,
        s = this.intRowNoMemo;
      return Math.ceil(l / s);
    },
    paginatedMemo() {
      // const start = (this.pageNumber - 1) * this.intRowNoMemo,
      //   end = start + this.intRowNoMemo;
      // return this.filteredMemo.slice(start, end);
      return this.filteredMemo;
    },
    customClearIcon() {
      return "mdi-close-circle-outline";
    },
  },
};
</script>
<style scoped>
#title_ellipsis {
  display: inline-block;
  width: 93%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button {
  /* background-color: #01579b; */
  border: none;
  cursor: pointer;
  /* color: white; */
  color: black;
  padding: 6px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.title {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px !important;
}
.viewed {
  background-color: white;
  color: purple;
}
</style>
