<template>
  <v-container pa-0 fluid>
    <!-- NOTE: disable transation in this page -->
    <div translate="no">
    <v-layout align-center justify-center v-if="loading2">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <v-card class="ma-2 pa-2" flat style="background-color: #f6f5f5" v-else>
      <!-- <v-btn @click="andMultiSearch()">showData</v-btn> -->
      <v-row dense>
        <!-- <v-col cols="12" sm="8" md="2"  v-if="selectedCondition == 'ID & TITLE'" > -- change to 帳票番号＆タイトル 20230704---->
        <!-- <v-col
          cols="12"
          sm="8"
          md="2"
          v-if="selectedCondition == '帳票番号＆タイトル'"
        >
          <a-input
            addon-before="ID"
            v-model="searchDocNo"
            placeholder="Reference No."
            append-icon="search"
            outlined
          >
            <a-icon
              v-if="searchDocNo"
              slot="suffix"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
              @click="searchDocNo ? clearSearch() : ''"
            />
          </a-input>
        </v-col> -->

        <!-- 2023-05-30 -->
        <!-- <v-col cols="12" sm="8" md="2"  v-if="selectedCondition == 'ID & TITLE'" > -- change to 帳票番号＆タイトル 20230704---->
        <!-- <v-col
          cols="12"
          sm="8"
          md="2"
          v-if="selectedCondition == '帳票番号＆タイトル'"
        >
          <a-input
            addon-before="Title"
            v-model="searchTitle"
            placeholder="Document Title"
            append-icon="search"
            outlined
          >
            <a-icon
              v-if="searchTitle"
              slot="suffix"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
              @click="searchTitle ? clearSearch() : ''"
            />
          </a-input>
        </v-col> -->

        <!---<v-col cols="12" sm="6" md="4" v-if="selectedCondition != 'ID & TITLE'" > -- change to 帳票番号＆タイトル 20230704-->
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-if="selectedCondition != '帳票番号＆タイトル'"
        >
          <!--20230704 change to 検索--->
          <!------ Removed a-input component replaced with v-text-field 20231104----->
          <!-- <a-input
            addon-before="検索"
            @keyup.enter="
              selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()
            "
            v-model="searchText"
            placeholder="検索"
            append-icon="search"
            outlined
            @compositionstart="StartCompose()"
            @compositionend="EndCompose()"
          >
            <a-icon
              slot="suffix"
              @click="searchText ? clearSearch() : ''"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-input> -->
          <v-row dense no-gutters>
            <!-- <p
              class="search-label mb-0"
              style="font-size:15px; color:#000000a6"
            >
              検索
            </p>
            <v-text-field
              solo
              height="31px"
              flat
              clearable
              hide-details
              @click:clear="clearSearch()"
              class="custom-text-field"
              :clear-icon="customClearIcon"
              v-model="searchText"
              @keyup.enter="
                selectedCondition != 'MULTI' ? onSearch() : onMultipleSearch()
              "
            ></v-text-field> -->
            <!-- NOTE: added full text search with "AND" condition and "MULTI" 2024-06-15 -->
            <v-text-field
              style="zoom:80%; background-color:white;"
              outlined
              dense
              hide-details
              label="全文検索"
              clearable
              @keyup.enter="selectedCondition=='MULTI',andMultiSearch()"
              @input="searchDocNo=''"
              @click:clear="clearSearch()"
              :clear-icon="customClearIcon"
              v-model="searchText"
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col cols="4" :sm="2" :md="1" v-if="!viewHistoryTable">
          <!-- NOTE: added document number search 2024-06-15 -->
          <v-text-field
            style="zoom:80%; background-color:white;"
            outlined
            dense
            hide-details
            label="番号検索"
            clearable
            @keyup.enter="selectedCondition='帳票番号',onSearch()"
            @input="searchText=''"
            @click:clear="clearSearch()"
            :clear-icon="customClearIcon"
            v-model="searchDocNo"
          ></v-text-field>
          <!-- <a-select v-model="selectedCondition" style="width: 100%">
            <a-select-option
              v-for="item in condition"
              :key="item"
              v-value="item"
              >{{ item }}</a-select-option
            >
          </a-select> -->
        </v-col>

        <!-- NOTE: eliminate マイナス検索 2024-04-18 start -->
        <!-- <v-col cols="12" sm="6" md="3" v-if="!viewHistoryTable">
          <a-input
            addon-before="マイナス検索"
            v-model="exceptText"
            placeholder="マイナス検索"
          >
            <a-icon
              slot="suffix"
              @click="exceptText ? clearSearch() : ''"
              type="close-circle"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-input>
        </v-col> -->
        <!-- NOTE: eliminate マイナス検索 2024-04-18 end -->

        <v-col cols="2">
          <a-button
            v-if="!viewHistoryTable"
            class="mx-1"
            dark
            type="primary"
            icon="search"
            @click="!searchText ? onSearch() : andMultiSearch()"
            :disabled="!searchText && !searchDocNo && !searchTitle"
          >
            Search
          </a-button>
          <!-- NOTE: previous function allSearch() 2024-07-01 -->
          <!-- <a-button @click="searchText ? clearSearch() : ''"  :disabled="!searchText" dense type='primary'>Reset</a-button> -->
        </v-col>

        <!-- <v-col  v-if="!isFilterOn"> -->
        <!-- <a-checkbox :checked="isSearchAll" @change="onChangeCheck">
              Search All
            </a-checkbox> -->
        <v-spacer v-if="!isFilterOn" />
        <!-- <pre> {{ filteredSpecs}} </pre> -->
        <a-button
          v-if="!isFilterOn"
          icon="filter"
          @click="hideFilter = !hideFilter"
          >{{ hideFilter ? "View Filter" : "Hide Filter" }}
        </a-button>

        <!-- </v-col> -->

        <v-col>
          <template v-if="isFilterOn">
            <a-popover
              title="Filter Options"
              :placement="placePop"
              v-model="isvisibleFilter"
              trigger="click"
            >
              <template slot="content">
                <v-card style="width: 500px" flat>
                  <v-layout row wrap>
                    &emsp;
                    <template v-if="!viewHistoryTable">
                      <span style="font-weight: bold; font-size: 14px"
                        >製品区分:</span
                      >
                      <a-select
                        class="mt-2"
                        v-model="selectedproductType"
                        style="width: 72%; margin-left: 10px"
                      >
                        <a-select-option
                          v-for="item in productTypeList"
                          :key="item"
                          v-value="item"
                          >{{ item }}</a-select-option
                        >
                      </a-select>
                    </template>

                    <a-row>
                      <a-col>
                        <!-- <a-icon
                              @click="sortByProductType(sortByProductTypeASC), sortByProductTypeASC = !sortByProductTypeASC"
                              v-if="sortByProductTypeASC == true"
                              type="caret-up"
                            />
                            <a-icon
                              v-else
                              @click="sortByProductType(sortByProductTypeASC), sortByProductTypeASC = !sortByProductTypeASC"
                              type="caret-down"
                            /> -->
                        <a-icon
                          @click="
                            sortByProductTypeASC = true;
                            sortByProductTypeDES = false;
                          "
                          type="caret-up"
                        />
                      </a-col>
                      <a-col>
                        <a-icon
                          data-v-step="5"
                          @click="
                            sortByProductTypeDES = true;
                            sortByProductTypeASC = false;
                          "
                          type="caret-down"
                        />
                      </a-col>
                    </a-row>
                  </v-layout>
                  <v-layout row wrap>
                    <span
                      class="mt-2"
                      style="font-weight: bold; font-size: 14px"
                      >構法: {{ title_spacer }}</span
                    >
                    <a-select
                      class="mt-1"
                      v-model="selectedMethod"
                      style="width: 72%; margin-left: 20px"
                    >
                      <a-select-option
                        v-for="item in methodList"
                        :key="item.value"
                        v-value="item.value"
                        >{{ item.text }}</a-select-option
                      >
                    </a-select>
                    <a-row>
                      <a-col>
                        <a-icon
                          @click="
                            sortByMethodASC = true;
                            sortByMethodDES = false;
                          "
                          type="caret-up"
                        />
                      </a-col>
                      <a-col>
                        <a-icon
                          type="caret-down"
                          @click="
                            sortByMethodASC = false;
                            sortByMethodDES = true;
                          "
                        />
                      </a-col>
                    </a-row>
                  </v-layout>

                  <v-layout row wrap>
                    <span
                      class="mt-2"
                      style="font-weight: bold; font-size: 14px"
                      >区分:</span
                    >
                    <a-select
                      class="mt-1"
                      v-model="selectedCriteria"
                      style="width: 72%; margin-left: 40px"
                    >
                      <a-select-option
                        v-for="item in criteriaList"
                        :key="item"
                        v-value="item"
                        >{{ item }}</a-select-option
                      >
                    </a-select>

                    <a-row>
                      <a-col>
                        <a-icon
                          type="caret-up"
                          @click="
                            sortByCriteriaASC = true;
                            sortByCriteriaDES = false;
                          "
                        />
                      </a-col>
                      <a-col>
                        <a-icon
                          type="caret-down"
                          @click="
                            sortByCriteriaASC = false;
                            sortByCriteriaDES = true;
                          "
                        />
                      </a-col>
                    </a-row>
                  </v-layout>
                  <v-layout row wrap>
                    <span
                      class="pt-2"
                      style="font-weight: bold; font-size: 14px"
                      >更新日:</span
                    >

                    <a-range-picker
                      class="mt-1"
                      @change="onChangeDate"
                      style="width: 72%; margin-left: 25px"
                    />
                    <a-row>
                      <a-col>
                        <a-icon
                          type="caret-up"
                          @click="
                            sortByDateASC = true;
                            sortByDateDES = false;
                          "
                        />
                      </a-col>
                      <a-col>
                        <a-icon
                          type="caret-down"
                          @click="
                            sortByDateASC = false;
                            sortByDateDES = true;
                          "
                        />
                      </a-col>
                    </a-row>
                  </v-layout>
                  <v-layout row wrap>
                    <span
                      class="pt-2"
                      style="font-weight: bold; font-size: 14px"
                      >建物タイプ:</span
                    >&nbsp;
                    <a-select
                      class="mt-1"
                      mode="multiple"
                      v-model="selectedBuildingType"
                      style="width: 72%"
                    >
                      <a-select-option
                        v-for="item in buildingTypeList"
                        :key="item.value"
                        v-value="item.value"
                        >{{ item.text }}</a-select-option
                      >
                    </a-select>
                  </v-layout>

                  <v-layout row wrap>
                    <span
                      class="pt-2"
                      style="font-weight: bold; font-size: 14px"
                      >建物仕様:</span
                    >
                    <a-select
                      mode="multiple"
                      v-model="selectedBuildingSpecification"
                      style="
                            width: 72%;
                            margin-top: 10px;
                            margin-left: 15px;
                          "
                      class="mt-2"
                    >
                      <a-select-option
                        v-for="item in buildingSpecificationList"
                        :key="item.value"
                        v-value="item.value"
                        >{{ item.text }}</a-select-option
                      >
                    </a-select>
                  </v-layout>
                  <v-layout row wrap>
                    <v-icon color="black"> mdi-calendar-star </v-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="mt-1"
                          id="favStyle"
                          @click="(isFavorite = !isFavorite), filterFavorites()"
                          type="primary"
                          style="
                                width: 72%;
                                margin-left: 40px;
                                margin-top: 10px;
                              "
                        >
                          <v-icon
                            small
                            :style="
                              isFavorite ? 'color:#ffa62b' : 'color:#214252'
                            "
                            class="mr-1"
                            >{{ isFav }}</v-icon
                          >
                          お気に入り
                        </v-btn>
                        <a-row>
                          <a-col>
                            <a-icon
                              type="caret-up"
                              @click="
                                sortByFavoriteASC = true;
                                sortByFavoriteDES = false;
                              "
                            />
                          </a-col>
                          <a-col>
                            <a-icon
                              type="caret-down"
                              @click="
                                sortByFavoriteASC = false;
                                sortByFavoriteDES = true;
                              "
                            />
                          </a-col>
                        </a-row>
                      </template>
                      <span>Filter Favorites</span>
                    </v-tooltip>
                  </v-layout>
                </v-card>
              </template>
              <v-icon class="ml-1 mt-2" @click="openFilter()"
                >mdi-filter-menu-outline</v-icon
              >
            </a-popover>
          </template>
        </v-col>
      </v-row>

      <!-- //FILTER    -->
      <a-row
        :xs="24"
        :sm="12"
        :md="10"
        :gutter="[0, 3]"
        v-if="!isFilterOn && !hideFilter"
      >
        <a-col :xs="24" :sm="14" :md="7">
          <v-layout row wrap>
            <template v-if="!viewHistoryTable">
              &emsp;

              <v-tooltip bottom v-if="!sortByProductTypeASC">
                <template v-slot:activator="{ on }">
                  <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-if="!sortByProductTypeASC"
                    v-on="on"
                    @click="
                      sortByProductType(sortByProductTypeASC),
                        (sortByProductTypeASC = !sortByProductTypeASC)
                    "
                  >
                    🔽 製品区分:
                  </span>

                  <!-- <span
                      class="mt-2 button"
                      style="font-weight: bold; font-size: 14px"
                      v-if="!sortByProductTypeASC"
                      @click="
                        sortByProductTypeASC = true;
                        sortByProductTypeDES = false;
                      "
                    >
                      🔽 製品区分: 
                    </span> -->
                </template>
                <span style="font-family:Arial"> 🔽 - 昇順 </span>
              </v-tooltip>

              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-on="on"
                    @click="
                      sortByProductType(sortByProductTypeASC),
                        (sortByProductTypeASC = !sortByProductTypeASC)
                    "
                    >🔼 製品区分:
                  </span>
                </template>
                <span style="font-family:Arial"> 🔼 - 降順 </span>
              </v-tooltip>

              <!-- <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-else
                    @click="
                      sortByProductTypeDES = true;
                      sortByProductTypeASC = false;
                    "
                  >🔼 製品区分:
                 </span > -->

              <a-select
                class="mt-2"
                v-model="selectedproductType"
                :style="Width"
              >
                <a-select-option
                  v-for="item in productTypeList"
                  :key="item"
                  v-value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </template>

            <template v-if="viewHistoryTable">
              &emsp;
              <span
                class="mt-2 button"
                style="font-weight: bold; font-size: 14px"
              >
                製品区分:
              </span>

              <a-select class="mt-2" v-model="selectedProdCat" :style="Width">
                <a-select-option
                  v-for="item in histProdCatList"
                  :key="item"
                  v-value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </template>
          </v-layout>
        </a-col>
        <a-col :xs="24" :sm="7" :md="7">
          <v-layout row wrap>
            <template v-if="!viewHistoryTable">
              <v-tooltip bottom v-if="sortByMethodASC == false">
                <template v-slot:activator="{ on }">
                  <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-if="sortByMethodASC == false"
                    v-on="on"
                    @click="
                      sortByMethod(sortByMethodASC),
                        (sortByMethodASC = !sortByMethodASC)
                    "
                  >
                    🔽 構法:
                  </span>

                  <!-- <span
                          class="mt-2 button"
                          style="font-weight: bold; font-size: 14px"
                          v-if="sortByMethodASC == false"
                          
                          @click="
                            sortByMethodASC = true;
                            sortByMethodDES = false;
                          "
                        >
                          🔽 構法:</span
                        > -->
                </template>
                <span style="font-family:Arial"> 🔽 - 昇順 </span>
              </v-tooltip>

              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-on="on"
                    @click="
                      sortByMethod(sortByMethodASC),
                        (sortByMethodASC = !sortByMethodASC)
                    "
                  >
                    🔼 構法:
                  </span>
                </template>
                <span style="font-family:Arial"> 🔼 - 降順 </span>
              </v-tooltip>

              <!-- <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-else
                    @click="
                      sortByMethodDES = true;
                      sortByMethodASC = false;
                    "
                  >
                    🔼 構法:
                  </span> -->
              <a-select class="mt-2" v-model="selectedMethod" :style="Width">
                <a-select-option
                  v-for="item in methodList"
                  :key="item.value"
                  v-value="item.value"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </template>

            <template v-if="viewHistoryTable">
              <span
                class="mt-2 button"
                style="font-weight: bold; font-size: 14px"
              >
                構法:</span
              >

              <a-select
                class="mt-2"
                v-model="selectedHistFrameWork"
                :style="Width"
              >
                <a-select-option
                  v-for="item in histFrameWork"
                  :key="item.value"
                  v-value="item.value"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </template>
          </v-layout>
        </a-col>
        <a-col :xs="24" :sm="15" :md="5">
          <v-layout row wrap>
            <template v-if="!viewHistoryTable">
              <v-tooltip bottom v-if="sortByCriteriaASC == false">
                <template v-slot:activator="{ on }">
                  <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-if="sortByCriteriaASC == false"
                    v-on="on"
                    @click="
                      sortByCriteria(sortByCriteriaASC),
                        (sortByCriteriaASC = !sortByCriteriaASC)
                    "
                  >
                    🔽 区分:</span
                  >

                  <!-- <span
                          class="mt-2 button"
                          style="font-weight: bold; font-size: 14px"
                          v-if="sortByCriteriaASC == false"
                          @click="
                            sortByCriteriaASC = true;
                            sortByCriteriaDES = false;
                          "
                        >
                          🔽 区分:</span
                        > -->
                </template>
                <span style="font-family:Arial"> 🔽 - 昇順 </span>
              </v-tooltip>

              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-on="on"
                    @click="
                      sortByCriteria(sortByCriteriaASC),
                        (sortByCriteriaASC = !sortByCriteriaASC)
                    "
                  >
                    🔼 区分:
                  </span>
                </template>
                <span style="font-family:Arial"> 🔼 - 降順 </span>
              </v-tooltip>
              <!-- <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-else
                    @click="
                      sortByCriteriaDES = true;
                      sortByCriteriaASC = false;
                    "
                  >
                    🔼 区分:</span
                  > -->
              <a-select
                class="mt-2"
                v-model="selectedCriteria"
                :style="StyleWidth"
              >
                <a-select-option
                  v-for="item in criteriaList"
                  :key="item"
                  v-value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </template>

            <template v-if="viewHistoryTable">
              <span
                class="mt-2 button"
                style="font-weight: bold; font-size: 14px"
                >区分:</span
              >
              <a-select
                class="mt-2"
                v-model="selectedHistClassification"
                :style="StyleWidth"
              >
                <a-select-option
                  v-for="item in histClassification"
                  :key="item"
                  v-value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </template>
          </v-layout>
        </a-col>

        <!-- 2022-09-13 -->
        <a-col :xs="24" :sm="7" :md="5">
          <template v-if="viewHistoryTable">
            <span class="mt-2 button" style="font-weight: bold; font-size: 14px"
              >更新区分:</span
            >
            <a-select
              class="mt-2"
              v-model="selectedHistUpCat"
              :style="StyleWidth"
            >
              <a-select-option
                v-for="item in histUpCatList"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </template>
        </a-col>

        <a-col :xs="24" :sm="7" :md="5">
          <v-layout row wrap>
            <template v-if="!viewHistoryTable">
              <v-tooltip bottom v-if="sortByFavoriteASC == false">
                <template v-slot:activator="{ on }">
                  <span
                    class="button mt-1"
                    v-if="sortByFavoriteASC == false"
                    v-on="on"
                    @click="
                      sortByFavorites(sortByFavoriteASC),
                        (sortByFavoriteASC = !sortByFavoriteASC)
                    "
                  >
                    🔽

                    <!-- <span
                        class="button mt-1"
                        v-if="sortByFavoriteASC == false"
                        @click="
                          sortByFavoriteASC = true;
                          sortByFavoriteDES = false;
                        "
                      >
                        🔽 -->
                  </span>
                </template>
                <span style="font-family:Arial"> 🔽 - 昇順 </span>
              </v-tooltip>

              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <span
                    class="button mt-1"
                    v-on="on"
                    @click="
                      sortByFavorites(sortByFavoriteASC),
                        (sortByFavoriteASC = !sortByFavoriteASC)
                    "
                  >
                    🔼
                  </span>
                </template>
                <span style="font-family:Arial"> 🔼 - 降順 </span>
              </v-tooltip>
              <!-- <span
                    class="button mt-1"
                    v-else
                    @click="
                      sortByFavoriteDES = true;
                      sortByFavoriteASC = false;
                    "
                  >
                    🔼
                  </span> -->
              <v-icon
                small
                :style="isFavorite ? 'color:#ffa62b' : 'color:#214252'"
                class="mr-1"
                >{{ isFav }}</v-icon
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="mt-2"
                    id="favStyle"
                    @click="
                      isFavorite = !isFavorite;
                      filterFavorites();
                    "
                    type="primary"
                    :style="Width"
                  >
                    お気に入り
                  </v-btn>
                </template>
                <span>Filter Favorites</span>
              </v-tooltip>
            </template>
          </v-layout>
        </a-col>
      </a-row>

      <a-row
        :xs="24"
        :sm="12"
        :md="10"
        :gutter="[0, 3]"
        v-if="!isFilterOn && !hideFilter"
      >
        <a-col :xs="24" :sm="14" :md="7">
          <v-layout row wrap>
            <template v-if="!viewHistoryTable">
              <span
                class="mt-2 border"
                style="font-weight: bold; font-size: 14px"
                >建物タイプ :</span
              >
              <a-select
                class="mt-2"
                mode="multiple"
                v-model="selectedBuildingType"
                :style="HTypeWidth"
              >
                <a-select-option
                  v-for="item in buildingTypeList"
                  :key="item.value"
                  v-value="item.value"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </template>

            <!-- 2022-09-13 -->
            <template v-if="viewHistoryTable">
              <span
                class="mt-1 border"
                style="font-weight: bold; font-size: 14px"
                >担当者:</span
              >
              <a-select
                class="mt-1"
                v-model="selectedHistIncharge"
                :style="StyleWidth"
              >
                <a-select-option
                  v-for="item in histInchargeList"
                  :key="item"
                  v-value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </template>

            <!-- <template v-if="viewHistoryTable">
                  <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                  >更新区分:</span>
                  <a-select
                    class="mt-2"
                    v-model="selectedHistUpCat"
                    :style="StyleWidth"
                  >
                    <a-select-option
                      v-for="item in histUpCatList"
                      :key="item"
                      v-value="item"
                      >{{ item }}</a-select-option>
                    </a-select>

                </template> -->
          </v-layout>
        </a-col>

        <a-col :xs="24" :sm="15" :md="7">
          <v-layout row wrap>
            <template v-if="!viewHistoryTable">
              <span
                class="mt-2 border"
                style="font-weight: bold; font-size: 14px"
                >建物仕様:</span
              >
              <a-select
                mode="multiple"
                v-model="selectedBuildingSpecification"
                :style="CustomWidth"
                class="mt-2"
              >
                <a-select-option
                  v-for="item in buildingSpecificationList"
                  :key="item.value"
                  v-value="item.value"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </template>

            <!-- 2022-09-13 Year -->
            <!-- <template v-if="viewHistoryTable">  removed 20230925
              <span
                class="mt-1 border"
                style="font-weight: bold; font-size: 14px"
              >
                Year :</span
              >
              <a-select
                @change="GetKoushinList(selectedYear)"
                class="mt-1"
                v-model="selectedYear"
                :style="StyleWidth"
              >
                <a-select-option
                  v-for="item in histRulebookYear"
                  :key="item"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </template> -->
            <!-- <template v-if="viewHistoryTable">
                    <span
                      class="mt-2 border"
                      style="font-weight: bold; font-size: 14px"
                      >担当者:</span
                    >
                    <a-select
                      class="mt-2"
                      v-model="selectedHistIncharge"
                      :style="StyleWidth"
                    >
                      <a-select-option
                        v-for="item in histInchargeList"
                        :key="item"
                        v-value="item"
                        >{{ item }}</a-select-option>
                      </a-select>
                </template> -->
          </v-layout>
        </a-col>
        <a-col :xs="24" :sm="7" :md="5" v-if="!viewHistoryTable">
          <v-layout row wrap>
            <v-tooltip bottom v-if="sortByDateASC == false">
              <template v-slot:activator="{ on }">
                <span
                  class="mt-2 button"
                  style="font-weight: bold; font-size: 14px"
                  v-if="sortByDateASC == false"
                  v-on="on"
                  @click="
                    sortByUpdatedDate(sortByDateASC),
                      (sortByDateASC = !sortByDateASC)
                  "
                >
                  🔽 更新日:
                </span>
              </template>
              <span style="font-family:Arial"> 🔽 - 昇順 </span>
            </v-tooltip>
            <!-- <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-if="!sortByDateASC"
                    @click="
                      sortByDateASC = true;
                      sortByDateDES = false;
                    "
                  >
                    🔽 更新日:
                  </span > -->
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span
                  class="mt-2 button"
                  style="font-weight: bold; font-size: 14px"
                  v-on="on"
                  @click="
                    sortByUpdatedDate(sortByDateASC),
                      (sortByDateASC = !sortByDateASC)
                  "
                >
                  🔼 更新日:
                </span>
              </template>
              <span style="font-family:Arial"> 🔼 - 降順 </span>
            </v-tooltip>

            <!-- <span
                    class="mt-2 button"
                    style="font-weight: bold; font-size: 14px"
                    v-else
                    @click="
                      sortByDateASC = false;
                      sortByDateDES = true;
                    "
                  >
                    🔼 更新日:</span
                  > -->

            &emsp;
          </v-layout>
        </a-col>

        <a-col :xs="24" :sm="7" :md="5">
          <!-- 2022-09-13 Koushin -->
          <!-- <template v-if="viewHistoryTable">    removed 20230925
            <span class="mt-1 border" style="font-weight: bold; font-size: 14px"
              >Koushin:</span
            >
            <a-select
              class="mr-1 ml-1"
              v-model="selectedKoushin"
              :style="StyleWidth"
            >
              <a-select-option
                v-for="item in koushinList"
                :key="item"
                v-value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </template> -->

          <!-- @click="viewHistoryTable = !viewHistoryTable, loadRulebookHistoryNew()" -->
          <!-- <v-layout row wrap>
                <span>
                  <v-icon
                    class="mr-1 mt-1 ml-4"
                    :color="viewHistoryTable ? 'primary' : ''"
                    >mdi-history
                  </v-icon 
                ></span>     
                <v-btn small :color="viewHistoryTable ? 'primary' : ''"  @click="loadRulebookHistoryNew()" :style="Width">
                  <span class="mt-1">更新履歴</span> 
                </v-btn>
                <v-spacer></v-spacer>
              </v-layout> -->
        </a-col>

        <!-- 2022-09-13-->
        <a-col :xs="24" :sm="7" :md="5" class="mt-2">
          <v-layout row wrap>
            <span>
              <v-icon
                class="mr-1 mt-1 ml-4"
                :color="viewHistoryTable ? 'primary' : ''"
                >mdi-history
              </v-icon></span
            >
            <v-btn
              small
              :color="viewHistoryTable ? 'primary' : ''"
              @click="loadRulebookHistoryNew()"
              :style="Width"
            >
              <span class="mt-1">更新履歴</span>
            </v-btn>
            <v-spacer></v-spacer>
          </v-layout>
        </a-col>
      </a-row>
    </v-card>

    <a-empty v-if="!loading2 && cPaginatedData2.length == 0" />
    <!-- 2022-05-20 -->

    <template
      v-if="!loading2 && cPaginatedData2.length > 0 && !viewHistoryTable"
    >
      <v-layout class="ma-1" v-if="!loading2">
        <!-- <v-spacer/> -->
        <span
          style="color: #0f3057; font-weight: bold"
          v-if="searchRulebookIconAlignment && !isMultiple"
        >
          About {{ filteredGoogleRules.length }} results for keywords "{{
            searchText
          }}"
        </span>
        <span
          style="color: #0f3057; font-weight: bold"
          v-else-if="searchRulebookIconAlignment && isMultiple"
        >
          About {{ filteredGoogleRules.length }} results for multiple keywords
        </span>
        <span style="color: #0f3057; font-weight: bold" v-else flat
          >Total: {{ filteredGoogleRules.length }}</span
        >
        <v-spacer />
      </v-layout>
      <!-- <pre> {{ filteredGoogleRules }} </pre> -->
      <v-card
        flat
        v-if="!loading2 && cPaginatedData2.length > 0"
        style="overflow-y: scroll"
        :height="hideFilter ? $vuetify.breakpoint.height - 270 : $vuetify.breakpoint.height - 357"
        class="ma-1 pa-1 resetScrollTop"
      >
        <v-card elevation="0" v-for="(item, i) in cPaginatedData2" :key="i">
          <v-divider />
          <v-row dense class="mb-n4 ">
            <v-col cols="12" md="6">
              <!-- <v-row no-gutters> -->
              <!-- <v-col cols="12"> -->
              <!-- pdf -->
              <v-layout>
                <!-- NOTE: delete icon not necessary in aws 2024-08-07 -->
                <!-- <v-icon color="#f55666" class="ml-2">mdi-file-document</v-icon> -->
                <v-icon
                  class="ml-2"
                  :color="isFavColor(item)"
                  @click="favoriteFunction(item)"
                  >{{ isFavIcon(item) }}</v-icon
                >

                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span
                      @click="
                        viewRuleContent(item);
                        selectDoc = item._id;
                        searchLists.push(item._id);
                      "
                      v-on="on"
                      :class="listColorSearch(item._id)"
                      id="title_ellipsis"
                      class="mx-2 mt-n2"
                      style="font-size: 20px; font-weight: bold; cursor: pointer"
                      v-html="
                        `<span style='background-color:#31b16e; border-radius:4px' class='px-3 mr-2 white--text'><span style='font-size:15px;font-weight:normal;'>${item._id}</span></span>${item.title}${title_spacer}`
                      "
                    />
                  </template>
                  <span
                    v-html="
                      item.title.replace(
                        'background-color:yellow',
                        'background-color:none'
                      )
                    "
                  />
                </v-tooltip> -->
                <!-- NOTE: remove tooltip 2024-04-30 start -->
                    <span
                      @click="
                        viewRuleContent(item);
                        selectDoc = item._id;
                        searchLists.push(item._id);
                      "
                      :class="listColorSearch(item._id)"
                      id="title_ellipsis"
                      class="mx-2 mt-n2"
                      style="font-size: 20px; font-weight: bold; cursor: pointer"
                      v-html="
                        `<span style='background-color:#31b16e; border-radius:4px' class='px-3 mr-2 white--text'><span style='font-size:15px;font-weight:normal;'>${item._id}</span></span>${item.title}${title_spacer}`
                      "
                    />
                <!-- NOTE: remove tooltip 2024-04-30 end -->

                <span
                  class="title"
                  v-show="searchRulebookIconAlignment && isFilterOn && item.showLine && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'"
                  v-html="`${item.content}`"
                  style="color: #555555; margin-left: 30px; font-size: 12px"
                >
                </span>
              </v-layout>
            </v-col>
            <!-- NOTE: added compression of subline after searching 2024-05-23 -->
            <v-col cols="1" class="d-flex">
              <v-spacer></v-spacer>
              <div v-if="searchRulebookIconAlignment && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'">
                <v-btn color="primary" icon @click="toggleShowLine(item)">
                  <v-icon large>{{item.showLine ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col class="pt-1" cols="12" md="5">
              <v-row dense>
                <v-col cols="3"
                  >
                  <a-tag
                    style="width: 100%; color: white; text-align: center"
                    color="#424874"
                    >{{ item.productType }}
                  </a-tag></v-col
                >
                <v-col cols="2"
                  ><a-tag
                    style="width: 100%; color: white; text-align: center"
                    color="#e36387"
                    >{{ item.criteria }}
                  </a-tag></v-col
                >
                <v-col cols="2">
                  <a-tag
                    style="width: 100%; color: white; text-align: center"
                    small
                    color="#5c969e"
                  >
                    <!-- {{item.method}} -->
                    <span v-if="item.method == 'JIKU'">
                      {{ methodList[1].text }}
                    </span>
                    <span class="mr-1" v-else-if="item.method == 'WAKU'"
                      >{{ methodList[2].text }}
                    </span>
                    <span v-else>枠組/軸組 </span>
                  </a-tag>
                </v-col>
                <v-col cols="3">
                  <a-tag
                    color="#3d7ea6"
                    style=" color: white;"
                    small
                  >
                    <v-icon v-if="item.koushinName != '' && item.koushinName==latestKoushinName" small class="mr-2" :color="item.version>1?'#5AB2FF':'yellow'">{{item.version>1?'mdi-alpha-r-box':'mdi-alpha-n-box'}}</v-icon>
                    <span class="hidden-md-and-down">更新日:</span>
                    <!-- NOTE: change key for sorting document by date 2024-03-27 -->
                    <!-- {{ item.RulebookUpdatedDate }} -->
                    {{ item.UpdatedDate }}
                  </a-tag>
                </v-col>
                <v-col cols="1">
                  <v-icon style="font-size: 10px" color="#6ebaa7"
                    >mdi-eye</v-icon
                  ><span v-if="item.countViews" style="font-size: 10px"
                    >{{ item.countViews }}
                  </span>
                  <span v-else style="font-size: 10px"> 0 </span>
                </v-col>
                <v-col cols="1"
                  ><v-icon small color="#ffa62b">mdi-star</v-icon>
                  <span style="font-size: 10px"
                    >{{ item.FavoritesCount }}
                  </span></v-col
                >
              </v-row>
            </v-col>
            <v-col v-show="!isFilterOn">
              <span
                class="title"
                v-show="searchRulebookIconAlignment && item.showLine && selectedCondition != 'タイトル' && selectedCondition != '帳票番号'"
                v-html="`${item.content}`"
                style="color: #555555; margin-left: 30px; font-size: 12px"
              >
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
      <!-- <a-empty v-else-if="!loading2 && cPaginatedData2.length == 0" /> -->
      <!----- Removed 20231124------->
      <v-pagination
        v-model="intPageNo"
        :length="cPageCount2"
        :total-visible="5"
        dark
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        v-if="!loading2 && !rulecontent"
      ></v-pagination>
    </template>

    <!-- TEMPLATE FOR RULEBOOK HISTORY  2022-03-09 -->

    <template v-if="!loading2 && !rulecontent && viewHistoryTable">
      <div>
        <v-row class="mb-n2">
          <v-col cols="8">
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
              >Total: {{ rulebookHistory.length }}</span
            >
            |
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
              >Total New: {{ totalNewHistory }}</span
            >
            |
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
              >Total Revised: {{ totalRevisedHistory }}</span
            >
            |
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
              >Total Deleted: {{ totalDeletedHistory }}</span
            >
          </v-col>
          <v-col cols="4">
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
              >LEGEND :</span
            >
            <v-chip
              color="#FCF3CF"
              text-color="#0f3057"
              width
              style="width: 80px; font-weight: bold; height: 20px"
              class="pl-6 mx-1"
            >
              New
            </v-chip>
            <v-chip
              color="#0f3057"
              outlined
              style="width: 80px; font-weight: bold; height: 20px"
              class="mx-1"
            >
              Deleted
            </v-chip>
            <v-chip
              color="#D1F2EB"
              text-color="#0f3057"
              style="width: 80px; font-weight: bold; height: 20px"
              class="mx-1"
            >
              Revised
            </v-chip>
          </v-col>
        </v-row>
        <!-- :data-source="filteredRulebookHistory"  -->
        <!-- <pre> {{ rulebookHistory }} </pre> -->
        <!-- <a-table :rowClassName="rowColor"  :pagination="false"  size="middle" :data-source="filteredRulebookHistory" :columns="headerColumns" :scroll="{ x: 2500, y: myScreenHeight - 370 }">
                <template slot="newControlNumber" slot-scope="text">
                  <a color="primary" text small @click="openRulebook(text)">
                    <span slot="title" style="color: #1890ff">{{text.newControlNumber}}</span>                 
                  </a>
                </template>

                <template slot="framework" slot-scope="text">
                  <a-tag style="width: 80px" v-if="text.framework" :color="text.framework == 'JIKU' ? 'blue' : text.framework == 'WAKU' ? 'green' : 'orange'">
                    <center>{{text.framework}}</center>
                  </a-tag>
                  <span v-else>
                    -
                  </span>
                </template>
              </a-table> -->

        <v-container fluid>
          <v-simple-table
            dense
            fixed-header
            :height="myScreenHeight - 370"
            style="margin-top:-10px"
          >
            <template v-slot:default>
              <thead class=" white--text">
                <tr>
                  <th
                    class="text-left"
                    @click="SortByColumn('updatedDate')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 更新日 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                  <th
                    class="text-left"
                    @click="SortByColumn('productCategory')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 製品区分 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                  <th
                    class="text-left"
                    @click="SortByColumn('classification')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 区分 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                  <th
                    class="text-left"
                    @click="SortByColumn('updateCategory')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 更新区分 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                  <th
                    class="text-left"
                    @click="SortByColumn('formerControlNumber')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 元管理番号 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                  <th
                    class="text-left"
                    @click="SortByColumn('newControlNumber')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 新管理番号 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                  <th
                    class="text-left"
                    @click="SortByColumn('updateContents')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 更新内容 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                  <!---ADDED 2023-10-18--->
                  <th class="text-left">更新内容</th>
                  <th
                    class="text-left"
                    @click="SortByColumn('personIncharge')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 担当者 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                  <th
                    class="text-left"
                    @click="SortByColumn('framework')"
                    style="cursor:pointer"
                  >
                    <v-tooltip bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <span v-on="on"> 構法 </span>
                      </template>
                      <span style="font-family:Arial">
                        {{ columnOrder == "asc" ? "Descending" : "Ascending" }}
                      </span>
                    </v-tooltip>
                    <v-icon color="white" class="ml-2" small>mdi-sort</v-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in paginatedRulebookHistory"
                  :key="i"
                  :class="rowColor(item)"
                >
                  <td class="text-left" width="7%">{{ item.updatedDate }}</td>
                  <td class="text-left" width="7%">
                    {{ item.productCategory }}
                  </td>
                  <td class="text-left" width="7%">
                    {{ item.classification }}
                  </td>
                  <td class="text-left" width="7%">
                    {{ item.updateCategory }}
                  </td>
                  <td class="text-left" width="10%">
                    {{
                      item.formerControlNumber ? item.formerControlNumber : "-"
                    }}
                  </td>
                  <td class="text-left" width="10%">
                    <a color="primary" text small @click="openRulebook(item)">
                      <span slot="title" style="color: #1890ff">{{
                        item.newControlNumber
                      }}</span>
                    </a>
                  </td>
                  <td class="text-left" width="40%">
                    {{ item.updateContents }}
                  </td>
                  <td class="text-left" width="40%">
                    <!-----Commented for Fixing-------->
                    <!-- <a-button
                      type="primary"
                      text
                      size="small"
                      style="width: 55px"
                      v-if="item.status == 'revised'"
                      @click="openHistory(item)"
                      >View
                      <RulebookHistoryViewDialog  
                        :historyObjToPass="historyObjToPass"
                        :updateDetailsDialog="historyObjToPass.openDialog"
                        @closeDetailsDialog="closeDetailsDialog"
                        v-if="openHistoryComponent"
                    />
                    </a-button> -->
                  </td>
                  <td class="text-left" width="5%">
                    {{ item.personIncharge }}
                  </td>
                  <td class="text-left" width="7%">
                    <a-tag
                      style="width: 80px"
                      v-if="item.framework"
                      :color="
                        item.framework == 'JIKU'
                          ? 'blue'
                          : item.framework == 'WAKU'
                          ? 'green'
                          : 'orange'
                      "
                    >
                      <center>{{ item.framework }}</center>
                    </a-tag>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </div>
      <v-pagination
        v-if="viewHistoryTable"
        class="mt-1"
        v-model="pageNumber"
        :length="paginationLength"
        :total-visible="5"
        dark
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        style="margin-top:-60px"
      ></v-pagination>
    </template>

    <!-- OLD -->
    <!-- <template v-if="!loading2 && !rulecontent && viewHistoryTable">
          <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
            >Total: {{ rulebookHistory.length }}</span> | 
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
            >Total New: {{ totalNewHistory }}</span> | 
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
            >Total Revised: {{ totalRevisedHistory }}</span> | 
            <span class="ma-4" style="color: #0f3057; font-weight: bold" flat
            >Total Deleted: {{ totalDeletedHistory }}</span>

           <a-table :rowClassName="rowColor" :loading="loading2" :pagination="false"  size="middle" :columns="headerColumns" :data-source="filteredRulebookHistory" :scroll="{ x: 2500, y: myScreenHeight - 370 }">
              <template slot="newControlNumber" slot-scope="text">
                <a color="primary" text small @click="openRulebook(text)">
                  <span slot="title" style="color: #1890ff">{{text.newControlNumber}}</span>
                  
                </a>
              </template>

              <template slot="framework" slot-scope="text">
                <a-tag style="width: 80px" v-if="text.framework" :color="text.framework == 'JIKU' ? 'blue' : text.framework == 'WAKU' ? 'green' : 'orange'">
                  <center>{{text.framework}}</center>
                </a-tag>
                <span v-else>
                  -
                </span>
              </template>

              
            </a-table>
          </template> -->
    </div>
  </v-container>
</template>

<script>
// var wshShell = new ActiveXObject("WScript.Shell");
const columns = [
  {
    // dataIndex: "variety",
    value: "variety",
    text: "品種",
    divider: true,
    width: "25%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "品番",
    // dataIndex: "hinban",
    value: "hinban",
    divider: true,
    width: "15%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "部材説明",
    // dataIndex: "buzai",
    value: "buzai",
    divider: true,
    width: "18%",
    class: "blue darken-4 white--text",
    sortable: false,
  },
  {
    text: "JWW",
    value: "JWWFile",
    divider: true,
    width: "13%",
    class: "blue darken-4 white--text",
    sortable: false,
    // dataIndex: "JWWFile",
    // scopedSlots: { customRender: "JWWFile" },
  },
  {
    text: "DXF",
    value: "DXFFile",
    divider: true,
    width: "13%",
    class: "blue darken-4 white--text",
    sortable: false,
    // dataIndex: "DXFFile",
    // scopedSlots: { customRender: "DXFFile" },
  },
  {
    text: "一般工法",
    value: "Ippan",
    divider: true,
    width: "10%",
    align: "center",
    class: "blue darken-4 white--text",
    sortable: false,
    // dataIndex: "Ippan",
    // scopedSlots: { customRender: "Ippan" },
  },
  {
    text: "夢の家",
    value: "YumeNoIe",
    divider: true,
    width: "10%",
    class: "blue darken-4 white--text",
    sortable: false,
    align: "center",
    // dataIndex: "YumeNoIe",
    // scopedSlots: { customRender: "YumeNoIe" },
  },
  {
    text: "G",
    value: "G",
    divider: true,
    width: "7%",
    class: "blue darken-4 white--text",
    sortable: false,
    align: "center",
    // dataIndex: "G",
    // scopedSlots: { customRender: "G" },
  },
  {
    text: "F",
    value: "F",
    divider: true,
    width: "5%",
    class: "blue darken-4 white--text",
    align: "center",
    sortable: false,
    // dataIndex: "F",
    // scopedSlots: { customRender: "F" },
  },
  {
    text: "A",
    value: "A",
    divider: true,
    width: "5%",
    align: "center",
    class: "blue darken-4 white--text",
    sortable: false,
    // dataIndex: "A",
    // scopedSlots: { customRender: "A" },
  },
  {
    text: "B",
    value: "B",
    divider: true,
    width: "5%",
    align: "center",
    class: "blue darken-4 white--text",
    sortable: false,
    // dataIndex: "B",
    // scopedSlots: { customRender: "B" },
  },
  {
    text: "i-cube",
    value: "iCube",
    align: "center",
    divider: true,
    width: "5%",
    class: "blue darken-4 white--text",
    sortable: false,
    // dataIndex: "iCube",
    // scopedSlots: { customRender: "iCube" },
  },
  {
    text: "i-smart",
    value: "iSmart",
    align: "center",
    divider: true,
    width: "5%",
    class: "blue darken-4 white--text",
    sortable: false,
    // dataIndex: "iSmart",
    // scopedSlots: { customRender: "iSmart" },
  },
];

import axios from "axios";
import Swal from "sweetalert2";
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";
import FileSaver from "file-saver";

import { mask } from "vue-the-mask";
import * as binconv from "binconv";
import moji from "moji";

// import { readableStreamToBlob } from "binconv";
// import RulebookHistoryViewDialog from "../views/RulebookHistoryViewDialog.vue";
import _ from "lodash";

export default {
  directives: {
    mask,
  },
  components: {
    // document,
    // VueHtml2pdf,
    // FeedBack: () => import("./FeedBack"),
    // RulebookHistoryViewDialog: RulebookHistoryViewDialog,
  },
  data() {
    return {
      ////==========ZHELL==========////
      rulebookHistory: [], // 2022-09-08
      pageNumber: 1, // 2022-09-08
      intRowNoAllDocs: 20, // 2022-09-08
      columnOrder: "desc", // 2022-09-08

      selectedYear: "ALL", // 2022-09-13
      selectedKoushin: "ALL", // 2022-09-13
      koushinList: ["ALL"], // 2022-09-13

      searchHistory: false,
      selectedProdCat: "ALL",
      favoriteList: [],
      histFrameWork: [
        { text: "ALL", value: "ALL" },
        { text: "軸組", value: "JIKU" },
        { text: "枠組", value: "WAKU" },
        { text: "軸組/枠組", value: "JIKU/WAKU" },
      ],
      selectedHistFrameWork: "ALL",
      selectedHistClassification: "ALL",
      histClassification: ["ALL", "ルール", "製品仕様", "基本図面", "パターン"],
      selectedHistUpCat: "ALL",
      selectedHistIncharge: "ALL",
      // histUpCatList: [],
      viewHistoryTable: false,

      // 2022-09-08
      headerColumns: [
        {
          value: "updatedDate",
          text: "更新日",
          divider: true,
          width: "10%",
          class: "blue darken-4 white--text",
          sortable: false,
        },
        {
          value: "productCategory",
          text: "製品区分",
          divider: true,
          width: "10%",
          class: "blue darken-4 white--text",
          sortable: false,
        },
        {
          value: "classification",
          text: "区分",
          divider: true,
          width: "10%",
          class: "blue darken-4 white--text",
          sortable: false,
        },
      ],

      headerColumns1: [
        // old
        {
          title: "更新日",
          width: 100,
          dataIndex: "updatedDate",
          key: "updatedDate",
          fixed: "left",
          align: "left",
          sorter: (a, b) => a.updatedDate.localeCompare(b.updatedDate),
        },
        {
          title: "製品区分",
          dataIndex: "productCategory",
          width: 130,
          key: "productCategory",
          fixed: "left",
          align: "left",
          sorter: (a, b) => a.productCategory.localeCompare(b.productCategory),
        },
        {
          title: "区分",
          dataIndex: "classification",
          width: 130,
          key: "classification",
          align: "left",
          sorter: (a, b) => a.classification.localeCompare(b.classification),
        },
        {
          title: "更新区分",
          key: "updateCategory",
          width: 150,
          dataIndex: "updateCategory",
          align: "left",
          sorter: (a, b) => a.updateCategory.localeCompare(b.updateCategory),
        },
        {
          title: "元管理番号",
          key: "formerControlNumber",
          width: 150,
          dataIndex: "formerControlNumber",
          align: "left",
          sorter: (a, b) =>
            a.formerControlNumber.localeCompare(b.formerControlNumber),
        },
        {
          title: "新管理番号",
          key: "newControlNumber",
          sorter: (a, b) =>
            a.newControlNumber.localeCompare(b.newControlNumber),
          // dataIndex: 'newControlNumber',
          align: "left",
          width: 150,
          scopedSlots: { customRender: "newControlNumber" },
          // sorter: (a, b) => a.newControlNumber.localeCompare(b.newControlNumber)
        },
        {
          title: "更新内容",
          key: "updateContents",
          dataIndex: "updateContents",
          align: "left",
          sorter: (a, b) => a.updateContents.localeCompare(b.updateContents),
        },
        {
          title: "担当者",
          key: "personIncharge",
          width: 100,
          fixed: "right",
          dataIndex: "personIncharge",
          align: "left",
          sorter: (a, b) => a.personIncharge.localeCompare(b.personIncharge),
        },
        {
          title: "構法",
          width: 120,
          key: "framework",
          fixed: "right",
          scopedSlots: { customRender: "framework" },
          align: "left",
          dataIndex: "framework", // 2022-03-10
          sorter: (a, b) => a.framework.localeCompare(b.framework),
        },
      ],
      ////====================////
      loadingPage: true,
      tabNumber: "1",
      filterMaster: [],
      hideFilter: false,//NOTE: show the filter in default 2024-04-18
      dataSource: [],
      isVisibleMoreFilter: false,
      isvisibleFilter: false,
      isFavorite: false,
      imagesList: [],
      tempData: "",
      imagesrc: "",
      sortByFavoriteASC: false,
      sortByFavoriteDES: false,
      sortByProductTypeASC: false,
      sortByProductTypeDES: false,
      sortByMethodASC: false,
      sortByMethodDES: false,
      sortByCriteriaASC: false,
      sortByCriteriaDES: false,
      sortByDateASC: false,
      sortByDateDES: false,
      printPreview: false,
      isMultiple: false,
      columns,
      title_spacer: "　",
      selectedCondition: "",
      intPageNo: 1,
      intRowNoRulebookDocument: 11,
      intRowNoSearchDocument: 30,
      // condition: ["AND", "OR", "MULTI" , "REFERENCE #", "TITLE", "ID & TITLE" ],//20230704 some change to japanese text
      // condition: ["AND", "OR", "MULTI" , "帳票番号", "タイトル", "帳票番号＆タイトル" ],// removed 帳票番号＆タイトル 20230711
      condition: ["AND", "OR", "MULTI", "帳票番号", "タイトル"],
      rulecontent: false,
      googleRules: [],
      searchGoogle: true,
      updatedRules: [],
      viewedRule: {
        approver: "",
        criteria: "",
        document: {
          SeihinKubun: "",
          KanriBangou: "",
          RuleKenmei: "",
          Kubun: "",
          YumeNoIe: true,
          Ippan: true,
          Icube: false,
          Ismart: false,
          Taishin: true,
          Menshin: true,
          Tantou: "",
          MailAddress: "",
          TantouBusho: "",
          TekiyouKubun: "",
          UpdatedDate: "",
        },
        documentType: "RULEBOOK",
        isApproved: true,
        method: "JIKU",
        productType: "外部付帯",
        updated: true,
        version: 1,
        _id: "",
        _rev: "0",
      },
      toSearch: "",
      approvalRemarks: "",
      approvedDialog: false,
      historyDialog: false,
      searchDialog: false,
      searchText: "",
      exceptText: "",
      docStatus: "approved",
      docStatusOption: ["approved", "for approval", "obsolete"],
      hideResult: true,
      loading: true,
      selectDoc: "",
      searchLists: [],
      criteriaList: ["ALL", "ルール", "製品仕様", "基本図面", "パターン"],
      selectedCriteria: "",
      methodList: [
        { text: "ALL", value: "ALL" },
        { text: "軸組", value: "JIKU" },
        { text: "枠組", value: "WAKU" },
        // { text: "枠組/軸組", value: "WAKU/JIKU" },//NOTE: remove waku/jiku 2024-05-27
      ],
      selectedMethod: "",
      selectedproductType: "",
      productTypeList: [
        `ALL`,
        `サッシ`,
        `システムバス`,
        `その他`,
        `バルコニー`,
        `階段`,
        `外部付帯`,
        `建具`,
        `構造`,
        `収納`,
        `住設`,
        `出窓`,
        `設備`,
        `太陽光`,
        `夢の家`,
        `免震`,
        `洋室造作`,
        `洋室造作/和室造作`,
        "洋室造作/和室造作/建具",
        `和室造作`,
        `和室造作/建具/出窓`,
      ],
      loading2: false,
      listData: [],
      toSearchJWW: "",
      pagination: {},
      OptVariety: [
        "All",
        "エコカラット割り付け図(｢i-cube 240+｣)",
        "エコカラット割り付け図(｢i-cube 260+｣)",
        "エコカラット割り付け図(｢i-smart 240+｣)",
        "エコカラット割り付け図(｢i-smart 260+｣)",
        "エコカラット割り付け図(｢夢の家 240｣)",
        "エコカラット割り付け図(｢夢の家 265｣)",
        "オリジナルテレビボード",
        "玄関上がり框",
        "勾配天井",
        "自在棚",
        "システムクローゼット(ユニット) ケーシング枠",
        "システムパントリー(ユニット)ケーシング枠",
        "白木調造作造作材",
        "スリットウォール",
        "スリットスライダー建具 (洋室)",
        "スリットスライダー建具 (和室)",
        "スリットルーバー",
        "ソリッドウッドパネル",
        "ソロモンマホガニー造作材",
        "タレ壁３方枠（白木調造作）",
        "タレ壁３方枠（白松造作）",
        "タレ壁３方枠（木調造作）",
        "タレ壁３方枠（木調造作/白木調造作）",
        "トータルシステムクローゼット(ウォークインタイプ)",
        "トータルシステムクローゼット(ユニット)",
        "トータルシステムクローゼット(ユニット) ケーシング枠",
        "ブックシェルフ(ユニット))ケーシング枠",
        "フリーカウンター",
        "丸棒手摺金物",
        "ユニット出窓",
        "リモコンニッチ",
        "ロフト",
        "和室建具平面図（白木調造作）",
        "和室建具平面図（白松造作）",
        "和室建具平面図（檜造作）",
        "階段関係",
        "階段関係　（240+）",
        "階段関係　（260+）",
        "掘座卓",
        "高性能樹脂サッシ(Sマホガ二ー造作)",
        "高性能樹脂サッシ(キッチン樹脂枠)",
        "高性能樹脂サッシ(白木調造作)",
        "高性能樹脂サッシ(白松造作)",
        "高性能樹脂サッシ(白松造作・内障子枠)",
        "高性能樹脂サッシ(檜造作)",
        "高性能樹脂サッシ(木調造作)",
        "高性能樹脂サッシ（木調造作/白木調造作）",
        "室内明かりとり",
        "住設",
        "造作材",
        "白松造作造作材",
        "木調造作材",
        "洋室建具平面図（Sマホガニー造作）",
        "洋室建具平面図（木調造作）",
        "洋室建具平面図（木調造作/白木調造作）",
        "檜造作造作材",
      ],
      Variety: "All",
      OptSpecs: [
        { text: "All", value: "All" },
        { text: "一般工法", value: "Ippan" },
        { text: "夢の家", value: "YumeNoIe" },
        { text: "G", value: "G" },
        { text: "F", value: "F" },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "i-cube", value: "iCube" },
        { text: "i-smart", value: "iSmart" },
      ],
      Specifications: ["All"],
      buildingTypeList: [//NOTE: move Gsmart,HUGme & HUGmefam on top of the list 2024-05-08
        { text: "ALL", value: "ALL" },
        { text: "グラン•スマート", value: "Gsmart" }, // 2022-11-15
        { text: "HUGme", value: "HUGme" },
        { text: "HUGme fam", value: "HUGmefam" }, // 2023-03-17
        { text: "i-smart", value: "Ismart" },
        { text: "i-cube", value: "Icube" },
        { text: "i-smile", value: "Ismile" },
        { text: "グランセゾン", value: "G" },
        { text: "ブリアール", value: "B" },
        { text: "セゾンA", value: "A" },
        { text: "セゾンF", value: "F" },
        { text: "セゾンF2-TD", value: "F2-TD" },
        { text: "夢の家", value: "YumeNoIe" },
        { text: "一般", value: "Ippan" },
      ],
      buildingSpecificationList: [
        { text: "ALL", value: "ALL" },
        { text: "耐震", value: "Taishin" },
        { text: "免震", value: "Menshin" },
      ],
      selectedBuildingType: ["ALL"],
      selectedBuildingSpecification: ["ALL"],
      historyDetails: "",
      dateFrom: "",
      dateTo: "",
      dateFromMenu: false,
      dateToMenu: false,
      mask: "####-##-##",
      synonymsData: [],
      searchRulebookIconAlignment: false,
      composing: false,

      searchDocNo: "", // 2023-06-30
      searchTitle: "", // 2023-06-30
      historyObjToPass: "", //2024-01-16
      openHistoryComponent: false,
    };
  },
  created() {
    // console.log('%c%s', 'color: #41cc00', 'TEST')
    this.getUserFavorites();
    this.selectedCriteria = "ALL";
    this.selectedMethod = "ALL";
    this.selectedproductType = "ALL";
    this.TAB_CND("Search");
    // console.log(this.tabCondition)
    if (this.globalSearchWord && this.globalCondition) {
      console.log('global search')
      if (this.isSearchAll) {
        this.searchText = this.globalSearchWord;
        this.selectedCondition = this.globalCondition;
        this.exceptText = this.globalExceptWord;

        if (this.selectedCondition != "MULTI") {
          this.onSearch();
        } else {
          this.onMultipleSearch();
        }
      }
    } else {
      if (this.userInfo.employee_code != "japanese") {
        this.searchGoogle = true;
      }
      this.loading2 = true;
      this.docStatus = "approved";
      this.selectedCondition = "MULTI";
      this.loadFile();
      this.loadAllDocs();
    }
  },
  watch: {
    //NOTE: reset scroll top 2024-04-23
    pageNumber(){
      document.querySelector('.v-data-table__wrapper').scrollTop = 0
    },
    //NOTE: reset scroll top 2024-04-23
    intPageNo(){
      let lists = document.querySelectorAll('.resetScrollTop')
      for(let list of lists){
        list.scrollTop = 0
      }
    },
    selectedYear(val) {
      // 2022-09-13
      if (val) {
        this.pageNumber = 1;
      }
    },
    selectedKoushin(val) {
      if (val) {
        this.pageNumber = 1;
      }
    },
    dateTo() {
      if (this.dateTo < this.dateFrom) {
        this.dateTo = "";
      }
    },
    docStatus() {
      // this.getDataRules();
    },
    selectedBuildingType() {
      if (
        this.selectedBuildingType.includes("ALL") &&
        this.selectedBuildingType.length == 2
      ) {
        this.selectedBuildingType.splice(0, 1);
      }
      if (this.selectedBuildingType.length == 0) {
        this.selectedBuildingType = ["ALL"];
      }
      if (
        this.selectedBuildingType.includes("ALL") &&
        this.selectedBuildingType.length != 1
      ) {
        this.selectedBuildingType = ["ALL"];
      }
    },

    selectedBuildingSpecification() {
      if (
        this.selectedBuildingSpecification.includes("ALL") &&
        this.selectedBuildingSpecification.length == 2
      ) {
        this.selectedBuildingSpecification.splice(0, 1);
      }
      if (this.selectedBuildingSpecification.length == 0) {
        this.selectedBuildingSpecification = ["ALL"];
      }
      if (
        this.selectedBuildingSpecification.includes("ALL") &&
        this.selectedBuildingSpecification.length != 1
      ) {
        this.selectedBuildingSpecification = ["ALL"];
      }
    },

    Specifications() {
      if (
        this.Specifications.includes("All") &&
        this.Specifications.length == 2
      ) {
        this.Specifications.splice(0, 1);
      }
      if (this.Specifications.length == 0) {
        this.Specifications = ["All"];
      }
      if (
        this.Specifications.includes("All") &&
        this.Specifications.length != 1
      ) {
        this.Specifications = ["All"];
      }
    },
  },
  methods: {
    //NOTE: merged search function for "AND" & "MULTI" 2024-09-25
    async andMultiSearch(){
      try{
        this.loading2 = true;
        this.searchRulebookIconAlignment = true
        this.pageNumber = 1
        this.selectedCondition='MULTI'
        this.googleRules = await this.mergedSearch()
        this.googleRules = this.googleRules
        .map((r)=>{
          r.UpdatedDate = r.UpdatedDate.substring(0,10)
          return r
        })
        .sort((a, b) => {
          return a._id > b._id ? 1 : -1;
        })
        .sort((a, b) => {
          return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
        }).map(r=>{
          this.$set(r,'showLine',false)
          return r
        });
      }catch(err){
        if(err){
          console.log(err)
        }
      }
    },
    mergedSearch() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      return new Promise((resolve, reject) => {
        const url = `${this.api}search/andmultiple/?searchText=${this.searchText}&exceptWord=`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              this.loading2 = false;
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    // showData(){
    //   console.log(this.filteredGoogleRules.map(r=>r._id))
    // },
    // NOTE: added search function for search button 2024-06-21
    allSearch(){
      if(this.searchDocNo){
        this.selectedCondition = '帳票番号'
        this.onSearch()
      }
      else{
        this.selectedCondition = 'AND'
        this.fullTextSearch()
      }
    },
    // NOTE: added fulltext search include "AND" conditiona and "MULTI" 2024-06-15
    fullTextSearch(){
      this.loading2 = true;
      this.searchDocNo = ""
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      const url = `${this.api}check/multi?group=${this.searchText}`;
      axios.get(url).then((res)=>{
        if(res.data){
          this.onMultipleSearch()
        }
        else{
          this.onSearch()
        }
      })
    },
    // NOTE: added toggle for show/hide of subline after searching 2024-05-23
    async toggleShowLine(item){
      let index = this.googleRules.indexOf(item)
      // console.log('line 2304', index, this.googleRules[index])
      if(index > -1){
        this.$set(this.googleRules[index],'showLine',!this.googleRules[index].showLine)
        // this.googleRules[index].showLine = !this.googleRules[index].showLine
        this.googleRules = this.googleRules
      }
    },

    openHistory(val) {
      // console.log(val);
      // /get/history/:id
      let controlNumber = val.newControlNumber;
      let id = val.id;
      // this.openHistoryComponent = true;
      // this.historyObjToPass = {
      //   id: id,
      //   controlNumber: controlNumber,
      //   openDialog: true,
      // };
      window.open(`rulebookComparing/${id}?id2=${controlNumber}`, "_blank");
    },
    closeDetailsDialog(val) {
      // added 2024-01-18
      this.openHistoryComponent = val;
    },
    // 2022-09-13
    GetKoushinList(year) {
      //  console.log('line2039', year )
      if (year == "ALL") {
        this.koushinList = ["ALL"];
      } else {
        this.koushinList = this.rulebookHistory
          .filter((rec) => {
            if (rec.year != undefined) {
              return rec.year == year;
            }
          })
          .map((rec1) => {
            return rec1.koushinName;
          });

        this.koushinList.unshift("ALL");

        this.koushinList = [...new Set(this.koushinList)];

        //  console.log('line2049', this.koushinList )
      }
    },
    // 2022-06-04
    sortByProductType(action) {
      if (action == false) {
        // console.log("sort by product type");
        this.googleRules = _.orderBy(
          this.googleRules,
          ["productType"],
          ["desc"]
        );
        this.sortByMethodASC = false;
        this.sortByCriteriaASC = false;
        this.sortByFavoriteASC = false;
        this.sortByDateASC = false;
      } else {
        //  this.loadAllDocs();
        this.googleRules = _.orderBy(
          this.googleRules,
          ["productType"],
          ["asc"]
        );
        this.sortByProductTypeASC = true;
        this.sortByMethodASC = false;
        this.sortByCriteriaASC = false;
        this.sortByFavoriteASC = false;
        this.sortByDateASC = false;
      }
    },
    sortByMethod(action) {
      if (action == false) {
        // console.log("sort by method");
        this.googleRules = _.orderBy(this.googleRules, ["method"], ["desc"]);
        this.sortByProductTypeASC = false;
        this.sortByCriteriaASC = false;
        this.sortByFavoriteASC = false;
        this.sortByDateASC = false;
      } else {
        // this.loadAllDocs();
        this.googleRules = _.orderBy(this.googleRules, ["method"], ["asc"]);
        this.sortByProductTypeASC = false;
        this.sortByMethodASC = true;
        this.sortByCriteriaASC = false;
        this.sortByFavoriteASC = false;
        this.sortByDateASC = false;
      }
    },
    sortByCriteria(action) {
      if (action == false) {
        // console.log("sort by criteria");
        this.googleRules = _.orderBy(this.googleRules, ["criteria"], ["desc"]);
        this.sortByProductTypeASC = false;
        this.sortByMethodASC = false;
        this.sortByFavoriteASC = false;
        this.sortByDateASC = false;
      } else {
        //  this.loadAllDocs();
        this.googleRules = _.orderBy(this.googleRules, ["criteria"], ["asc"]);
        this.sortByProductTypeASC = false;
        this.sortByMethodASC = false;
        this.sortByCriteriaASC = true;
        this.sortByFavoriteASC = false;
        this.sortByDateASC = false;
      }
    },

    sortByFavorites(action) {
      if (action == false) {
        // console.log("sort by favorites");
        this.googleRules = this.googleRules.sort((a, b) =>
          a.FavoritesCount < b.FavoritesCount ? 1 : -1
        );
        this.sortByProductTypeASC = false;
        this.sortByMethodASC = false;
        this.sortByCriteriaASC = false;
        this.sortByDateASC = false;
      } else {
        // this.loadAllDocs();
        this.googleRules = this.googleRules.sort((a, b) =>
          a.FavoritesCount < b.FavoritesCount ? -1 : 1
        );
        this.sortByProductTypeASC = false;
        this.sortByMethodASC = false;
        this.sortByCriteriaASC = false;
        this.sortByFavoriteASC = true;
        this.sortByDateASC = false;
      }
    },

    sortByUpdatedDate(action) {
      if (action == false) {
        // console.log("sort by updated date");
        this.googleRules = this.googleRules
        .sort((a, b) => {
          return a._id > b._id ? 1 : -1;
        })
        this.googleRules = _.orderBy(
          this.googleRules,
          // ["RulebookUpdatedDate"],//NOTE: change key for sorting document by date 2024-03-19
          ["UpdatedDate"],
          ["desc"]
        );
        this.sortByProductTypeASC = false;
        this.sortByMethodASC = false;
        this.sortByCriteriaASC = false;
        this.sortByFavoriteASC = false;
      } else {
        this.googleRules = _.orderBy(
          this.googleRules,
          // ["RulebookUpdatedDate"],//NOTE: change key for sorting document by date 2024-03-19
          ["UpdatedDate"],
          ["asc"]
        );
        this.sortByProductTypeASC = false;
        this.sortByMethodASC = false;
        this.sortByCriteriaASC = false;
        this.sortByFavoriteASC = false;
        this.sortByDateASC = true;
      }
    },

    rowColor(rec) {
      // 2022-09-08
      return rec.status.toLowerCase() == "new"
        ? "bg-yellow"
        : rec.status.toLowerCase() == "revised"
        ? "bg-green"
        : "";
    },
    openRulebook(data) {
      let url = `${this.api}rulebookHistory/getNewControlNumber/${data.newControlNumber}`;
      axios.get(url).then((res) => {
        if (res.data != "empty") {
          let tempContent = res.data.document;
          if (res.data.FavoritesCount) {
            tempContent.FavoritesCount = res.data.FavoritesCount;
          }
          tempContent._id = res.data._id;
          tempContent.title = tempContent.RuleKenmei;

          window.open(
            `${this.linkURL}/document/${tempContent._id}?title=${tempContent.title}`,
            "_blank"
          );
          // console.log(tempContent);
        } else {
          alert("NO RESULT");
        }
      });
    },

    // 2022-09-09
    SortByColumn(columnName) {
      this.columnOrder = this.columnOrder == "desc" ? "asc" : "desc";
      this.rulebookHistory = _.orderBy(
        this.rulebookHistory,
        [columnName.toString()],
        [this.columnOrder.toString()]
      );
    },

    // 2022-09-08
    loadRulebookHistoryNew() {
      this.viewHistoryTable = !this.viewHistoryTable;
      // console.log(this.viewHistoryTable);
      if (this.viewHistoryTable) {
        this.pageNumber = 1;
        this.loading2 = true;
        // console.log("Load Rulebook History New");
        let url = `${this.api}rulebookHistory/GetRuleBookHistoryRecords`;
        axios
          .get(url)
          .then((res) => {
            // console.log(res.data);
            let sortedData = res.data.sort((a, b) => {
              return a.updatedDate < b.updatedDate ? 1 : -1;
            });

            let newHistory = _.orderBy(
              _.filter(sortedData, function(o) {
                return o.status == "new";
              }),
              ["updatedDate", "newControlNumber"],
              ["desc", "asc"]
            );
            let revisedHistory = _.orderBy(
              _.filter(sortedData, function(o) {
                return o.status == "revised";
              }),
              ["updatedDate", "newControlNumber"],
              ["desc", "asc"]
            );
            let deletedHistory = _.orderBy(
              _.filter(sortedData, function(o) {
                return o.status == "deleted";
              }),
              ["updatedDate", "newControlNumber"],
              ["desc", "asc"]
            );

            this.rulebookHistory = [
              ...newHistory,
              ...deletedHistory,
              ...revisedHistory,
            ]
              .filter((rec) => {
                return rec.deletedDate == undefined;
              })
              .map((r, i) => {
                r.key = i;
                return r;
              });

            this.loading2 = false;
          })
          .catch((err) => {
            console.log("line1895", err);
            alert("Error Fetching Rulebook History");
            this.loading2 = false;
          });
      } else {
        // console.log("papuntang rulebook");
        this.loadAllDocs(); // 2022-03-09
      }
    },

    loadRulebookHistory() {
      // 2022-03-09

      if (this.viewHistoryTable) {
        this.loading2 = true;
        let url = `${this.api}rulebookHistory/getHistory`;
        axios.get(url).then((res) => {
          this.rulebookHistory = res.data;
          // console.log("line1602", res.data);

          this.rulebookHistory
            .map((r, i) => {
              r.key = i;
              // if(!r.formerControlNumber || r.formerControlNumber == "/"){
              //   r.formerControlNumber = ''
              // }
              return r;
            })
            .sort((a, b) => {
              return a.updatedDate.localeCompare(b.updatedDate);
            });

          // console.log("line1637", this.rulebookHistory);

          this.loading2 = false;
        });
        // .then(() => {
        //   this.loading2 = false
        // })
      } else {
        // console.log("papuntang rulebook");
        this.loadAllDocs(); // 2022-03-09
      }
    },
    favoriteFunction(data) {
      if (this.favoriteList.includes(data._id)) {
        this.isFavorite = false;
      } else {
        this.isFavorite = true;
      }

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let toInsert = {
        id: data._id,
        userId: this.userInfo.id,
        isFavorite: this.isFavorite,
      };
      let url = `${this.api}addFavorite`;
      let url2 = `${this.api}addFavoriteCount`;
      axios.post(url2, toInsert).then(() => {
        axios.post(url, toInsert).then((res) => {
          if (res.data == "Add Favorite") {
            Swal.fire({
              icon: "success",
              title: `${data._id} added to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: `${data._id} deleted to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          }
          if (this.searchText != "") {
            this.onSearch();
            this.getUserFavorites();
          } else {
            this.loadAllDocs();
            this.getUserFavorites();
          }
        });
      });
    },
    addFavorite(data) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let toInsert = {
        id: data._id,
        userId: this.userInfo.id,
        isFavorite: this.isFavorite,
      };

      let url = `${this.api}addFavorite`;
      let url2 = `${this.api}addFavoriteCount`;
      axios.post(url2, toInsert).then(() => {
        axios.post(url, toInsert).then((res) => {
          // console.log("HEREEEEEEEEE", res.data);
          if (res.data == "Add Favorite") {
            this.SET_FAVORITE(this.isFavCnt + 1);
            Swal.fire({
              icon: "success",
              title: `${data._id} added to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          } else {
            this.SET_FAVORITE(this.isFavCnt - 1);
            Swal.fire({
              icon: "error",
              title: `${data._id} deleted to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          }
        });
      });
    },
    isFavIcon(data) {
      let isFavMemo = false;
      if (this.favoriteList.includes(data._id)) {
        isFavMemo = true;
      }
      if (isFavMemo) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    isFavColor(data) {
      let isFavMemo = false;
      if (this.favoriteList.includes(data._id)) {
        isFavMemo = true;
      }
      if (isFavMemo) {
        return "yellow";
      } else return "black";
    },
    getUserFavorites() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.loadingJapanMemo = true;
      let toGet = {
        userId: this.userInfo.id,
      };
      let url = `${this.api}getFavorites`;
      axios.post(url, toGet).then((res) => {
        // console.log(res.data);
        if(res.data){
          this.favoriteList = res.data;
        }
      });
    },
    onChangeCheck(e) {
      this.UPDATE_SEARCH_ALL(e.target.checked);
      if (e.target.checked == false) {
        this.CHANGE_SEARCH_WORD("");
        this.CHANGE_CONDITION("");
        this.CHANGE_EXCEPT_WORD("");
      }
    },
    StartCompose() {
      this.composing = true;
    },
    EndCompose() {
      // console.log('end')
      this.composing = false;
    },
    getMasterMultiple() {
      let url = `${this.api}get/SynonymChars`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.get(url).then((res) => {
        var merged = [].concat.apply([], res.data.data);
        this.dataSource = merged;
      });
    },
    onSearchAuto(searchText) {
      this.getMasterMultiple();
      if (searchText) {
        this.filterMaster = this.dataSource.filter((rec) => {
          let word = new RegExp(`${searchText}`, "g");
          if (rec != null) {
            return rec.match(word) != null;
          }
        });
      }
    },
    onSelect() {
      // console.log('onSelect', value);
      // this.searchText = value
    },
    onChange() {
      this.getMasterMultiple();
    },

    openFilter() {
      // this.isvisibleFilter = true
    },
    openFilterMore() {
      // this.isVisibleMoreFilter = true;
    },
    confirm(e) {
      console.log(e);
      this.$message.success("Click on Yes");
    },
    async filterFavorites() {
      await this.getUserFavorites();

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      this.loading2 = true;

      if (this.isFavorite) {
        let toGet = {
          userId: this.userInfo.id,
        };
        let url = `${this.api}getFavorites`;
        axios.post(url, toGet).then((res) => {
          // console.log(res.data);
          let list = res.data;
          this.googleRules = [];
          if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              let url2 = `${this.api}getDetailsFavorite?id=${list[i]}`;
              axios.get(url2).then((res) => {
                if(res.data){
                  this.googleRules.push(res.data);
                }
                this.loading2 = false;
              });
            }
          } else {
            this.loading2 = false;
          }
        });
      } else {
        this.clearSearch();
      }
    },
    ListImages() {
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });
      // console.log(tunnelingAgent, "proxy");

      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: "us-east-2",
        credentials: config,
      });
      var s3 = new AWS.S3({
        AccessKeyID: this.accessKeyId,
        SecretAccessKey: this.secretAccessKey,
        Region: "us-east-2",
        params: {
          bucket: "rulebook.files",
        },
        // endpoint:'https://s3.us-east-2.amazonaws.com/rulebook.files/'
      });
      // var s3params = {
      //   Bucket: "rulebook.files",
      //   Prefix: "Document_images/",
      //   // MaxKeys: 20,
      // };
      // s3.listObjectsV2(s3params, (err, data) => {
      //   if (err) {
      //     console.log(err);
      //   } else console.log(data);
      // });
      let Images = [];
      async function listAllObjectsFromS3Bucket(bucket, prefix) {
        let isTruncated = true;
        let marker;
        while (isTruncated) {
          let params = { Bucket: bucket };
          if (prefix) params.Prefix = prefix;
          if (marker) params.Marker = marker;
          try {
            const response = await s3.listObjects(params).promise();
            response.Contents.forEach((item) => {
              // Images.push(item.Key);
              console.log(item.Key);
              // return item.Key;
            });
            isTruncated = response.IsTruncated;
            if (isTruncated) {
              marker = response.Contents.slice(-1)[0].Key;
            }
          } catch (error) {
            throw error;
          }
          // console.log(Images, "ALL");
        }
      }

      // Images.push(
      // console.log(
      listAllObjectsFromS3Bucket("rulebook.files", "Document_images/");
      // );
      // .then(
      //   (data) => {
      //     console.log(data);
      //     // Images.push(data);
      //   }
      // );
      // );
      this.imagesList = Images;
    },
    ListResponsive() {
      let screen = eval(`${window.screen.height} * ${window.devicePixelRatio}`);
      // alert(screen)
      if (screen == "768" && !this.isFilterOn) {
        return "responsive768";
      } else if (screen == "1024" && !this.isFilterOn) {
        return "responsive1024";
      } else if (screen == "1080" && !this.isFilterOn) {
        return "responsive1080";
      } else if (screen >= "1200" && !this.isFilterOn) {
        return "responsive1200";
      } else if (screen == "768" && this.isFilterOn) {
        return "responsive1024";
      } else if (screen == "1024" && this.isFilterOn) {
        return "responsive1080";
      } else if (screen == "1080" && !this.isFilterOn) {
        return "responsive1200";
      } else {
        return "responsive";
      }
    },
    ListResponsive2() {
      let screen = eval(`${window.screen.height} * ${window.devicePixelRatio}`);
      // alert(screen)
      if (screen == "768") {
        return "responsive2768";
      } else if (screen == "1024") {
        return "responsive21024";
      } else if (screen == "1080") {
        return "responsive21080";
      } else if (screen >= "1200") {
        return "responsive21200";
      } else {
        return "responsive2";
      }
    },
    ImageSrc(imgName) {
      return new Promise((resolve) => {
        var tunnelingAgent = tunnel.httpsOverHttp({
          proxy: {
            // Proxy settings
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
          },
        });
        // console.log(tunnelingAgent, "proxy");

        AWS.config.update({
          httpOptions: { agent: tunnelingAgent },
          region: "us-east-2",
          credentials: config,
        });
        var s3 = new AWS.S3({
          AccessKeyID: this.accessKeyId,
          SecretAccessKey: this.secretAccessKey,
          Region: "us-east-2",
          params: {
            bucket: "rulebook.files",
          },
          // endpoint:'https://s3.us-east-2.amazonaws.com/rulebook.files/'
        });

        var options = {
          Bucket: "rulebook.files",
          Key: `Document_images/${imgName}.png`,
          // Key: "JWW_Files/02.jww",
          // Key: "9557811-2019-0905D07-Advance_Series__INDIVIDUAL_.xls",
        };
        // console.log(options, "options");
        s3.getObject(options, function(err, data) {
          if (err) {
            // console.log(err, err.stack);
          }
          // an error occurred
          else {
            // console.log(data);

            const base64 = binconv.uint8ArrayToBase64(data.Body);
            let content = `data:${data.ContentType};base64,`;
            // console.log(content);
            // console.log("ConvertBase64", base64);
            let finalcontent = `${content}${base64}`;
            // console.log(finalcontent);
            // var reader = new FileReader();

            // reader.readAsDataURL(base64);
            this.imagesrc = finalcontent;
            // successful response
            console.log(err);
            resolve(this.imagesrc);
          }
        });
      });
    },

    FileSrc(filename) {
      // console.log(filename);
      // console.log(filename.substring(filename.length, filename.length - 3));
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });
      // console.log(tunnelingAgent, "proxy");

      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: "us-east-2",
        credentials: config,
      });
      var s3 = new AWS.S3({
        AccessKeyID: this.accessKeyId,
        SecretAccessKey: this.secretAccessKey,
        Region: "us-east-2",
        params: {
          bucket: "rulebook.files",
        },
      });

      var options = {
        Bucket: "rulebook.files",
        Key: `JWW_Files/${filename}`,
      };
      // console.log(s3, "s3");

      s3.getObject(options, function(err, data) {
        if (err) console.log(err, err.stack);
        // an error occurred
        else {
          // console.log(data);
          var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
            type: `${data.ContentType};charset=utf-8`,
          });

          FileSaver.saveAs(blob, `${filename}`);
        } // successful response
      });
    },
    generateReport() {
      this.printPreview = true;
      this.$refs.html2Pdf.generatePdf();
    },
    print() {
      // this.$htmlToPaper("print");
      window.print();
    },
    clearSearch() {
      this.isFavorite = false;
      this.searchRulebookIconAlignment = false;
      this.loading2 = true;
      (this.sortByProductTypeASC = false),
        (this.sortByProductTypeDES = false),
        (this.sortByMethodASC = false),
        (this.sortByMethodDES = false),
        (this.sortByCriteriaASC = false),
        (this.sortByCriteriaDES = false),
        (this.sortByDateASC = false),
        (this.sortByDateDES = false),
        (this.isMultiple = false);
      this.loadAllDocs();
      this.getUserFavorites();
      this.searchText = "";
      this.searchDocNo = ""
      this.exceptText = "";
      this.searchHistory = false;
    },
    Copy() {
      Swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "Copy mail address to clipboard",
        showConfirmButton: false,
        timer: 800,
      });
    },
    loadFile() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let url = `${this.api}getAllFiles?docType=JWW`;
      axios.get(url).then((res) => {
        res.data = res.data.map((rec, index) => {
          rec.key = index;
          return rec;
        });

        this.listData = res.data;
        this.listData.sort((a, b) => (a._id > b._id ? 1 : -1));
      });
    },
    callback(val) {
      // this.rulecontent = false;
      //  this.$intro().exit()
      if (this.tabCondition == "Search") {
        // this.tabCondition = 'Files'
        this.TAB_CND("Files");
      } else if (this.tabCondition == "Files") {
        // this.tabCondition = 'Search'
        this.TAB_CND("Search");
      }
      this.tabNumber = val;
      // console.log(this.tabCondition);
      this.searchGoogle = true;
      this.rulecontent = false;
      this.selectDoc = "";
    },
    listColor(id) {
      if (id == this.selectDoc) {
        return "bgYellow";
      } else return "bgWhite";
    },

    listColorSearch(id) {
      if (this.searchLists.includes(id)) {
        return "viewed";
      }
    },
    getDataRules() {
      this.updatedRules = [];
      this.loading = true;

      if (this.docStatus != "obsolete") {
        let url = `${this.api}get/updated_rules/${this.docStatus}`;
        axios.get(url).then((res) => {
          this.updatedRules = res.data;

          this.updatedRules = this.updatedRules.map((a) => {
            if (a.title) {
              a.title = a.title.replace(/<br>/gi, "\n");
            }
            return a;
          });

          this.loading = false;
        });
      } else {
        let url = `${this.api}get/all/obsolete`;
        axios.get(url).then((res) => {
          this.updatedRules = res.data;

          this.updatedRules = this.updatedRules.map((a) => {
            a.title = a.title.replace(/<br>/gi, "\n");
            return a;
          });

          this.loading = false;
        });
      }
    },
    viewRule(val) {
      // this.viewedRule = [];

      let url = `${this.api}get/this/${val}`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.get(url).then((res) => {
        this.viewedRule = res.data;
      });
      this.searchGoogle = false;
      this.rulecontent = true;
    },
    async viewRuleContent(val) {
      // tanskie
      //console.log('line2078', val )
      console.log("Zel", val);
      this.SET_FAVORITE(0);
      this.SET_FAVORITE(val.FavoritesCount);
      if (!this.searchText) {
        ////Open Document without Search
        console.log("1");
        console.log("11111");
        window.open(
          `${this.linkURL}/document/${val._id}?title=${val.title}`,
          "_blank"
        );
      } else if (
        this.searchText.match(/^[0-9]/g) == null &&
        this.selectedCondition != "MULTI"
      ) {
        ////Open document on Search
        console.log("22222");
        console.log("2");
        window.open(
          `${this.linkURL}/document/${val._id}?search=${this.searchText}&title=${val.title}`,
          "_blank"
        );
      } else if (this.searchText && this.selectedCondition == "MULTI") {
        ////Open Document with MULTI and Search
        console.log("33333");
        console.log("3");
        window.open(
          `${this.linkURL}/document/${val._id}?search=${this.searchText}&multi=true&title=${val.title}`,
          "_blank"
        );
      } else {
        ////Open Document
        console.log("44444");
        console.log("4");
        window.open(
          `${this.linkURL}/document/${val._id}?title=${val.title}`,
          "_blank"
        );
      }
      let url = `${this.api}addCount/${val._id}`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.post(url).then(() => {
        let index = this.googleRules.indexOf(val);
        // console.log(index);
        if (this.googleRules[index].countViews) {
          this.googleRules[index].countViews =
            this.googleRules[index].countViews + 1;
        } else {
          this.googleRules[index].countViews = 1;
        }
      });
    },
    viewHistory(val) {
      this.viewedRule.version = val + 1;
      // this.historyDialog = false
    },
    // splitSelectedSpecs() {
    //   this.selectedBuildingType = this.selectedBuildingType.split(",");
    // },
    // searchRulebookHistory(){
    //   if(this.searchText){
    //     return this.rulebookHistory.filter(r => {
    //       return r.updateCategory == this.searchText || r.classification == this.searchText || r.framework == this.searchText
    //       || r.productCategory == this.searchText || r.formerControlNumber == this.searchText || r.newControlNumber == this.searchText
    //       || r.updateContents == this.searchText || r.personIncharge == this.searchText
    //     })
    //   }
    // },

    convertSearchText(word) {
      return {
        ZE_HE: moji(word)
          .convert("ZE", "HE")
          .toString(),
        HE_ZE: moji(word)
          .convert("HE", "ZE")
          .toString(),
        HK_ZK: moji(word)
          .convert("HK", "ZK")
          .toString(),
        ZK_HK: moji(word)
          .convert("ZK", "HK")
          .toString(),
        HG_KK: moji(word)
          .convert("HG", "KK")
          .toString(),
        KK_HG: moji(word)
          .convert("KK", "HG")
          .toString(),
      };
    },
    promiseGetSearchWord(condition = "and", search1 = "", search2 = "") {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}search/rulebook/${condition}/?search1=${search1}&search2=${search2}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    //2024-01-24 FOR DYNAMIC SEARCHING AND
    promiseGetSearchWordNew(condition = "and", searchText) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}search2/rulebook/${condition}/?searchText=${searchText}&exceptWord=${this.exceptText}`;

        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWordByDocumentCategory(condition = "", search = "") {
      //added 20230630

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}search/rulebook/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    promiseGetSearchWordByDocumentCategoryNew(condition = "", search = "") {
      //added 20240124

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      return new Promise((resolve, reject) => {
        const url = `${this.api}search2/rulebook/${condition}/?search1=${search}&exceptWord=${this.exceptText}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },
    async onSearch() {
      // 2023-03-13

      try {
        this.intPageNo = 1; // 2022-02-03
        this.searchRulebookIconAlignment = true;
        this.isMultiple = false;
        this.selectedCondition = '帳票番号'

        let search = "";
        let japaneseSpace = "　";

        this.loading2 = true;

        // CHECK IF SEARCH BY RULEBOOK NUMBER
        // if( this.selectedCondition == 'REFERENCE #' ) { // change to 帳票番号 20230704
        if (this.selectedCondition == "帳票番号") {
          this.searchText = ""
          if (this.searchDocNo.trim()) {
            //  alert('search by reference number')
            let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
              "byRulebookNumber",
              this.searchDocNo
            );

            // this.googleRules = finalData.sort((a, b) => {
            //   return a.RulebookUpdatedDate > b.RulebookUpdatedDate ? -1 : 1;
            // });

            //NOTE: change sorting key to UpdatedDate 2024-04-24
            this.googleRules = finalData
            .map((r)=>{
              r.UpdatedDate = r.UpdatedDate.substring(0,10)
              return r
            })
            .sort((a, b) => {
              return a._id > b._id ? 1 : -1;
            })
            .sort((a, b) => {
              return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
            }).map(r=>{
              this.$set(r,'showLine',false)
              return r
            });

            this.loading2 = false;
          } else {
            alert(`Please Input Reference Number`);
            this.loading2 = false;
          }
        }
        // CHECK IF SEARCH BY RULEBOOK TITLE
        // else if ( this.selectedCondition == 'TITLE' ) {  // change to タイトル 20230704
        else if (this.selectedCondition == "タイトル") {
          if (this.searchText.trim()) {
            //  alert('search by title')
            let finalData = await this.promiseGetSearchWordByDocumentCategoryNew(
              "byDocumentTitle",
              this.searchText
            );

            // this.googleRules = finalData.sort((a, b) => {
            //   return a.RulebookUpdatedDate > b.RulebookUpdatedDate ? -1 : 1;
            // });

            //NOTE: change sorting key to UpdatedDate 2024-04-24
            this.googleRules = finalData
            .map((r)=>{
              r.UpdatedDate = r.UpdatedDate.substring(0,10)
              return r
            })
            .sort((a, b) => {
              return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
            }).map(r=>{
              this.$set(r,'showLine',false)
              return r
            });

            this.loading2 = false;
          } else {
            alert(`Please Input Reference Number`);
            this.loading2 = false;
          }
        }

        // CHECK IF SEARCH BY Document Number and Title
        // else if( this.selectedCondition == 'ID & TITLE') {  // change to 帳票番号＆タイトル 20230704
        else if (this.selectedCondition == "帳票番号＆タイトル") {
          if (this.searchDocNo.trim() && this.searchTitle.trim()) {
            //  alert('search id title')
            let finalData = await this.promiseGetSearchWord(
              "byRulebookNumberAndTitle",
              this.searchDocNo,
              this.searchTitle
            );

            // this.googleRules = finalData.sort((a, b) => {
            //   return a.RulebookUpdatedDate > b.RulebookUpdatedDate ? -1 : 1;
            // });

            //NOTE: change sorting key to UpdatedDate 2024-04-24
            this.googleRules = finalData
            .map((r)=>{
              r.UpdatedDate = r.UpdatedDate.substring(0,10)
              return r
            })
            .sort((a, b) => {
              return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
            }).map(r=>{
              this.$set(r,'showLine',false)
              return r
            });

            this.loading2 = false;
          } else {
            alert(`Please Input Reference Number and Title`);
            this.googleRules = [];
            this.loading2 = false;
          }
        } else {
          // FULL SEARCH TEXT
          if (this.searchText) {
            // 2 Words
            if (
              this.searchText.includes(" ") ||
              this.searchText.includes(japaneseSpace)
            ) {
              // Split Two Words into array
              let splitKeyWord = this.searchText
                .replace(/\s+/g, " ")
                .split(" ");

              // Get Keyword per index ( as of now 2 words only )
              let keywordsArr1 = Object.values(
                this.convertSearchText(splitKeyWord[0])
              );
              let keywordsArr2 = Object.values(
                this.convertSearchText(splitKeyWord[1])
              );

              console.log("Search word 1 --> ", keywordsArr1);
              console.log("Search word 2 --> ", keywordsArr2);

              // Selected Condition
              // ===================================== AND =========================================== //
              if (this.selectedCondition == "AND") {
                if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                  let finalData = await this.promiseGetSearchWordNew(
                    "and",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  this.googleRules = _.uniqBy(finalData.flat(), "_id");

                  // this.googleRules = this.googleRules.sort((a, b) => {
                  //   return a.RulebookUpdatedDate > b.RulebookUpdatedDate
                  //     ? -1
                  //     : 1;
                  // });

                  //NOTE: change sorting key to UpdatedDate 2024-04-24
                  this.googleRules = this.googleRules
                  .map((r)=>{
                    r.UpdatedDate = r.UpdatedDate.substring(0,10)
                    return r
                  })
                  .sort((a, b) => {
                    return a._id > b._id ? 1 : -1;
                  })
                  .sort((a, b) => {
                    return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
                  })
                  .map(r=>{
                    this.$set(r,'showLine',false)
                    return r
                  });
                }
              } else {
                // ==================================== OR ============================================== //
                if (keywordsArr1.length != 0 && keywordsArr2.length != 0) {
                  let finalData = await this.promiseGetSearchWordNew(
                    "or",
                    // splitKeyWord[0],
                    // splitKeyWord[1]
                    this.searchText
                  );
                  this.googleRules = _.uniqBy(finalData.flat(), "_id");

                  // this.googleRules = this.googleRules.sort((a, b) => {
                  //   return a.RulebookUpdatedDate > b.RulebookUpdatedDate
                  //     ? -1
                  //     : 1;
                  // });

                  //NOTE: change sorting key to UpdatedDate 2024-04-24
                  this.googleRules = this.googleRules
                  .map((r)=>{
                    r.UpdatedDate = r.UpdatedDate.substring(0,10)
                    return r
                  })
                  .sort((a, b) => {
                    return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
                  }).map(r=>{
                    this.$set(r,'showLine',false)
                    return r
                  });
                }
              }

              this.loading2 = false;
              this.searchDialog = false;
            } else {
              // 1 Word  AND
              if (this.selectedCondition == "AND") {
                search = this.convertSearchText(this.searchText);
                let searchKeyArr = Object.values(search);

                console.log("Search Word --> ", _.uniq(searchKeyArr));

                let finalData = await this.promiseGetSearchWordNew(
                  "and",
                  this.searchText
                );

                this.googleRules = _.uniqBy(finalData.flat(), "_id");

                // this.googleRules = this.googleRules.sort((a, b) => {
                //   return a.RulebookUpdatedDate > b.RulebookUpdatedDate ? -1 : 1;
                // });

                //NOTE: change sorting key to UpdatedDate 2024-04-24
                this.googleRules = this.googleRules
                .map((r)=>{
                  r.UpdatedDate = r.UpdatedDate.substring(0,10)
                  return r
                })
                .sort((a, b) => {
                  return a._id > b._id ? 1 : -1;
                })
                .sort((a, b) => {
                  return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
                })
                .map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });

                this.loading2 = false;
                this.searchDialog = false;
              } else {
                alert(
                  "Search words should be atleast 2 words separated with space"
                );
                this.loading2 = false;
              }
            }
          }
        }
      } catch (err) {
        console.log(err.message);
        alert("Error in fetching records, Please try again..");
        this.googleRules = [];
        this.loading2 = false;
        this.searchDialog = false;
      }
    },

    onSearch1() {
      // Old backup 2023-03-13

      this.intPageNo = 1; // 2022-02-03

      if (!this.viewHistoryTable) {
        if (!this.composing) {
          // addLog = addLog.replace(/\s\s+/g, ' ');
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          this.searchRulebookIconAlignment = true;
          this.isMultiple = false;

          let search = this.convert2FullKatakana(this.searchText).trim();

          if (this.searchText != "") {
            this.loading2 = true;
          }
          if (this.searchText) {
            let url = "";
            let url2 = "";
            let search2 = "";
            if (this.selectedCondition == "OR") {
              // ============================== OR Function ============================================== //

              if (search.includes(this.title_spacer) || search.includes(" ")) {
                let searchReplaceWords = [];
                if (search.includes(this.title_spacer)) {
                  searchReplaceWords.push(search);
                } else {
                  search.replace(" ", this.title_spacer);
                  searchReplaceWords.push(search);
                }

                console.log("line1230", search);
                // Check if Space is japanese space or normal space
                let newSearchReplaceWords = searchReplaceWords[0].includes(
                  this.title_spacer
                )
                  ? searchReplaceWords.join().split(this.title_spacer)
                  : searchReplaceWords.join().split(" ");

                // Convert Normal English to Japanese English
                let normalEngToJapEng = moji(newSearchReplaceWords[0])
                  .convert("HE", "ZE")
                  .toString();
                let japEngToNormalEng2 = moji(newSearchReplaceWords[0])
                  .convert("ZE", "HE")
                  .toString();

                // Convert Jap English to Normal English
                let japEngToNormalEng = moji(newSearchReplaceWords[1])
                  .convert("ZE", "HE")
                  .toString();
                let normalEngToJapEng2 = moji(newSearchReplaceWords[1])
                  .convert("HE", "ZE")
                  .toString();

                let toSearch2WordsEng1 = [normalEngToJapEng, japEngToNormalEng];
                let toSearch2WordsEng2 = [
                  japEngToNormalEng2,
                  normalEngToJapEng2,
                ];

                console.log(">", toSearch2WordsEng1);
                console.log(">>", toSearch2WordsEng2);

                search = [toSearch2WordsEng1.join(this.title_spacer)].join();
                search2 = [toSearch2WordsEng2.join(this.title_spacer)].join();

                console.log("line2320", search);
                console.log("line2321", search2);

                url = `${this.api}get/title_rules?search=${search}&except=${this.exceptText}`;
                url2 = `${this.api}get/title_rules?search=${search2}&except=${this.exceptText}`;
              } else {
                alert("2 Words Only");
                this.googleRules = [];
                this.searchDialog = false;
                this.loading2 = false;
              }

              // let ishiragana = moji(search).filter("HG").toString();
              // let iskatakana = moji(search).filter("KK").toString();
              // let hiragana = "";
              // let katakana = "";
              // if (ishiragana) {
              //   hiragana = moji(this.searchText).convert("HG", "KK").toString();
              //   search = `${search}${this.title_spacer}${hiragana}`;
              // } else if (iskatakana) {
              //   search = this.convert2FullKatakana(this.searchText);
              //   katakana = moji(search).convert("KK", "HG").toString();
              //   search = this.convert2FullKatakana(search);
              //   katakana = this.convert2FullHiragana(katakana);
              //   search = `${search}${this.title_spacer}${katakana}`;
              // } else {
              //   search = this.convert2FullKatakana(this.searchText);
              // }
              // //-----SMALL AND BIG ALPHA NUMERIC CHARACTERS CND
              // let numSmall = moji(search).filter("HE").toString();
              // let numBig = moji(search).filter("ZE").toString();

              // if (search.match(/[0-9]/g) || search.match(/[０-９]/g)) {
              //   if (numSmall) {
              //     numSmall = moji(search).convert("HE", "ZE").toString();
              //     search = `${search}${this.title_spacer}${numSmall}`;
              //   } else if (numBig) {
              //     numBig = search = `${search}${this.title_spacer}${moji(search)
              //       .convert("ZE", "HE")
              //       .toString()}`;
              //   }
              // }
              // //-----HALF AND FULL WIDTH  CHARACTERS

              // let fullKatakana = moji(search).filter("ZK").toString();
              // let halfKatakana = moji(search).filter("HK").toString();
              // if (fullKatakana) {
              //   fullKatakana = moji(search).convert("ZK", "HK").toString();
              //   search = `${search}${this.title_spacer}${fullKatakana}`;
              // } else if (halfKatakana) {
              //   halfKatakana = moji(search).convert("HK", "ZK").toString();
              //   search = `${search}${this.title_spacer}${halfKatakana}`;
              // }

              // url = `${this.api}get/title_rules?search=${search}&except=${this.exceptText}`;
            } else {
              // ================================ AND Function ============================================ //

              if (search.match(/[０-９]/g)) {
                search = `${moji(search)
                  .convert("ZE", "HE")
                  .toString()}`;
              }

              // 2022-06-16
              let bigWordToNormalEng = moji(search)
                .convert("ZE", "HE")
                .toString();
              let normalEngToBigWord = moji(search)
                .convert("HE", "ZE")
                .toString();
              url = `${this.api}get/title_rules/and?search=${bigWordToNormalEng}&except=${this.exceptText}`;
              url2 = `${this.api}get/title_rules/and?search=${normalEngToBigWord}&except=${this.exceptText}`;

              // OLD
              // url = `${this.api}get/title_rules/and?search=${search}&except=${this.exceptText}`;
              // url2 = `${this.api}get/title_rules/and?search=${search}&except=${this.exceptText}`;
            }
            // console.log('AND',url);
            // console.log('AND',url2);

            axios.defaults.headers.common["x-api-key"] =
              "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

            axios
              .get(url)
              .then((res) => {
                if (res.data) {
                  axios
                    .get(url2)
                    .then((res2) => {
                      // console.log('res.data', res.data);
                      // console.log('res2.data', res2.data);

                      let concat1 =
                        res.data[0] != "<"
                          ? _.uniqBy([...res.data, ...res2.data], "_id")
                          : [];

                      this.googleRules = concat1;

                      // OLD
                      // this.googleRules.sort((a, b) =>
                      //   a.countViews < b.countViews ? 1 : -1
                      // );

                      // 2022-10-28 New
                      // if Actual Link will not show test codes rulebook
                      if (this.linkURL == "https://documentsearch.hrd-s.com") {
                        //  let testCodesArrRulebook = [ '000000-00', '999999-99' ];
                        let testCodesArrRulebook = []; // 2023-03-03

                        this.googleRules = this.googleRules.filter((rec) => {
                          return !testCodesArrRulebook.includes(rec?._id);
                        }).map(r=>{
                          this.$set(r,'showLine',false)
                          return r
                        });

                        this.googleRules.sort((a, b) =>
                          a.countViews < b.countViews ? 1 : -1
                        );
                      } else {
                        // Test Bucket Link will shows all records including test codes rulebook
                        this.googleRules.sort((a, b) =>
                          a.countViews < b.countViews ? 1 : -1
                        ).map(r=>{
                          this.$set(r,'showLine',false)
                          return r
                        });
                      }

                      this.searchDialog = false;
                      this.loading2 = false;
                    })
                    .catch((err2) => {
                      console.log(err2.message);
                    });
                }
              })
              .catch((err1) => {
                console.log(err1.message);
                this.googleRules = [];
                this.searchDialog = false;
                this.loading2 = false;
              });

            this.hideResult = false;
            if (this.isSearchAll) {
              this.CHANGE_SEARCH_WORD(this.searchText);
              this.CHANGE_CONDITION(this.selectedCondition);
              this.CHANGE_EXCEPT_WORD(this.exceptText);
            } else {
              this.CHANGE_SEARCH_WORD("");
              this.CHANGE_CONDITION("");
              this.CHANGE_EXCEPT_WORD("");
            }
          } else {
            // alert('clear')
            this.googleRules = [];
            this.hideResult = true;
          }
          this.searchGoogle = true;
          this.rulecontent = false;
        }
      } else {
        //insert search History code here...
        // tanskie 2022-03-09
        //   this.loading2 = true;
        //   console.log('line2378', this.rulebookHistory)
        //   this.rulebookHistory = this.rulebookHistory
        //     .filter(a => {
        //       let search = new RegExp(this.searchText.toUpperCase(), "g");
        //       if (!search) {
        //           return a
        //       }else{
        //           return JSON.stringify(Object.values(a)).toUpperCase().match(search);
        //       }
        //     })
        //  this.loading2 =false
        // return this.rulebookHistory
        // .filter(a => {
        //     let search = new RegExp(this.searchText.toUpperCase(), "g");
        //     if (!search) {
        //         return a
        //     }else{
        //         return JSON.stringify(Object.values(a)).toUpperCase().match(search);
        //     }
        // })
      }
    },

    promiseGetMultipleSearchWords(searchKeyWord = "", exceptWord = "") {
      // 2023-03-15
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        const url = `${this.api}search/multiple/?search1=${searchKeyWord}&exceptWord=${exceptWord}`;
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject({ message: err.message });
          });
      });
    },

    async onMultipleSearch() {
      try {
        this.intPageNo = 1;
        this.searchRulebookIconAlignment = true;
        this.isMultiple = true;
        this.loading2 = true;
        if (!this.composing) {
          if (this.searchText) {
            let rulebookRecords = await this.promiseGetMultipleSearchWords(
              this.searchText,
              this.exceptText
            );
            this.googleRules = rulebookRecords.map((r)=>{
              r.UpdatedDate = r.UpdatedDate.substring(0,10)
              return r
            })
            .sort((a, b) => {
              return a._id > b._id ? 1 : -1;
            })
            .sort((a, b) => {
              return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
            }).map(r=>{
              this.$set(r,'showLine',false)
              return r
            });
            this.isMultiple = false;
            this.loading2 = false;
          } else {
            alert("Please provide search word..");
            this.googleRules = [];
            this.loading2 = false;
          }
        }
      } catch (err) {
        console.log(err.message);
        alert(
          "Error fetching data from multiple condition, Please try again.."
        );
        this.loading2 = true;
        this.googleRules = [];
        this.loading2 = false;
      }
    },

    async onMultipleSearchMerge() {
      try {
        this.intPageNo = 1;
        this.searchRulebookIconAlignment = true;
        this.isMultiple = true;
        this.loading2 = true;
        if (!this.composing) {
          if (this.searchText) {
            let rulebookRecords = await this.promiseGetMultipleSearchWords(
              this.searchText,
              this.exceptText
            );
            this.googleRules = rulebookRecords.map((r)=>{
              r.UpdatedDate = r.UpdatedDate.substring(0,10)
              return r
            })
            .sort((a, b) => {
              return a._id > b._id ? 1 : -1;
            })
            .sort((a, b) => {
              return a.UpdatedDate > b.UpdatedDate ? -1 : 1;
            }).map(r=>{
              this.$set(r,'showLine',false)
              return r
            });
            this.isMultiple = false;
            this.loading2 = false;
          } else {
            alert("Please provide search word..");
            this.googleRules = [];
            this.loading2 = false;
          }
        }
      } catch (err) {
        console.log(err.message);
        alert(
          "Error fetching data from multiple condition, Please try again.."
        );
        this.loading2 = true;
        this.googleRules = [];
        this.loading2 = false;
      }
    },

    onMultipleSearch1() {
      // backup multiple search
      this.intPageNo = 1; // 2022-02-03
      if (!this.composing) {
        this.searchRulebookIconAlignment = true;
        this.isMultiple = true;
        let search = this.convert2FullKatakana(this.searchText).trim();
        this.searchLists = [];
        if (this.searchText != "") {
          this.loading2 = true;
        }
        if (this.searchText) {
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          let url = "";
          // url = `${this.api}get/multiple_rules2?search=${search}&except=${this.exceptText}`;
          url = `${this.api}get/multiple_rulesNew2?search=${search}&except=${this.exceptText}`;
          axios
            .get(url)
            .then((res) => {
              if (res.data.responseData) {
                let regexText = new RegExp(
                  res.data.searchArr.join("|").toString(),
                  "g"
                );
                console.log("line2493", regexText);
                let mapTextContentData = res.data.responseData.map((rec) => {
                  rec.content = rec.content.replace(regexText, function(str) {
                    return `<span style="background-color:yellow"><b>${str}</b></span>`;
                  });
                  return rec;
                });

                this.googleRules = _.orderBy(
                  mapTextContentData,
                  ["RulebookUpdatedDate", "countViews"],
                  ["desc", "desc"]
                ).map(r=>{
                  this.$set(r,'showLine',false)
                  return r
                });
                this.loading2 = false;
                this.isMultiple = false;
              }

              // if (typeof res.data != "string") {
              //   this.loading2 = false;
              //   this.googleRules = res.data;
              //   this.googleRules.sort((a, b) =>
              //     a.countViews < b.countViews ? 1 : -1
              //   );
              // } else {
              //   this.loading2 = true;
              //   // this.selectedCondition = "OR";
              //   this.selectedCondition = "MULTI";

              //   this.onSearch();
              //   this.isMultiple = false;
              // }
              // this.approvedDialog = false;
              // this.searchDialog = false;
            })
            .catch((err) => {
              alert(
                `Keyword ${this.searchText} is not yet registered, Please confirm to administrator`
              );
              this.googleRules = [];
              this.loading2 = false;
              console.log(err.message);
            });

          this.hideResult = false;
          if (this.isSearchAll) {
            this.CHANGE_SEARCH_WORD(this.searchText);
            this.CHANGE_CONDITION(this.selectedCondition);
            this.CHANGE_EXCEPT_WORD(this.exceptText);
          } else {
            this.CHANGE_SEARCH_WORD("");
            this.CHANGE_CONDITION("");
            this.CHANGE_EXCEPT_WORD("");
          }
        } else {
          this.googleRules = [];
          this.hideResult = true;
        }
        this.searchGoogle = true;
        this.rulecontent = false;
      }
    },

    disapprovedDocs() {
      if (this.approvalRemarks) {
        this.viewedRule.isApproved = "disapproved";
        this.viewedRule.approver = this.userInfo.full_name;
        this.viewedRule.approvalRemarks = this.approvalRemarks;
        let toUpdate = {};
        toUpdate.isApproved = "disapproved";
        toUpdate.approver = this.userInfo.full_name;
        toUpdate._id = this.viewedRule._id;
        toUpdate.approvalRemarks = this.approvalRemarks;
        let url = `${this.api}update/document`;
        axios.post(url, toUpdate).then((res) => {
          this.viewedRule._rev = res.data.rev;
          Swal.fire({
            icon: "error",
            title: "Disapproved.",
          });
          // this.getDataRules();
          this.approvedDialog = false;
          this.rulecontent = false;
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Please add remarks.",
        });
      }
    },
    approvedDocs() {
      this.viewedRule.isApproved = true;
      this.viewedRule.approver = this.userInfo.full_name;
      let toUpdate = {};
      toUpdate.isApproved = true;
      toUpdate.approver = this.userInfo.full_name;
      toUpdate._id = this.viewedRule._id;
      let url = `${this.api}update/document`;
      axios.post(url, toUpdate).then((res) => {
        this.viewedRule._rev = res.data.rev;

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Approved !",
          showConfirmButton: false,
          timer: 1500,
        });
        // this.getDataRules();
        this.approvedDialog = false;
      });
    },

    getRdoc(val) {
      this.docStatus = val;
    },
    loadAllDocs() {
      this.isMultiple = false;
      this.loading2 = true;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let url = `${this.api}getAllDocs?docType=RULEBOOK&method=${this.selectedMethod}&criteria=${this.selectedCriteria}&productType=${this.selectedproductType}`;

      axios.get(url).then((res) => {
        // console.log(res.data)
        // OLD
        // this.googleRules = res.data.map((r) => {
        //   let i = 0;
        //   r.key = i++;
        //   return r;
        // });

        // this.googleRules.sort((a, b) => (a.countViews < b.countViews ? 1 : -1));

        // 2022-10-28
        // If Actual Link, do not include test codes for rulebook
        if (this.linkURL == "https://documentsearch.hrd-s.com") {
          //  let testCodesArrRulebook = ['000000-00', '999999-99']
          let testCodesArrRulebook = []; // 2023-03-03

          this.googleRules = res.data.map((r) => {
            let i = 0;
            r.key = i++;
            return r;
          });

          this.googleRules = this.googleRules.filter((rec) => {
            return !testCodesArrRulebook.includes(rec?._id);
          })
          .map((r)=>{
            r.UpdatedDate = r.UpdatedDate.substring(0,10)
            return r
          })
          .sort((a, b) => {
            return a._id > b._id ? 1 : -1;
          });

          // this.googleRules.sort((a, b) =>
          //   a.countViews < b.countViews ? 1 : -1
          // )
          //NOTE: initial sorting by UpdatedDate 2024-03-27
          this.googleRules =  _.orderBy(
            this.googleRules,
            ["UpdatedDate"],
            ["desc"]
          )
          .map(r=>{
            this.$set(r,'showLine',false)
            return r
          });
        } else {
          // Test Bucket, Shows all records including test codes rulebook
          this.googleRules = res.data.map((r) => {
            let i = 0;
            r.key = i++;
            return r;
          })
          .map((r)=>{
            r.UpdatedDate = r.UpdatedDate.substring(0,10)
            return r
          })
          .sort((a, b) => {
            return a._id > b._id ? 1 : -1;
          });

          // this.googleRules.sort((a, b) =>
          //   a.countViews < b.countViews ? 1 : -1
          // )
          //NOTE: initial sorting by UpdatedDate 2024-03-27
          this.googleRules =  _.orderBy(
            this.googleRules,
            ["UpdatedDate"],
            ["desc"]
          ).map(r=>{
            this.$set(r,'showLine',false)
            return r
          });
        }
        // console.log('rulebook datas', this.googleRules.filter(r=>{
        //   return r._id == '190712-16'
        // }))

        this.loading2 = false;
        //      var myHeaders = new Headers(); // Currently empty
        // console.log(myHeaders.get());
      });
    },
    SpecInclude(specs) {
      return this.Specifications.includes(specs);
    },

    sortHistory() {
      let viewedRuleDataHolder = this.viewedRule.data;
      this.historyDetails = viewedRuleDataHolder.reverse();
    },
    onChangeDate(date, datestring) {
      this.dateFrom = datestring[0];
      this.dateTo = datestring[1];
    },

    loadSynonyms() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.get(`${this.api}get/SynonymChars`).then((res) => {
        this.synonymsData = res.data;
      });
    },

    newSearch() {
      // let search = [];
      // if (this.searchText.includes(`${title_spacer}`)) {
      //   search = this.searchText.replace(/\s+/g, " ").split(" ");
      // } else {
      //   search = this.searchText.replace(/\s+/g, " ").split(" ");
      // }

      // let Multiple = await this.synonymsData.data
      //   .map((rec, index) => {
      //     return search.filter((rec2) => {
      //       if (rec.indexOf(rec2) >= 0) {
      //         return rec;
      //       }
      //     });
      //   })
      //   .filter((rec) => {
      //     return rec.length > 0;
      //   });
      // // console.log(Multiple);
      // await Multiple.map((rec) => {
      //   console.log(rec, search);
      //   // if (search.includes(rec)) {
      //   search.splice(search.indexOf(rec[0]), 1);
      //   // }
      // });
      // console.log(search.toString().replace(",", " "));
      // console.log(Multiple.toString().replace(",", " "));

      // await this.onSearch(search.toString().replace(",", " "));
      // await this.onMultipleSearch(Multiple.toString().replace(",", " "));
      // this.onMultipleSearch();
      // this.onSearch();

      if (this.synonymsData.data.toString().includes(this.searchText)) {
        this.onMultipleSearch();
      } else {
        this.onSearch();
      }
    },
  },
  computed: {
    // NOTE: add getting of latest koushin 2024-06-13
    latestKoushinName(){
      let koushins = this.googleRules.filter(r=>{
        return r.koushinName
      }).map(r=>{
        return r.koushinName
      }).sort().reverse()[0]
      if(koushins){
        return koushins
      }else{
        return ''
      }
    },
    // 2022-09-08
    paginationLength() {
      let l = this.filteredRulebookHistory.length,
        s = this.intRowNoAllDocs;
      return Math.ceil(l / s);
    },
    paginatedRulebookHistory() {
      const start = (this.pageNumber - 1) * this.intRowNoAllDocs,
        end = start + this.intRowNoAllDocs;
      return this.filteredRulebookHistory.slice(start, end);
    },

    filteredRulebookHistory() {
      return this.rulebookHistory
        .filter((a) => {
          let search = new RegExp(this.searchText.toUpperCase(), "g");
          if (!search) {
            return a;
          } else {
            return JSON.stringify(Object.values(a))
              .toUpperCase()
              .match(search);
          }
        })

        .filter((r) => {
          if (this.selectedProdCat == "ALL") {
            return r;
          } else {
            return r.productCategory == this.selectedProdCat;
          }
        })
        .filter((r) => {
          if (this.selectedHistFrameWork == "ALL") {
            return r;
          } else {
            if(this.selectedHistFrameWork == 'JIKU/WAKU'){
              return this.selectedHistFrameWork == r.framework;
            }else{
              return this.selectedHistFrameWork ==r.framework || r.framework == 'JIKU/WAKU';
            }
          }
        })
        .filter((r) => {
          if (this.selectedHistClassification == "ALL") {
            return r;
          } else {
            return this.selectedHistClassification == r.classification;
          }
        })
        .filter((r) => {
          if (this.selectedHistUpCat == "ALL") {
            return r;
          } else {
            return r.updateCategory == this.selectedHistUpCat;
          }
        })
        .filter((r) => {
          if (this.selectedHistIncharge == "ALL") {
            return r;
          } else {
            return this.selectedHistIncharge == r.personIncharge;
          }
        })
        .filter((r) => {
          // 2022-09-13 Year
          if (this.selectedYear == "ALL") {
            return r;
          } else {
            if (r.year != undefined) {
              return this.selectedYear == r.year;
            }
          }
        })
        .filter((r) => {
          // 2022-09-13 Koushin
          if (this.selectedKoushin == "ALL") {
            return r;
          } else {
            if (r.koushinName != undefined) {
              return this.selectedKoushin == r.koushinName;
            }
          }
        })

        .filter((r) => {
          if (this.viewHistoryTable && this.searchHistory) {
            return (
              r.updateCategory.includes(this.searchText) ||
              r.classification.includes(this.searchText) ||
              r.framework.includes(this.searchText) ||
              r.productCategory.includes(this.searchText) ||
              r.formerControlNumber.includes(this.searchText) ||
              r.newControlNumber.includes(this.searchText) ||
              r.updateContents.includes(this.searchText) ||
              r.personIncharge.includes(this.searchText)
            );
          } else {
            return [];
          }
        });
    },

    // 2022-09-13
    histRulebookYear() {
      if (this.viewHistoryTable) {
        let a = this.rulebookHistory.map((r) => {
          if (r.year != undefined) {
            return r.year;
          }
        });
        a.unshift("ALL");
        return [...new Set(a)];
      } else {
        return [];
      }
    },

    // 2022-09-08
    histInchargeList() {
      if (this.viewHistoryTable) {
        let a = this.rulebookHistory.map((r) => {
          return r.personIncharge;
        });
        a.unshift("ALL");

        return [...new Set(a)];
      } else {
        return [];
      }
    },
    histUpCatList() {
      if (this.viewHistoryTable) {
        let a = this.rulebookHistory.map((r) => {
          return r.updateCategory;
        })
        .filter(r=>r);//NOTE: filter out the empty or null value 2024-04-23
        a.unshift("ALL");

        return [...new Set(a)];
      } else {
        return [];
      }
    },
    histProdCatList() {
      if (this.viewHistoryTable) {
        let a = this.rulebookHistory.map((r) => {
          return r.productCategory;
        });
        a.unshift("ALL");

        return [...new Set(a)];
      } else {
        return [];
      }
    },
    totalDeletedHistory() {
      return this.rulebookHistory.filter((r) => {
        return r.status == "deleted";
      }).length;
    },
    totalRevisedHistory() {
      return this.rulebookHistory.filter((r) => {
        return r.status == "revised";
      }).length;
    },
    totalNewHistory() {
      return this.rulebookHistory.filter((r) => {
        return r.status == "new";
      }).length;
    },
    isFav() {
      if (this.isFavorite) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    screenName() {
      return this.$vuetify.breakpoint.name;
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    placePop() {
      if (this.$vuetify.breakpoint.name == "sm") {
        return "bottomRight";
      } else if (this.$vuetify.breakpoint.name == "xs") {
        return "bottomLeft";
      } else return "bottom";
    },
    isFilterOn() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        return true;
      } else return false;
    },
    LabelProductType() {
      if (
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "font-weight: bold; font-size: 14px;";
      } else return "font-weight: bold; font-size: 12px";
    },
    WidthFile() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "width:87%; margin-left:26px";
      } else return "width:88%";
    },
    WidthFile2() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "width:80%; ";
      }
      // else if (this.$vuetify.breakpoint.name== 'sm'){
      //   return
      // }
      else return "width: 70%";
    },

    StyleWidth() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:64%;";
      } else return "width:98%";
    },
    favoriteWidth() {
      //  if (
      //         this.$vuetify.breakpoint.name == "md" ||
      //         this.$vuetify.breakpoint.name == "lg" ||
      //         this.$vuetify.breakpoint.name == "xl"
      //       ) {
      //         return "width:40%;";

      //     } else return "width:98%";
      return "width:20%;";
    },
    CustomWidth() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:48%";
      } else return "width:98%";
    },
    MethodWidth() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:50%";
      } else return "width:98%";
    },
    Width() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:55%";
      } else return "width:98%";
    },
    SearchWidth() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:68%";
      } else return "width:98%";
    },
    HTypeWidth() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:55%";
      } else return "width:98%";
    },
    DateWidth() {
      if (
        this.$vuetify.breakpoint.name == "md" ||
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      ) {
        return "width:68%";
      } else return "width:98%";
    },
    // isFav() {
    //   if (this.isFavorite) {
    //     return "mdi-star";
    //   } else {
    //     return "mdi-star-outline";
    //   }
    // },
    newViewedRule() {
      return this.viewedRule;
    },
    filteredSpecs() {
      if (this.listData.length > 0) {
        let notAll = this.SpecInclude("All");
        return this.listData
          .filter((r) => {
            if (this.Variety == "All") {
              return r;
            } else {
              return r.variety == this.Variety;
            }
          })
          .filter((s) => {
            if (this.SpecInclude("All")) {
              return s;
            } else {
              return [];
            }
          })
          .filter((r) => {
            if (this.SpecInclude("Ippan") && !notAll) {
              return r.Ippan;
            } else {
              return [];
            }
          })
          .filter((r) => {
            if (this.SpecInclude("YumeNoIe") && !notAll) {
              return r["YumeNoIe"];
            } else {
              return [];
            }
          })
          .filter((r) => {
            if (this.SpecInclude("G") && !notAll) {
              return r["G"];
            } else {
              return [];
            }
          })
          .filter((r) => {
            if (this.SpecInclude("F") && !notAll) {
              return r["F"];
            } else {
              return [];
            }
          })
          .filter((r) => {
            if (this.SpecInclude("A") && !notAll) {
              return r["A"];
            } else {
              return [];
            }
          })
          .filter((r) => {
            if (this.SpecInclude("B") && !notAll) {
              return r["B"];
            } else {
              return [];
            }
          })
          .filter((r) => {
            if (this.SpecInclude("iCube") && !notAll) {
              return r["iCube"];
            } else {
              return [];
            }
          })
          .filter((r) => {
            if (this.SpecInclude("iSmart") && !notAll) {
              return r["iSmart"];
            } else {
              return [];
            }
          })

          .filter((r) => {
            if (this.SpecInclude("Gsmart") & !notAll) {
              // 2022-11-15
              return r["Gsmart"];
            } else {
              return [];
            }
          })

          .filter((r) => {
            if (this.SpecInclude("HUGme") & !notAll) {
              // 2022-11-23
              return r["HUGme"];
            } else {
              return [];
            }
          })

          .filter((r) => {
            if (this.SpecInclude("HUGmefam") & !notAll) {
              // 2023-03-17
              return r["HUGmefam"];
            } else {
              return [];
            }
          });
      } else {
        return [];
      }
    },
    filteredJWW() {
      if (this.filteredSpecs.length > 0) {
        return this.filteredSpecs.filter((data) => {
          let search = new RegExp(this.toSearchJWW.toUpperCase(), "g");
          return JSON.stringify(Object.values(data))
            .toUpperCase()
            .match(search);
        });
      } else {
        return [];
      }
    },
    filteredItem() {
      if (this.updatedRules.length > 0) {
        return this.updatedRules.filter((data) => {
          return data._id.toLowerCase().includes(this.toSearch.toLowerCase());
        });
        // } else if (this.searchText) {
        //   return this.updatedRules
        //     .filter((r) => {
        //       if (this.selectedproductType != "ALL") {
        //         return r.productType == this.selectedproductType;
        //       } else {
        //         return r;
        //       }
        //     })
        //     .filter((r) => {
        //       if (this.selectedMethod != "ALL") {
        //         return r.method == this.selectedMethod;
        //       } else {
        //         return r;
        //       }
        //     })
        //     .filter((r) => {
        //       if (this.selectedCriteria != "ALL") {
        //         return r.criteria == this.selectedCriteria;
        //       } else {
        //         return r;
        //       }
        //     });
      } else {
        return [];
      }
    },

    filteredGoogleRules() {
      // console.log('computed:',this.googleRules)
      let data = [];
      if (this.googleRules.length > 0) {
        //   return this.filteredRuleSpecs;
        // } else if (!this.searchText) {
        data = this.googleRules
          .filter((r) => {
            if (this.selectedproductType != "ALL") {
              return r.productType == this.selectedproductType;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (this.selectedMethod != "ALL") {
              // return r.method == this.selectedMethod;
              //NOTE: change filtering for selected method 2024-05-16
              if(this.selectedMethod == 'WAKU/JIKU'){
                return r.method == this.selectedMethod;
              }else{
                return r.method == this.selectedMethod || r.method == 'WAKU/JIKU';
              }
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (this.selectedCriteria != "ALL") {
              return r.criteria == this.selectedCriteria;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("Ippan") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.Ippan == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("YumeNoIe") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.YumeNoIe == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("Icube") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.Icube == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("Ismart") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.Ismart == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("Ismile") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.Ismile == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingSpecification.includes("Taishin") &&
              !this.selectedBuildingSpecification.includes("ALL")
            ) {
              return r.Taishin == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingSpecification.includes("Menshin") &&
              !this.selectedBuildingSpecification.includes("ALL")
            ) {
              return r.Menshin == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (this.dateFrom) {
              return r.RulebookUpdatedDate >= `${this.dateFrom}`;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (this.dateTo) {
              return r.RulebookUpdatedDate <= `${this.dateTo}`;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("G") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.G == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("F") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.F == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("A") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.A == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("B") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r.B == true;
            } else {
              return r;
            }
          })
          .filter((r) => {
            if (
              this.selectedBuildingType.includes("F2-TD") &&
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r["F2-TD"] == true;
            } else {
              return r;
            }
          })

          .filter((r) => {
            if (
              this.selectedBuildingType.includes("Gsmart") && // 2022-11-15
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r["Gsmart"] == true;
            } else {
              return r;
            }
          })

          .filter((r) => {
            if (
              this.selectedBuildingType.includes("HUGme") && // 2022-11-15
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r["HUGme"] == true;
            } else {
              return r;
            }
          })

          .filter((r) => {
            if (
              this.selectedBuildingType.includes("HUGmefam") && // 2023-03-17
              !this.selectedBuildingType.includes("ALL")
            ) {
              return r["HUGmefam"] == true;
            } else {
              return r;
            }
          });
      } else {
        data = [];
      }

      // on going to bukas ( try to remove ito then gawin itong methods ) ( 2022-06-04)
      // if (this.sortByProductTypeASC) {
      //   data.sort((a, b) => (a.productType > b.productType ? 1 : -1));
      // }
      // if (this.sortByProductTypeDES) {
      //   data.sort((a, b) => (a.productType < b.productType ? 1 : -1));
      // }
      // if (this.sortByMethodASC) {
      //   data.sort((a, b) => (a.method > b.method ? 1 : -1));
      // }
      // if (this.sortByMethodDES) {
      //   data.sort((a, b) => (a.method < b.method ? 1 : -1));
      // }
      // if (this.sortByCriteriaASC) {
      //   data.sort((a, b) => (a.criteria > b.criteria ? 1 : -1));
      // }
      // if (this.sortByCriteriaDES) {
      //   data.sort((a, b) => (a.criteria < b.criteria ? 1 : -1));
      // }
      // if (this.sortByDateASC) {
      //   data.sort((a, b) => {
      //     let key1 = a.RulebookUpdatedDate;
      //     let key2 = b.RulebookUpdatedDate;
      //     if (key1 < key2) {
      //       return -1;
      //     } else if (key1 == key2) {
      //       return 0;
      //     } else {
      //       return 1;
      //     }
      //   });
      // }
      // if (this.sortByDateDES) {
      //   data.sort(function (a, b) {
      //     if (a.RulebookUpdatedDate > b.RulebookUpdatedDate) return -1;
      //     if (a.RulebookUpdatedDate < b.RulebookUpdatedDate) return 1;
      //     return 0;
      //   });
      // }
      // if (this.sortByFavoriteASC) {
      //   data.sort((a, b) => (a.FavoritesCount > b.FavoritesCount ? 1 : -1));
      // }
      // if (this.sortByFavoriteDES) {
      //   data.sort((a, b) => (a.FavoritesCount < b.FavoritesCount ? 1 : -1));
      // }

      return data;
    },

    //Rulebook Document Data with pagination
    cPageCount() {
      let l = this.filteredItem.length,
        s = this.intRowNoRulebookDocument;
      return Math.ceil(l / s);
    },
    cPaginatedData() {
      const start = (this.intPageNo - 1) * this.intRowNoRulebookDocument,
        end = start + this.intRowNoRulebookDocument;
      return this.filteredItem.slice(start, end);
    },

    //Search Rulebook Document Data with pagination
    cPageCount2() {
      let l = this.filteredGoogleRules.length,
        s = this.intRowNoSearchDocument;
      return Math.ceil(l / s);
    },
    cPaginatedData2() {
      // console.log("filter:", this.filteredGoogleRules);
      const start = (this.intPageNo - 1) * this.intRowNoSearchDocument,
        end = start + this.intRowNoSearchDocument;
      return this.filteredGoogleRules.slice(start, end);
      // return this.filteredGoogleRules;
    },
    customClearIcon() {
      return "mdi-close-circle-outline";
    },
  },
};
</script>

<style scoped>
/* .bg-green{
   background-color: #D1F2EB !important; 
}
.bg-yellow{
   background-color: #FCF3CF !important; 
} */
#title_ellipsis {
  display: inline-block;
  width: 93%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px !important;
}
.bgYellow {
  background-color: #a8e5f5;
}
.bgWhite {
  background-color: white;
}
.viewed {
  background-color: white;
  color: purple;
}
div {
  margin: 0 0 0 0;
}
thead.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  color: #fafafa;
  background-color: gray;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

/* .border {
  box-sizing: content-box;
  width: 80px;
  height: 20px;
  padding: 5px;
  border: 1px solid #c7c1c1;
} */

.disabled {
  pointer-events: none;
}

#mycard {
  /* min-height: calc(100vh - 100px);
  max-height: calc(100vh - 100px); */
  overflow-y: auto;
  overflow-x: auto;
}

.test {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  color: #fafafa;
  background-color: gray;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

/* #label {
  border-radius: 5px;
  font-size: 15px;
  padding: 0 0.5rem;
  color: honeydew;
} */

#responsive {
  max-height: 72vh;
}
#responsive768 {
  max-height: 46vh;
}
#responsive1024 {
  max-height: 61vh;
}
#responsive1080 {
  max-height: 63vh;
}
#responsive1200 {
  max-height: 70vh;
}
#responsive2 {
  max-height: 95vh;
  width: 100%;
  overflow: auto;
}
#responsive2768 {
  max-height: 71vh;
  width: 100%;
  overflow: auto;
}
#responsive21024 {
  max-height: 79vh;
  width: 100%;
  overflow: auto;
}
#responsive21080 {
  max-height: 85vh;
  width: 100%;
  overflow: auto;
}
#responsive21200 {
  max-height: 90vh;
  width: 100%;
  overflow: auto;
}
#favStyle {
  color: #214252 !important;
  background-color: #fafafa !important;
  height: 30px !important;
  border-radius: 0mm !important;
}
.button {
  /* background-color: #01579b; */
  border: none;
  cursor: pointer;
  /* color: white; */
  color: black;
  padding: 6px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.border {
  /* background-color: #01579b; */
  border: none;
  /* color: white; */
  color: black;

  padding: 6px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
/* .print {
  background-color: white;
} */
@media only print {
  body {
    overflow: auto;
    height: auto;
  }
  /* #app {
    display: none !important;
  } */
  div.v-toolbar * {
    display: none !important;
  }
  div.v-toolbar__content * {
    display: none !important;
  }
  nav.v-toolbar.elevation-0.theme--light {
    display: none !important;
  }

  div.ant-tabs-nav-scroll * {
    display: none !important;
  }
  div.v-card.v-card--flat.v-sheet.theme--light {
    margin: 0mm;
    padding: 0mm;
  }
}

table > thead > tr > th {
  /* background-color: #154a7d !important; */
  background-color: #213b57 !important;
  color: white !important;
}
</style>
