<template>
  <v-container pa-0 fluid>
    <!-- {{userInfo}} -->
    <a-tabs
      ref="sekkeiref"
      :activeKey="activeKey"
      :defaultActiveKey="tabNumber"
      @change="callback"
    >
      <a-tab-pane key="0" tab="全検索" v-if="isAdmin() || isIchijoUser()">
        <AllDocuments > </AllDocuments>
      </a-tab-pane>

      <a-tab-pane key="1" tab="ルールブック" v-if="!isSalesUser()">
        <Rulebook > </Rulebook>
      </a-tab-pane>

      <!-- NOTE: proposal -->
      <a-tab-pane key="11" tab="ルールブック(全体)" v-if="!isSalesUser()">
        <RulebookNew :rulebookTab="'all'"> </RulebookNew>
      </a-tab-pane>

      <a-tab-pane key="12" tab="ルールブック(軸組)" v-if="!isSalesUser()">
        <RulebookNew :rulebookTab="'jiku'"> </RulebookNew>
      </a-tab-pane>

      <a-tab-pane key="13" tab="ルールブック(枠組)" v-if="!isSalesUser()">
        <RulebookNew :rulebookTab="'waku'"> </RulebookNew>
      </a-tab-pane>

      <a-tab-pane
        key="2"
        tab="連絡票・通達"
        v-if="isAdmin() || isIchijoUser() || isSalesUser()"
      >
        <JapanMemo > </JapanMemo>
      </a-tab-pane>

      <a-tab-pane
        key="3"
        tab="JWW/DXF(部材)"
        v-if="
          (isAdmin() || isIchijoUser() || isGCUser() || isGCPortalUser()) &&
            !isSalesUser()
        "
      >
        <JWW  />
      </a-tab-pane>

      <a-tab-pane
        key="4"
        tab="DXF(商品)"
        v-if="
          (isIchijoUser() || isGCUser() || isGCPortalUser()) && !isSalesUser()
        "
      >
        <DXFProduct ></DXFProduct>
      </a-tab-pane>

      <a-tab-pane
        key="5"
        tab="仕様マニュアル"
        v-if="
          isAdmin() ||
            isGCPortalUser() ||
            isInCharge() ||
            isIchijoUser() ||
            isGCUser() ||
            isGCUserNew()
        "
      >
        <!-- 2022-09-15 -->
        <ShiyoManual ></ShiyoManual>
      </a-tab-pane>

      <!-- NOTE: new UI for shiyo -->
      <!-- <a-tab-pane
        key="14"
        tab="仕様マニュアル(NEW UI)"
      >
        <ShiyoManualNewUI></ShiyoManualNewUI>
      </a-tab-pane> -->

      <!-- NOTE: proposal -->
      <a-tab-pane
        key="10"
        tab="仕様マニュアル(PROPOSAL)"
      >
        <ShiyoManualNew></ShiyoManualNew>
      </a-tab-pane>

      <a-tab-pane key="6" tab="設計業務マニュアル" v-if="isAdmin()">
        <!-- 2023-07-07 -->
        <SekkeiGyoumu
          
        ></SekkeiGyoumu>
      </a-tab-pane>
      <a-tab-pane key="7" tab="設計企画通信" v-if="isAdmin()">
        <SekkeiKikaku ></SekkeiKikaku>
      </a-tab-pane>
      <a-tab-pane key="8" tab="CAD依頼資料" v-if="!isSalesUser()">
        <CADRequestMaterials ></CADRequestMaterials>
      </a-tab-pane>

      <a-tab-pane key="9" tab="CAD依頼指示方法" v-if="isAdmin()">
        <PlannersReference ></PlannersReference>
      </a-tab-pane>

      <!-- 2023-02-01 -->
      <!-- <a-tab-pane key="7" tab="技術関連事">
         <GijyutsuKanren v-if="tabNumber == '7'"></GijyutsuKanren>
      </a-tab-pane>  -->

      <!-- <a-tab-pane key="8" tab="提案書">
         <Teiansho v-if="tabNumber == '8'"></Teiansho>
      </a-tab-pane> -->
    </a-tabs>
  </v-container>
</template>

<script>
// import ShiyoManualNewUI from "../components/ShiyoManualNewUI.vue";
import ShiyoManualNew from "../components/ShiyoManualNew.vue";
import RulebookNew from "../components/RulebookNew.vue";
import JapanMemo from "../components/JapanMemo.vue";
import Rulebook from "../components/Rulebook.vue";
import AllDocuments from "../components/AllDocuments.vue";
import ShiyoManual from "../components/ShiyoManual.vue";
import DXFProduct from "../components/DXFProduct.vue";
import JWW from "../components/JWW.vue";
import CADRequestMaterials from "../components/CADRequestMaterials.vue";
import PlannersReference from "../components/PlannersReference.vue";
import SekkeiGyoumu from "../components/SekkeiGyoumo.vue";
import SekkeiKikaku from "../components/SekkeiKikaku.vue";

// import GijyutsuKanren from '../components/GijyutsuKanren.vue'  // 2023-02-01
// import Teiansho from '../components/Teiansho.vue'

import { mask } from "vue-the-mask";
import axios from "axios";
export default {
  directives: {
    mask,
  },
  components: {
    JapanMemo,
    Rulebook,
    AllDocuments,
    ShiyoManual,
    DXFProduct,
    JWW,
    CADRequestMaterials,
    PlannersReference,
    SekkeiGyoumu,
    SekkeiKikaku,
    // ShiyoManualNewUI
    ShiyoManualNew,
    RulebookNew,

    // GijyutsuKanren,
    // Teiansho
  },
  data() {
    return {
      rulebookTab: '',
      tabNumber: "1",//NOTE: change to empty for alldocs
      tabNum: "",
      activeKey: "1",//NOTE: change to 0 for alldocs
    };
  },

  created() {
    // Check if has Original Mail Address
    //  if( this.userInfo.originalMailAddress == undefined ||
    //      this.userInfo.originalMailAddress == null ||
    //      !this.userInfo.originalMailAddress) {
    //       this.deleteInfo();
    //  } else{
    //       // Increment Log
    //       this.CountUserLogs();
    //  }

    this.CountUserLogsNew(); // 2022-03-01
    for(let i=0;i<=9;i++){
      setTimeout(() => {
        this.callback(`${i}`)
        if(i==9){
          this.callback("1")
        }
      }, 100);
    }
  },
  watch: {},
  methods: {
    CountUserLogsNew() {
      let userId = this.userInfo.employeeCode;
      const url = `${this.api}/getUserDetailsByUserId/?UserId=${userId}`;
      axios
        .get(url)
        .then((res) => {
          if (res.data) {
            let toInsertUserLog = {
              MailAddress: res.data[0].MailAddress, // 2022-02-07
              count: 1,
              name: res.data[0].Name, // 2022-02-14
            };

            let insertLogUrl = `${this.api}userLogs/insertUserLog`;
            axios
              .post(insertLogUrl, toInsertUserLog)
              .then((res) => {
                if (res.data) {
                  console.log(`Increment Log`);
                }
              })
              .catch((err1) => {
                console.log(err1.message);
              });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    // CountUserLogs() {   // 2022-02-14
    //  // Save User logs
    //   let toInsertUserLog = {
    //         MailAddress: this.userInfo.originalMailAddress , // 2022-02-07
    //         count: 1,
    //         name: this.userInfo.name // 2022-02-14
    //   }
    //   let insertLogUrl = `${this.api}userLogs/insertUserLog`;
    //   axios.post(insertLogUrl, toInsertUserLog).then(res=>{
    //     if(res.data){
    //        console.log(`Increment Log`)
    //     }
    //   }).catch(err=>{console.log(err.message)})

    // },
    callback(val) {
      if (this.tabCondition == "Search") {
        this.TAB_CND("Files");
      } else if (this.tabCondition == "Files") {
        this.TAB_CND("Search");
      }
      this.tabNumber = val;
      this.activeKey = val;
      this.searchGoogle = true;
      this.rulecontent = false;
      this.selectDoc = "";
    },
    viewHistory(val) {
      this.viewedRule.version = val + 1;
    },
    setActiveTabNumber() {
      if (this.isAdmin() || this.isIchijoUser()) {
        this.tabNumber = "0";
      } else if (this.isSalesUser()) {
        // 2023-04-19
        this.tabNumber = "2";
      } else if (this.isGCUser()) {
        this.tabNumber = "1";
      }
      // else if (
      //   this.tabNum == "6" &&
      //   this.$route.params.tabNum == "redirect"
      // ) {
      //   this.tabNumber = "6";
      // }
      else {
        return this.tabNumber;
      }
    },
  },
  mounted() {
    this.tabNum = this.$route.query.tabnum;
    console.log(this.tabNum);
    if (this.tabNum) {
      this.activeKey = "6";
      this.callback("6");
    }
    // this.setActiveTabNumber();
  },
};
</script>
